import Cookies from 'universal-cookie';
import { refreshAuthToken } from 'actions/session';
import { fetchAgentClientListings } from 'actions/listings';
import { fetchProfile, fetchMyAgentProfile, fetchMyAdvisorProfile } from 'actions/profile';
import { fetchClients } from 'actions/clients';
import { fetchSettings, fetchAuthMechanism, fetchPrintPieceURL } from 'actions/settings';
import { fetchTeamAgents } from 'actions/agents';
import { GetSectionCustomizationOptions } from 'components/util';

const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");
const IsLNOEnabled = (CommonCustomizationOptions && CommonCustomizationOptions.IsLNOEnabled) ? true : false;

export default function setupLoginState(store) {
    const cookies = new Cookies();
    const authToken = cookies.get('authDetails');

    if(store){
        
        if (authToken) {
            store.dispatch(refreshAuthToken()).then(r => {
                if(r.response && r.response.AccessToken && r.response.AudienceType){
                    store.dispatch(fetchProfile());
                    store.dispatch(fetchSettings());
                    store.dispatch(fetchAuthMechanism());
                    if(CommonCustomizationOptions && CommonCustomizationOptions.FetchPrintPieceUrl){
                        store.dispatch(fetchPrintPieceURL());
                    }
                    if(CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers){
                        store.dispatch(fetchTeamAgents());
                    }

                    if (r.response.AudienceType === 'CLIENT') {
                        if(IsLNOEnabled){
                            store.dispatch(fetchMyAdvisorProfile());    
                            store.dispatch(fetchMyAgentProfile());
                        }
                        else {
                            store.dispatch(fetchMyAgentProfile());
                        }
                        store.dispatch(fetchAgentClientListings("favorites"));
                        store.dispatch(fetchAgentClientListings("bookmarks"));
                        store.dispatch(fetchAgentClientListings("removed"));
                        store.dispatch(fetchAgentClientListings("agentlistings"));
                    }
                    else {
                        store.dispatch(fetchClients());
                        store.dispatch(fetchAgentClientListings("agentlistings"));
                    }
                }
            });
        }

        if(!authToken) {
            //store.dispatch(anonymousLogin());
            store.dispatch(fetchSettings());
            store.dispatch(fetchAuthMechanism());
            if(CommonCustomizationOptions && CommonCustomizationOptions.FetchPrintPieceUrl){
                store.dispatch(fetchPrintPieceURL());
            }
            if(CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers){
                store.dispatch(fetchTeamAgents());
            }
        }
    }
}

import Cookies from "universal-cookie";
//import { getGUID } from 'components/util';
import {
  AUTH_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_REFRESH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_FAILURE,
  AUTH_MANUAL_LOGIN_SUCCESS,
  PURL_PREFIX_SAVE,
  ACCESSTOKEN_LOGOUT_REQUEST,
  ACCESSTOKEN_LOGOUT_SUCCESS,
  ACCESSTOKEN_LOGOUT_FAILURE,
  SYNCADS_REQUEST,
  SYNCADS_SUCCESS,
  SYNCADS_FAILURE,
} from "actions/session";
import {
  SEND_AGENT_EMAIL_REQUEST,
  SEND_AGENT_EMAIL_SUCCESS,
  SEND_AGENT_EMAIL_FAILURE,
  AGENT_REQUEST,
  AGENT_SUCCESS,
  AGENT_FAILURE,
  FAGENT_REQUEST,
  FAGENT_SUCCESS,
  FAGENT_FAILURE,
  TAGENT_REQUEST,
  TAGENT_SUCCESS,
  TAGENT_FAILURE,
  ADDOFF_REQUEST,
  ADDOFF_SUCCESS,
  ADDOFF_FAILURE,
} from "actions/agents";

let cookies;

const setSessionItems = (resp) => {
  window.sessionStorage.setItem("APIKey", resp.AccessToken);
  window.sessionStorage.setItem("LastAuthCheck", new Date());

  if (resp.AudienceType === "CLIENT") {
    window.sessionStorage.setItem("APIAudience", "clients/");
  } else {
    window.sessionStorage.setItem("APIAudience", "agents/");
  }

  window.sessionStorage.setItem("MLSKey", resp.Audience.MLSKey);
  window.sessionStorage.setItem(
    "RootAPI",
    window.sessionStorage.getItem("BaseAPI") + resp.Audience.MLSKey + "/"
  );

  /* var DeviceIDs = window.localStorage.getItem("DeviceIDs");
    if(!DeviceIDs){ 
        DeviceIDs = [{UserId: resp.Audience.Id, DeviceId: getGUID()}];
    }
    else{
        DeviceIDs = JSON.parse(DeviceIDs);
        var dLst = DeviceIDs.filter(d => d.UserId === resp.Audience.Id);
        if(!dLst || (dLst && dLst.length === 0)){
            DeviceIDs.push({UserId: resp.Audience.Id, DeviceId: getGUID()});
        }
    }
    window.localStorage.setItem("DeviceIDs", JSON.stringify(DeviceIDs)); */
};

export default (
  state = {
    isAuthenticating: false,
    isAuthenticated: false,
    user: null,
    audience: {},
    notificationMessage: null,
    sendingEmail: false,
    error: undefined,
    emailError: undefined,
    featuredagents: [],
    teamagents: [],
    isAnonymous: true,
    isAOFetching: false,
    advisorsOffices: [],
    isSSOLoggingOut: false,
  },
  action
) => {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        error: undefined,
      };
    case AUTH_MANUAL_LOGIN_SUCCESS:
    case AUTH_LOGIN_SUCCESS:
      setSessionItems(action.response);

      // Set long term cookie
      cookies = new Cookies();
      cookies.set("authDetails", action.response, { path: "/" });
      window.sessionStorage.removeItem("SearchParameters");
      window.sessionStorage.removeItem("SelectedClient");
      window.sessionStorage.removeItem("UserProfile");
      window.sessionStorage.removeItem("ACCommuteAddress");

      var isAnonymous = false;
      /* if (process.env.REACT_APP_AnonymousUserId !== undefined)
            {
                if(action.response && action.response.Audience && action.response.Audience.Id &&
                    action.response.Audience.Id !== "" && action.response.Audience.Id.toLowerCase() === process.env.REACT_APP_AnonymousUserId.toLowerCase()){
                    isAnonymous = true;
                }
            } */

      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        isAnonymous: isAnonymous,
        user: action.response,
        audience: {
          type: action.response.AudienceType === "CLIENT" ? "client" : "agent",
          ...action.response.Audience,
        },
        error: undefined,
      };
    case AUTH_REFRESH_SUCCESS:
      cookies = new Cookies();

      if (!cookies.get("authDetails") && !action.skip) {
        return {
          ...state,
          isAuthenticated: false,
          isAuthenticating: false,
          audience: null,
          error: undefined,
        };
      }

      if (action.response.ExpiresOnUTC) {
        cookies.set("authDetails", action.response, {
          path: "/",
          expires: new Date(action.response.ExpiresOnUTC),
        });
      } else {
        cookies.set("authDetails", action.response, { path: "/" });
      }
      setSessionItems(action.response);

      var isAnonymousAR = false;
      /* if (process.env.REACT_APP_AnonymousUserId !== undefined)
            {
                if(action.response && action.response.Audience && action.response.Audience.Id &&
                    action.response.Audience.Id !== "" && action.response.Audience.Id.toLowerCase() === process.env.REACT_APP_AnonymousUserId.toLowerCase()){
                    isAnonymousAR = true;
                }
            } */

      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        isAnonymous: isAnonymousAR,
        audience: {
          ...state.audience,
          type: action.response.AudienceType === "CLIENT" ? "client" : "agent",
          ...action.response.Audience,
        },
        error: undefined,
      };
    case AUTH_LOGOUT:
      window.sessionStorage.removeItem("SearchParameters");
      window.sessionStorage.removeItem("SelectedClient");
      window.sessionStorage.removeItem("UserProfile");
      window.sessionStorage.removeItem("ACCommuteAddress");
      window.sessionStorage.removeItem("ComingFromCampaign");

      cookies = new Cookies();
      /* const authToken = cookies.get('authDetails');
            var d = new Date();
            d.setDate(d.getDate()-5);
            cookies.set('authDetails', authToken, { path: '/', expires: d  }); */
      cookies.remove("authDetails");
      // Reset APIKey
      if (
        process.env.REACT_APP_NonExpiredAuthToken &&
        process.env.REACT_APP_NonExpiredAuthToken !== ""
      ) {
        window.sessionStorage.setItem(
          "APIKey",
          process.env.REACT_APP_NonExpiredAuthToken
        );
      }
      window.sessionStorage.setItem("APIAudience", "agents/");

      // Reset baseAPI URL
      //window.sessionStorage.setItem("RootAPI", window.sessionStorage.getItem("BaseAPI") + "true/");
      if (
        process.env.REACT_APP_PortalKey &&
        process.env.REACT_APP_PortalKey !== ""
      ) {
        window.sessionStorage.setItem(
          "RootAPI",
          window.sessionStorage.getItem("BaseAPI") +
            process.env.REACT_APP_PortalKey +
            "/"
        );
        window.sessionStorage.setItem(
          "MLSKey",
          process.env.REACT_APP_PortalKey
        );
      } else if (
        process.env.REACT_APP_MLSKey &&
        process.env.REACT_APP_MLSKey !== ""
      ) {
        window.sessionStorage.setItem(
          "RootAPI",
          window.sessionStorage.getItem("BaseAPI") +
            process.env.REACT_APP_MLSKey +
            "/"
        );
        window.sessionStorage.setItem("MLSKey", process.env.REACT_APP_MLSKey);
      } else {
        window.sessionStorage.setItem(
          "RootAPI",
          window.sessionStorage.getItem("BaseAPI") + "true/"
        );
        window.sessionStorage.setItem("MLSKey", "true");
      }

      return {
        ...state,
        isAuthenticated: false,
        isAnonymous: true,
        user: null,
        error: undefined,
      };
    case AUTH_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        error: action.error,
      };
    case SEND_AGENT_EMAIL_REQUEST:
      return {
        ...state,
        sendingEmail: true,
        notificationMessage: undefined,
        emailError: undefined,
      };
    case SEND_AGENT_EMAIL_SUCCESS:
      return {
        ...state,
        sendingEmail: false,
        notificationMessage: action.ResponseMessage || action.response.Message,
      };
    case SEND_AGENT_EMAIL_FAILURE:
      return {
        ...state,
        sendingEmail: false,
        emailError: action.error,
      };
    case PURL_PREFIX_SAVE:
      return {
        ...state,
        purlPrefix: action.purlPrefix,
      };
    case AGENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case AGENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notificationMessage: action.response.Message,
      };
    case AGENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case FAGENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case FAGENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        featuredagents: action.response.Agents,
      };
    case FAGENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case TAGENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case TAGENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        teamagents: action.response || [],
      };
    case TAGENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case ADDOFF_REQUEST:
      return {
        ...state,
        isAOFetching: true,
        error: undefined,
      };
    case ADDOFF_SUCCESS:
      return {
        ...state,
        isAOFetching: false,
        advisorsOffices: action.response.Agents || [],
      };
    case ADDOFF_FAILURE:
      return {
        ...state,
        isAOFetching: false,
        error: action.error,
      };
    case ACCESSTOKEN_LOGOUT_REQUEST:
      return {
        ...state,
        ssoLogoutRedirectUrl: null,
      };
    case ACCESSTOKEN_LOGOUT_SUCCESS:
      var rUrl = null;
      var Idp = null;
      var SAMLRequest = null;
      var Method = null;
      var isSSOLoggingOut = false;
      if (
        action &&
        action.response &&
        action.response.RedirectUrl &&
        action.response.RedirectUrl !== ""
      ) {
        if (
          action.UId &&
          action.UId !== "" &&
          process.env.REACT_APP_AnonymousUserId &&
          process.env.REACT_APP_AnonymousUserId !== "" &&
          action.UId.toLowerCase() !==
            process.env.REACT_APP_AnonymousUserId.toLowerCase() &&
          !action.PreventRedirect
        ) {
          rUrl = action.response.RedirectUrl;
        }
      }

      if (
        action &&
        action.response &&
        action.response.AuthenticationMechanism &&
        action.response.AuthenticationMechanism[0].AuthenticationMechanismId &&
        action.response.AuthenticationMechanism[0].AuthenticationMechanism &&
        action.response.AuthenticationMechanism[0].logoutURL &&
        action.response.AuthenticationMechanism[0].AuthDetails &&
        action.response.AuthenticationMechanism[0].AuthDetails.Idp &&
        action.response.AuthenticationMechanism[0].AuthDetails.SAMLRequest &&
        action.response.AuthenticationMechanism[0].AuthDetails.Method
      ) {
        Idp = action.response.AuthenticationMechanism[0].AuthDetails.Idp;
        SAMLRequest =
          action.response.AuthenticationMechanism[0].AuthDetails.SAMLRequest;
        Method = action.response.AuthenticationMechanism[0].AuthDetails.Method;

        rUrl = action.response.AuthenticationMechanism[0].logoutURL;
        isSSOLoggingOut = true;
      }

      return {
        ...state,
        rUrl,
        ssoLogoutRedirectUrl: rUrl,
        Idp,
        SAMLRequest,
        Method,
        isSSOLoggingOut: isSSOLoggingOut,
      };
    case ACCESSTOKEN_LOGOUT_FAILURE:
      return {
        ...state,
        ssoLogoutRedirectUrl: null,
        isSSOLoggingOut: false,
      };
    case SYNCADS_REQUEST:
      return {
        ...state,
      };
    case SYNCADS_SUCCESS:
      return {
        ...state,
      };
    case SYNCADS_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

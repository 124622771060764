import React, { Component } from 'react';
import { connect } from 'react-redux';
import FeedbackForm from 'components/feedbackform';
import DocumentTitle from 'react-document-title';
import { PageTitle } from 'components/util';


class FeedbackPage extends Component{

    constructor(props) {
        super(props);
        
        this.state = {
            title: 'Feedback'
        }
    }

    componentDidMount() {  
        window.scrollTo(0, 0);
    }

    render() {
        const { audience, profile, isAnonymous } = this.props;

        return (
            <DocumentTitle title={ PageTitle.replace('{title}', this.state.title) }>
            <div>
                <section className="custom-container pd-tp-0">
                    <div className="col-xs-12">
                        <FeedbackForm profile={profile} audience={audience} isAnonymous={isAnonymous} showNotification={this.props.showNotification} />
                    </div>
                </section>
            </div>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state, props) => ({
    audience: state.session.audience,
    profile: state.profile.mine,
    isAnonymous: state.session.isAnonymous,
});

export default connect(mapStateToProps)(FeedbackPage);
import React from 'react';
import Loader from 'components/loader';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/styles"; 
import { Button } from "@material-ui/core";
import Select from 'react-select';
import ReCAPTCHA from "react-google-recaptcha";
import { GetSectionCustomizationOptions, 
    getFormattedValueForReactSelect, getFormattedOptionsForReactSelect } from 'components/util';
import '../scss/_contactND.scss';


const userTypeOptions = [
    { value: "agent", text: "I am an Agent"},
    { value: "client", text: "I am a Client"}
];

const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");

const SITE_KEY = process.env.REACT_APP_GoogleRecaptchaSiteKey;
const recaptchaRef = React.createRef();

class FeedbackForm extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    handleChangeContactoptions = placeadoptions => {
        this.setState({ tmpplaceadoptions:placeadoptions});
    };

    state = {
        Name: "",
        Email: "",
        UserType: "agent",
        Message: "",
        postingForm: false,
        ErrorMsg: "",
        csvData: {}
    }

    componentWillReceiveProps(nextProps) {
        if(JSON.stringify(this.props.profile) !== JSON.stringify(nextProps.profile) && 
        nextProps.profile && !nextProps.isAnonymous){
            this.setState({
                Name: nextProps.profile.FirstName + " " + nextProps.profile.LastName,
                Email: nextProps.profile.Email ? nextProps.profile.Email : "",
                UserType: nextProps.audience.type
            });
        }
                    
    }

    isValid = () => {
        const { Name, Email, Message} = this.state;
        var errMsg = "";
        const recaptchaValue = recaptchaRef.current.getValue();

        if(!Name || Name === ""){
            errMsg = "Please enter your Name";
        }
        else if(!Email || Email === ""){
            errMsg = "Please enter your Email";
        }
        // eslint-disable-next-line
        else if(Email && !(/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(Email)){
            errMsg = "Please enter a valid Email";
        }
        else if(!Message || Message === ""){
            errMsg = "Please enter your Message";
        }
        else if(!recaptchaValue){
            errMsg = "Oops, you have to check the captcha!";
        }
        
        this.setState({ErrorMsg : errMsg});

        return(errMsg === "");
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({[name]: value, ErrorMsg: ""});

    }

    onSubmitForm = (e) => {
        if(e) e.preventDefault();

        if (this.isValid()) {
            var self = this;

            var url = window.sessionStorage.getItem("RootAPI") + "sendfeedback";

            var reqBody = {
                SenderName: self.state.Name,
                SenderEmail: self.state.Email,
                SenderType: self.state.UserType,
                Comment: self.state.Message,
            }
            
            self.setState({postingForm: true});
            fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + window.sessionStorage.getItem("APIKey")
                },
                method: "POST",
                body: JSON.stringify(reqBody)
            })
            .then(response => {
                if (response.ok) return response.json()
                else throw Error(response.statusText)
            })
            .then(json => {
                self.setState({Message: "", postingForm: false});
                if(self.props.showNotification) {
                    self.props.showNotification(
                        "success",
                        "",
                        "Thanks for your feedback. We will get back to you soon.",
                        5000);
                }    
            })
            .catch(function (data) {
                self.setState({postingForm: false});
                if(self.props.showNotification) {
                    self.props.showNotification(
                        "success",
                        "",
                        "Failed sending your feedback. Please try again later.",
                        5000);
                }    
            });
        }
    }    

    render() {
        const { classes } = this.props;

        const { Name, Email, UserType, Message, ErrorMsg, postingForm } = this.state;

        return (
            <div>
                <section className="text-center"> 
                    <div className="inner-header">    
                        <div className="col-md-12 col-sm-12 col-xl-12">
                            <Typography  className={classes.typographytitle + " banner-title"} variant="h1">
                                <span>
                                    {(CommonCustomizationOptions && CommonCustomizationOptions.FeedbackFormDetails && 
                                        CommonCustomizationOptions.FeedbackFormDetails.TitleText && CommonCustomizationOptions.FeedbackFormDetails.TitleText !== "") ? 
                                        CommonCustomizationOptions.FeedbackFormDetails.TitleText : "Feedback"}                                            
                                </span>
                            </Typography>
                            <Typography variant="h2" className={classes.typographytext + " banner-sub-title"}>
                                <span>
                                        {(CommonCustomizationOptions && CommonCustomizationOptions.FeedbackFormDetails && 
                                            CommonCustomizationOptions.FeedbackFormDetails.SubTitleText1 && CommonCustomizationOptions.FeedbackFormDetails.SubTitleText1 !== "") ? 
                                            CommonCustomizationOptions.FeedbackFormDetails.SubTitleText1 : "Help us Improve"}
                                        <span>
                                        {(CommonCustomizationOptions && CommonCustomizationOptions.FeedbackFormDetails && 
                                            CommonCustomizationOptions.FeedbackFormDetails.SubTitleText2 && CommonCustomizationOptions.FeedbackFormDetails.SubTitleText2 !== "") ? 
                                            CommonCustomizationOptions.FeedbackFormDetails.SubTitleText2 : ""}
                                        </span> 
                                        <span>
                                        {(CommonCustomizationOptions && CommonCustomizationOptions.FeedbackFormDetails && 
                                            CommonCustomizationOptions.FeedbackFormDetails.SubTitleText3 && CommonCustomizationOptions.FeedbackFormDetails.SubTitleText3 !== "") ? 
                                            CommonCustomizationOptions.FeedbackFormDetails.SubTitleText3 : ""}
                                        </span>
                                    </span>
                            </Typography>
                        </div>
                    </div>                        
                </section>
                <section className="custom-container">
                    <div className="white-card-cnt-box">
                        <div className="white-card-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="section-title text-center">
                                            <Typography className={classes.typographytext} component={'span'} variant="body2">
                                                <div className="heading-sub-text mg-bt-20">                                                    
                                                    {(CommonCustomizationOptions && CommonCustomizationOptions.FeedbackFormDetails && 
                                                        CommonCustomizationOptions.FeedbackFormDetails.InLineTitleText && 
                                                        CommonCustomizationOptions.FeedbackFormDetails.InLineTitleText !== "") ? 
                                                        CommonCustomizationOptions.FeedbackFormDetails.InLineTitleText : 
                                                        "Have an idea on how we can make our site better? Have you found a bug or an issue you'd like to report? Please use this form to let us know. We'll get back to you as soon as possible. Thanks for your help!"
                                                    }
                                                </div>
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography  className={classes.contactusdetail} component={'span'} variant="body2">
                                                <div className="contactus-send-message">
                                                    <div className="row mg-tp-30">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                {ErrorMsg && ErrorMsg !== "" &&
                                                                    <div className="col-md-12 col-sm-12 mg-bt-5 text-center">
                                                                        <div className="submit-meg-box error">{ErrorMsg}</div>
                                                                    </div>
                                                                }
                                                                <div className="col-xl-4 col-sm-6  mg-bt-30">
                                                                    <input className={"contactus-field-input"} placeholder="Name" name="Name" type="text" onChange={this.handleInputChange} value={Name}/>
                                                                </div>
                                                                <div className="col-xl-4 col-sm-6  mg-bt-30">
                                                                    <input className={"contactus-field-input"} placeholder="Email" name="Email" type="text" onChange={this.handleInputChange} value={Email}/>
                                                                </div>
                                                                <div className="col-xl-4 col-sm-4 mg-bt-30">
                                                                    <Typography className={classes.selectdropdown} component={'span'} variant="body2">
                                                                        <Select
                                                                            value={getFormattedValueForReactSelect(userTypeOptions, ((UserType) ? UserType : "agent"), false)}
                                                                            onChange={(e) => this.handleInputChange({target: {name: 'UserType', value: e.value}})}
                                                                            options={getFormattedOptionsForReactSelect(userTypeOptions)}
                                                                            className="type-select"
                                                                            classNamePrefix="react-select"
                                                                        />
                                                                    </Typography>
                                                                </div>
                                                                <div className="col-xl-12 col-sm-12 mg-bt-30">
                                                                    <textarea className="contactus-field-input" cols="50" rows="4" name="Message" placeholder="Message" onChange={this.handleInputChange} value={Message} />
                                                                </div>
                                                                <div className="col-xl-12 col-sm-12 dp-block recaptcha-box">
                                                                    <ReCAPTCHA
                                                                        sitekey={SITE_KEY}
                                                                        ref={recaptchaRef}
                                                                        style={{transform:'scale(0.97)', WebkitTransform:'scale(0.92)', TransformOrigin:'0 0', WebkitTransformOrigin:'0 0'}}
                                                                    />
                                                                </div>
                                                                <div className="col-xl-12 col-sm-12 text-right mg-bt-10">
                                                                    <Button id="submit_bt" className={classes.typographybutton + " theme-btn min-width"} onClick={this.onSubmitForm} disabled={postingForm} ref="btnSubmit" type="button">
                                                                        {postingForm ?
                                                                        <Loader inline="true" width="18" height="18" /> :
                                                                        <span> Submit</span>
                                                                        }
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const styles = theme => ({
   
    typographytitle: {
        fontWeight:'normal',
        lineHeight:'normal',
        letterSpacing:'inherit',
        color:theme.palette.primary.title,
        '&.MuiTypography-h1': {
            fontWeight:'normal',
            lineHeight:'normal',
            letterSpacing:'inherit',
        },
        '&.MuiTypography-h2': {
            fontWeight:'normal',
            lineHeight:'normal',
            letterSpacing:'inherit',
        } 
    },    
    typographytext:{
        color: theme.palette.primary.text,
        '&.MuiTypography-h2': {
            fontWeight:'normal',
            letterSpacing:'inherit',
        }
    },
    typographylink: {
        '& a': {
            color: theme.palette.secondary.main,
            '&:hover': {
                color: theme.palette.primary.main,
            },
            '&:focus': {
                color: theme.palette.primary.main,
            },
        },
    },    
    contactusdetail:{
        '& label': {
            color: theme.palette.primary.textbold,
        },
        '& .contactus-info': {
            color: theme.palette.primary.text,
        },
        '& a': {
            color: theme.palette.secondary.main,
            '&:hover': {
                color: theme.palette.primary.main,
            },
            '&:focus': {
                color: theme.palette.primary.main,
            }
        },
        '& .submit-meg-box': {
            '&.active':{
                background: theme.palette.success.light,
                color: theme.palette.success.main,
            },
            '&.error':{
                background: theme.palette.error.light,
                color: theme.palette.error.main,
            },
        }
    },    
    typographybutton:{
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.text,
        '&.MuiButton-contained': {
          boxShadow:'inherit',
          letterSpacing:'inherit',
          lineHeight:'normal',
          textTransform:'none',
        }, 
        '&.MuiButton-root': {
            minWidth:'inherit',
            fontWeight:'inherit',
        },
        '&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.text,
        },
        '&:focus': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.text,
        },
    },
    selectdropdown:{
        '& .type-select': {
            '& .react-select__control': {
                '& .react-select__value-container': {
                    '& .react-select__placeholder': {
                        color: theme.palette.default.text,
                    }
                },
                '& .react-select__indicator': {
                    '&:after':{
                        color: theme.palette.default.text,
                    },
                },
                '& .react-select__single-value': {
                    color: theme.palette.default.text,
                },
            },
            '& .react-select__menu': {
                '& .react-select__menu-list': {
                    '& .react-select__option': {
                        color: theme.palette.default.text,
                    }
                }
            },
        },
        '&.without-label-dropdown': {
            '& .type-select': {
                '& .react-select__control': {
                    '& .react-select__indicator': {
                        '&:after':{
                            color: theme.palette.primary.text,
                        },
                    },
                    '& .react-select__single-value': {
                        color: theme.palette.primary.text,
                    },
                },
            },
        }
    },
});


export default withStyles(styles)(FeedbackForm);
import React from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';


const LI_RETURN_URL = `${window.location.origin}/socialaccounts/linkedin`;

function SocialLoginLinkedIn(props) {  
    
    const { linkedInLogin } = useLinkedIn({
        clientId: (props.LinkedInCID || '78su9znp9x695m'),
        redirectUri: LI_RETURN_URL,
        scope: "r_emailaddress r_liteprofile",
        onSuccess: (code) => {
            if(code && code !== ""){
                fetch(window.sessionStorage.getItem("RootAPI") + "accesstokens/soclog",
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + window.sessionStorage.getItem("APIKey")
                        },
                        method: "POST",                        
                        body: JSON.stringify({
                            method: "linkedin",
                            code: code,
                            redirect_url: LI_RETURN_URL
                        })
                    })
                    .then(response => {
                        if (response.ok) return response.json();
                        else return null;
                    })
                    .then(json => {                
                        var returnObj = {};
                        if(json && json.id && json.email){
                            returnObj.SToken = json.id;
                            returnObj.Email = json.email;
                            returnObj.Firstname = json.firstname;
                            returnObj.LastName = json.lastname;

                            if(props && props.callBackFun){
                                props.callBackFun(returnObj);
                            }
                        }
                    })
                    .catch(function(data){ 
                        console.log('Error in signInWithLinkedIn');
                        console.log(data);
                        if(props && props.callBackFun){
                            props.callBackFun({Error: "Invalid account or credentials"});
                        }
                    });
            }
        },        
        onError: (error) => {
            //console.log('Error in signInWithLinkedIn');
            //console.log(error);
        },
    });
    
    return (
        <span className="social-btn sm-btn btn-lin" onClick={linkedInLogin}>
            <span><i className="fa fa-linkedin"></i></span>
        </span>
    );
}   

export default SocialLoginLinkedIn;
import { CALL_API } from '../store/api';

export const CHAT_SELECT_CLIENT_REQUEST = 'CHAT_SELECT_CLIENT_REQUEST';
export const CHAT_SELECT_CLIENT_SUCCESS = 'CHAT_SELECT_CLIENT_SUCCESS';
export const CHAT_SELECT_CLIENT_FAILURE = 'CHAT_SELECT_CLIENT_FAILURE';
export const CHAT_SELECT_CLIENT_DESELECT = 'CHAT_SELECT_CLIENT_DESELECT';

export const CHAT_CLIENT_CHAT_REQUEST = 'CHAT_CLIENT_CHAT_REQUEST';
export const CHAT_CLIENT_CHAT_SUCCESS = 'CHAT_CLIENT_CHAT_SUCCESS';
export const CHAT_CLIENT_CHAT_FAILURE = 'CHAT_CLIENT_CHAT_FAILURE';

export const CHAT_UPDATE_CHAT_SUCCESS = 'CHAT_UPDATE_CHAT_SUCCESS';

export const CHAT_UNREAD_COUNT_REQUEST = 'CHAT_UNREAD_COUNT_REQUEST';
export const CHAT_UNREAD_COUNT_SUCCESS = 'CHAT_UNREAD_COUNT_SUCCESS';
export const CHAT_UNREAD_COUNT_FAILURE = 'CHAT_UNREAD_COUNT_FAILURE';

export const CHAT_UPDATE_READON_REQUEST = 'CHAT_UPDATE_READON_REQUEST';
export const CHAT_UPDATE_READON_SUCCESS = 'CHAT_UPDATE_READON_SUCCESS';
export const CHAT_UPDATE_READON_FAILURE = 'CHAT_UPDATE_READON_FAILURE';

export const CHAT_CHAT_WINDOW_SUCCESS = 'CHAT_CHAT_WINDOW_SUCCESS';

export const ADCHAT_UNREAD_COUNT_REQUEST = 'ADCHAT_UNREAD_COUNT_REQUEST';
export const ADCHAT_UNREAD_COUNT_SUCCESS = 'ADCHAT_UNREAD_COUNT_SUCCESS';
export const ADCHAT_UNREAD_COUNT_FAILURE = 'ADCHAT_UNREAD_COUNT_FAILURE';
export const ADCHAT_CLIENT_CHAT_REQUEST = 'ADCHAT_CLIENT_CHAT_REQUEST';
export const ADCHAT_CLIENT_CHAT_SUCCESS = 'ADCHAT_CLIENT_CHAT_SUCCESS';
export const ADCHAT_CLIENT_CHAT_FAILURE = 'ADCHAT_CLIENT_CHAT_FAILURE';
export const ADCHAT_UPDATE_CHAT_SUCCESS = 'ADCHAT_UPDATE_CHAT_SUCCESS';

export const selectChatClient = id => (dispatch, getState) => {
    const { Id } = getState().session.audience;

    return dispatch({
        [CALL_API]: {
            types: [CHAT_SELECT_CLIENT_REQUEST, CHAT_SELECT_CLIENT_SUCCESS, CHAT_SELECT_CLIENT_FAILURE],
            method: 'GET',
            endpoint: `agents/${Id}/clients/${id}`
        }
    });
}; 

export const deselectChatClient = () => ({
    type: CHAT_SELECT_CLIENT_DESELECT
});

export const setChatWindowStatus = (isOpen, isLNO) => ({
    type: CHAT_CHAT_WINDOW_SUCCESS,
    isOpen,
    isLNO
});

export const fetchChats = (id, flag, lnoFlag) => (dispatch, getState) => {
    const { session } = getState();
    const { type, Id } = session.audience;

    let endpoint;

    if (type === 'agent') {
        endpoint = `agents/${Id}`;

        if (id) {
            endpoint +=  `/clients/${id}`;
        }
    }
    else {
        endpoint = `clients/${Id}`;
    }

    endpoint += '/trackchats';

    const body = {
        Count: 10
    };

    if(lnoFlag){
        body.ForLNO = true;
    }

    return dispatch({
        [CALL_API]: {
            types: [CHAT_CLIENT_CHAT_REQUEST, CHAT_CLIENT_CHAT_SUCCESS, CHAT_CLIENT_CHAT_FAILURE],
            method: 'POST',
            endpoint: endpoint,
            body: body            
        },
        flag: flag
    });
}

export const fetchAdvisorChats = (id, flag) => (dispatch, getState) => {
    const { session } = getState();
    const { type, Id } = session.audience;

    let endpoint;

    if (type === 'agent') {
        endpoint = `agents/${Id}`;

        if (id) {
            endpoint +=  `/clients/${id}`;
        }
    }
    else {
        endpoint = `clients/${Id}`;
    }

    endpoint += '/trackchats';

    const body = {
        Count: 10
    };
    
    body.ForLNO = true;    

    return dispatch({
        [CALL_API]: {
            types: [ADCHAT_CLIENT_CHAT_REQUEST, ADCHAT_CLIENT_CHAT_SUCCESS, ADCHAT_CLIENT_CHAT_FAILURE],
            method: 'POST',
            endpoint: endpoint,
            body: body            
        },
        flag: flag
    });
}

/* export const updateChats = (result) => (dispatch, getState) => {
    return dispatch({type: CHAT_UPDATE_CHAT_SUCCESS, result: result })
} */

export const updateChats = (result) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch({type: CHAT_UPDATE_CHAT_SUCCESS, result: result})
        resolve();
    });
}

export const updateAdvisorChats = (result) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch({type: ADCHAT_UPDATE_CHAT_SUCCESS, result: result})
        resolve();
    });
}

export const fetchUnreadCount = (clientId, lnoFlag) => (dispatch, getState) => {
    const { session } = getState();
    const { type, Id } = session.audience;

    let endpoint;

    if (type === 'agent') {
        endpoint = `agents/${Id}`;
        if(clientId) {
            endpoint += `/clients/${clientId}`;    
        }
    }
    else {
        endpoint = `clients/${Id}`;
    }

    endpoint += '/unreadchatcount';

    if(lnoFlag){
        endpoint += '?ForLNO=1';
    }
    
    return dispatch({
        [CALL_API]: {
            types: [CHAT_UNREAD_COUNT_REQUEST, CHAT_UNREAD_COUNT_SUCCESS, CHAT_UNREAD_COUNT_FAILURE],
            method: 'GET',
            endpoint: endpoint    
        }
    });
}

export const fetchAdvisorUnreadCount = (clientId) => (dispatch, getState) => {
    const { session } = getState();
    const { type, Id } = session.audience;

    let endpoint;

    if (type === 'agent') {
        endpoint = `agents/${Id}`;
        if(clientId) {
            endpoint += `/clients/${clientId}`;    
        }
    }
    else {
        endpoint = `clients/${Id}`;
    }

    endpoint += '/unreadchatcount';
    
    endpoint += '?ForLNO=1';
    
    return dispatch({
        [CALL_API]: {
            types: [ADCHAT_UNREAD_COUNT_REQUEST, ADCHAT_UNREAD_COUNT_SUCCESS, ADCHAT_UNREAD_COUNT_FAILURE],
            method: 'GET',
            endpoint: endpoint    
        }
    });
}

export const updateReadOn = (body, clientId) => (dispatch, getState) => {
    const { session } = getState();
    const { type, Id } = session.audience;

    let endpoint;

    if (type === 'agent') {
        endpoint = `agents/${Id}`;
        if(clientId) {
            endpoint += `/clients/${clientId}`;    
        }
    }
    else {
        endpoint = `clients/${Id}`;
    }

    endpoint += '/updatereadon';
    
    return dispatch({
        [CALL_API]: {
            types: [CHAT_UPDATE_READON_REQUEST, CHAT_UPDATE_READON_SUCCESS, CHAT_UPDATE_READON_FAILURE],
            method: 'POST',
            endpoint: endpoint,
            body: body
        }
    });
}
import { CALL_API } from '../store/api';

export const ANT_CNT_REQUEST = 'ANT_CNT_REQUEST';
export const ANT_CNT_SUCCESS = 'ANT_CNT_SUCCESS';
export const ANT_CNT_FAILURE = 'ANT_CNT_FAILURE';
export const ANT_LST_REQUEST = 'ANT_LST_REQUEST';
export const ANT_LST_SUCCESS = 'ANT_LST_SUCCESS';
export const ANT_LST_FAILURE = 'ANT_LST_FAILURE';
export const ANT_ADD_SUCCESS = 'ANT_ADD_SUCCESS';
export const ANT_UPDATE_SUCCESS = 'ANT_UPDATE_SUCCESS';
export const ANT_DEL_SUCCESS = 'ANT_DEL_SUCCESS';
export const ANT_RESET_SUCCESS = 'ANT_RESET_SUCCESS';
export const ANT_POST_REQUEST = 'ANT_POST_REQUEST';
export const ANT_POST_SUCCESS = 'ANT_POST_SUCCESS';
export const ANT_POST_FAILURE = 'ANT_POST_FAILURE';
export const ANT_SEARCH_PARAMS_REQUEST = 'ANT_SEARCH_PARAMS_REQUEST';
export const ANT_SEARCH_PARAMS_SUCCESS = 'ANT_SEARCH_PARAMS_SUCCESS';
export const ANT_SEARCH_PARAMS_FAILURE = 'ANT_SEARCH_PARAMS_FAILURE';

export const fetchNewClientsCount = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [ANT_CNT_REQUEST, ANT_CNT_SUCCESS, ANT_CNT_FAILURE],
            method: 'POST',        
            endpoint: "analytics/getcount/newclients",
            body
        },
        paramkey: body.paramkey, params: body.params
    });
};

export const fetchRegisteredUsersCount = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [ANT_CNT_REQUEST, ANT_CNT_SUCCESS, ANT_CNT_FAILURE],
            method: 'POST',        
            endpoint: "analytics/getcount/registereduser",
            body
        },
        paramkey: body.paramkey, params: body.params
    });
};

export const fetchClientLoginsCount = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [ANT_CNT_REQUEST, ANT_CNT_SUCCESS, ANT_CNT_FAILURE],
            method: 'POST',        
            endpoint: "analytics/getcount/uniquelogin",
            body
        },
        paramkey: body.paramkey, params: body.params
    });
};

export const fetchAgentLoginsCount = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [ANT_CNT_REQUEST, ANT_CNT_SUCCESS, ANT_CNT_FAILURE],
            method: 'POST',        
            endpoint: "analytics/getcount/uniquelogin",
            body
        },
        paramkey: body.paramkey, params: body.params
    });
};

export const fetchTopFavoritedProperties = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [ANT_LST_REQUEST, ANT_LST_SUCCESS, ANT_LST_FAILURE],
            method: 'POST',        
            endpoint: "analytics/get/favoritedproperties",
            body
        },
        paramkey: body.paramkey, params: body.params
    });
};

export const fetchTopPropertyInquiries = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [ANT_LST_REQUEST, ANT_LST_SUCCESS, ANT_LST_FAILURE],
            method: 'POST',        
            endpoint: "analytics/get/propertyinquiry",
            body
        },
        paramkey: body.paramkey, params: body.params
    });
};

export const fetchTopShowingRequests = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [ANT_LST_REQUEST, ANT_LST_SUCCESS, ANT_LST_FAILURE],
            method: 'POST',        
            endpoint: "analytics/get/showingrequest",
            body
        },
        paramkey: body.paramkey, params: body.params
    });
};

export const fetchTopContactAgentRequests = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [ANT_LST_REQUEST, ANT_LST_SUCCESS, ANT_LST_FAILURE],
            method: 'POST',        
            endpoint: "analytics/get/contactagentrequest",
            body
        },
        paramkey: body.paramkey, params: body.params
    });
};

export const addToBucket = (obj) => (dispatch, getState) => {
    return dispatch({type: ANT_ADD_SUCCESS, obj: obj })
}

export const removeFromBucket = (obj) => (dispatch, getState) => {
    return dispatch({type: ANT_DEL_SUCCESS, obj: obj })
}

export const resetBucket = () => (dispatch, getState) => {
    return dispatch({type: ANT_RESET_SUCCESS })
}

export const updateBucket = (obj) => (dispatch, getState) => {
    return dispatch({type: ANT_UPDATE_SUCCESS, obj: obj })
}

export const postBucket = (key) => (dispatch, getState) => {
    //console.log("postBucket: ", key);
    const { bucket } = getState().analytics;
    if(bucket && bucket.length > 0){
        //console.log("submitting bucket: ", JSON.stringify(bucket));
        var endpoint = "analytics/set/" + ((key === "tlcwidget") ? "widgetinteracteddata" : "searchfilter");
        //console.log("endpoint: ", endpoint);
        // return dispatch({type: ANT_RESET_SUCCESS });
        return dispatch({
            [CALL_API]: {
                types: [ANT_POST_REQUEST, ANT_POST_SUCCESS, ANT_POST_FAILURE],
                method: 'POST',        
                endpoint: endpoint,
                body: bucket
            }
        });
    }
    else {
        //console.log("emptying bucket");
        return dispatch({type: ANT_POST_SUCCESS });
    }    
}

export const fetchTopSearchParams = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [ANT_LST_REQUEST, ANT_LST_SUCCESS, ANT_LST_FAILURE],
            method: 'POST',        
            endpoint: "analytics/getlist/topsearchattributes",
            body
        },
        paramkey: body.paramkey, params: body.params
    });
};

export const fetchTLCWidgetCount = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [ANT_CNT_REQUEST, ANT_CNT_SUCCESS, ANT_CNT_FAILURE],
            method: 'POST',        
            endpoint: "analytics/getcount/widgetinteraction",
            body
        },
        paramkey: body.paramkey, params: body.params
    });
};

export const fetchTLCWidgetComponentChange = (body, key) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [ANT_LST_REQUEST, ANT_LST_SUCCESS, ANT_LST_FAILURE],
            method: 'POST',        
            endpoint: "analytics/getdata/" + key,
            body
        },
        paramkey: body.paramkey, params: body.params
    });
};
import { CALL_API, Schemas } from "../store/api";

export const CLIENTS_REQUEST = "CLIENTS_REQUEST";
export const CLIENTS_SUCCESS = "CLIENTS_SUCCESS";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
export const CLIENTS_FETCH_SUCCESS = "CLIENTS_FETCH_SUCCESS";
export const CLIENT_SELECT = "CLIENT_SELECT";
export const CLIENT_DESELECT = "CLIENT_DESELECT";
export const CLIENTS_FAILURE = "CLIENTS_FAILURE";

export const CLIENT_IGNORE_SUCCESS = "CLIENT_IGNORE_SUCCESS";
export const CLIENT_DELETE = "CLIENT_DELETE";
/* export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST';
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const CLIENT_DELETE_FAILURE = 'CLIENT_DELETE_FAILURE'; */

export const ALL_CLIENTS_REQUEST = "ALL_CLIENTS_REQUEST";
export const ALL_CLIENTS_SUCCESS = "ALL_CLIENTS_SUCCESS";
export const ALL_CLIENTS_FAILURE = "ALL_CLIENTS_FAILURE";

export const CONNECT_CLIENT_REQUEST = "CONNECT_CLIENT_REQUEST";
export const CONNECT_CLIENT_SUCCESS = "CONNECT_CLIENT_SUCCESS";
export const CONNECT_CLIENT_FAILURE = "CONNECT_CLIENT_FAILURE";

export const CLIENT_PROFILE_UPDATED = "CLIENT_PROFILE_UPDATED";
export const CLIENT_PROFILE_UPDATED_FALSE = "CLIENT_PROFILE_UPDATED_FALSE";

export const fetchClient = (id) => (dispatch, getState) => {
  const { Id } = getState().session.audience;

  return dispatch({
    [CALL_API]: {
      types: [CLIENTS_REQUEST, CLIENTS_SUCCESS, CLIENTS_FAILURE],
      method: "GET",
      endpoint: `agents/${Id}/clients/${id}`,
      schema: Schemas.CLIENT,
    },
  });
};

export const updateClient = (id, body) => (dispatch, getState) => {
  const { Id } = getState().session.audience;

  return dispatch({
    [CALL_API]: {
      types: [CLIENTS_REQUEST, CLIENT_UPDATE_SUCCESS, CLIENTS_FAILURE],
      method: "PUT",
      body,
      endpoint: `agents/${Id}/clients/${id}`,
    },
  });
};

export const connectClient = (body) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      types: [
        CONNECT_CLIENT_REQUEST,
        CONNECT_CLIENT_SUCCESS,
        CONNECT_CLIENT_FAILURE,
      ],
      method: "POST",
      body,
      endpoint: `agents/connecttoclient`,
    },
  });
};

export const fetchClients = () => (dispatch, getState) => {
  const { Id } = getState().session.audience;

  return dispatch({
    [CALL_API]: {
      types: [CLIENTS_REQUEST, CLIENTS_FETCH_SUCCESS, CLIENTS_FAILURE],
      method: "GET",
      endpoint: `agents/${Id}/clients`,
      schema: Schemas.CLIENT_ARRAY,
    },
  });
};

/* export const selectClient = id => ({
    type: CLIENT_SELECT, 
    id
}); */

export const selectClient = (id) => (dispatch, getState) => {
  const { Id } = getState().session.audience;

  return dispatch({
    [CALL_API]: {
      types: [CLIENTS_REQUEST, CLIENT_SELECT, CLIENTS_FAILURE],
      method: "GET",
      endpoint: `agents/${Id}/clients/${id}`,
      schema: Schemas.CLIENT,
    },
  });
};

export const deselectClient = () => ({
  type: CLIENT_DESELECT,
});

export const clientProfileUpdated = (yes = true) => {
  var show = yes;
  if (sessionStorage.getItem("DoNotShowProfileNote")) {
    show = sessionStorage.getItem("DoNotShowProfileNote");
    show = !show;
  }
  sessionStorage.removeItem("DoNotShowProfileNote");
  return {
    type: show ? CLIENT_PROFILE_UPDATED : CLIENT_PROFILE_UPDATED_FALSE,
  };
};

/* export const deleteClient = id => (dispatch, getState) => {
    const { Id } = getState().session.audience;

    return dispatch({
        [CALL_API]: {
            types: [CLIENT_DELETE_REQUEST, CLIENT_DELETE_SUCCESS, CLIENT_DELETE_FAILURE],
            method: 'DELETE',
            endpoint: `agents/${Id}/clients/${id}`,
            id: id
        }
    });
}; */

export const deleteClient = (id) => ({
  type: CLIENT_DELETE,
  id,
});

export const addDeleteBookmark =
  (method, body, clientId) => (dispatch, getState) => {
    const { Id, type } = getState().session.audience;
    var url = window.sessionStorage.getItem("APIAudience");
    if (type === "agent") url += `${Id}/clients/${clientId}/bookmarks`;
    else url += `${Id}/bookmarks`;

    return dispatch({
      [CALL_API]: {
        types: [CLIENTS_REQUEST, CLIENT_IGNORE_SUCCESS, CLIENTS_FAILURE],
        method: method,
        body: body,
        endpoint: url,
      },
    });
  };

export const addDeleteFavorite =
  (method, body, clientId) => (dispatch, getState) => {
    const { Id, type } = getState().session.audience;
    var url = window.sessionStorage.getItem("APIAudience");
    if (type === "agent") url += `${Id}/clients/${clientId}/favorites`;
    else url += `${Id}/favorites`;

    return dispatch({
      [CALL_API]: {
        types: [CLIENTS_REQUEST, CLIENT_IGNORE_SUCCESS, CLIENTS_FAILURE],
        method: method,
        body: body,
        endpoint: url,
      },
    });
  };

export const addDeleteRemove =
  (method, body, clientId) => (dispatch, getState) => {
    const { Id, type } = getState().session.audience;
    var url = window.sessionStorage.getItem("APIAudience");
    if (method === "POST") {
      if (type === "agent") url += `${Id}/clients/${clientId}/removed/${body}`;
      else url += `${Id}/removed/${body}`;
    } else url += `${Id}/removed`;

    return dispatch({
      [CALL_API]: {
        types: [CLIENTS_REQUEST, CLIENT_IGNORE_SUCCESS, CLIENTS_FAILURE],
        method: method,
        body: body,
        endpoint: url,
      },
    });
  };

export const fetchAllClients = () => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [ALL_CLIENTS_REQUEST, ALL_CLIENTS_SUCCESS, ALL_CLIENTS_FAILURE],
      method: "GET",
      endpoint: `agents/allclients/get`,
    },
  });
};

import React from "react";
import { GetSectionCustomizationOptions } from "components/util";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";

const PropertyDetailPageCustomizationOptions = GetSectionCustomizationOptions(
  "PropertyDetailPageOptions"
);

const ExternalGetPreApprovalModal = ({
  show,
  title,
  onHide,
  classes,
  showButton = true,
  desc,
}) => {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Typography
        className={(classes && classes.modalpopuptlc) || ""}
        component={"span"}
        variant="body2"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <Typography
              className={(classes && classes.typographytitle) || ""}
              variant="h2"
            >
              {title || "Get Pre-Approved"}
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="row">
                {PropertyDetailPageCustomizationOptions &&
                PropertyDetailPageCustomizationOptions.ExternalGetPreApprovalDescription &&
                PropertyDetailPageCustomizationOptions.ExternalGetPreApprovalDescription !==
                  "" ? (
                  <Typography
                    component={"div"}
                    className={(classes && classes.propertydescription) || ""}
                    variant="body2"
                  >
                    <div className="property-description">
                      {desc ? (
                        desc
                      ) : (
                        <span>
                          <h3>About Our Affiliates</h3>
                          <p>Here's what you need to know about Ark Homes and its affiliates.</p>
                          <p>AFFILIATED BUSINESS ARRAMGEMENT DISCLOSURE</p>
                          <p>ArkHomes.com (&ldquo;Ark Homes&rdquo;)</p>
                          <p>We are pleased to share information regarding our affiliate (Ark Mortgage, Inc.), and how they may be able to assist you moving forward in your home buying process.</p>
                          <p>NOTICE OF BUSINESS AFFILIATION</p>
                          <p>Please note that Ark Homes has a business relationship with Ark Mortgage, Inc., that both entities are wholly owned subsidiaries of Ark Holdings Associates, Inc. Because of this relationship, this referral may provide Ark Homes and/or Ark Holdings Associates Inc. with a financial or other benefit. </p>
                          <p>Following is the estimated range of charges by Ark Mortgage, Inc. for the settlement services it provides. These charges are customary and are provided so that you may compare them to other service provider charges. </p>
                          <p><u>Loan Origination Fees</u>: $1,345</p>
                          <p><u>Discount points (optional)</u>: 0%- 4% of Loan Amount. Points vary based on borrower’s selection of interest rate.</p>
                          <p><u>Third Party Fees</u>: Third parties may also charge fees (e.g., credit report, appraisal, and/or tax service/flood cert fees). See the Loan Estimate provided within three days of application for a disclosure of third-party charges required to obtain a loan, if applicable.</p>
                          <p>You are NOT required to use the listed provider as a condition for settlement of your loan on or purchase or refinance of the subject property. THERE ARE FREQUENTLY OTHER SETTLEMENT SERVICE PROVIDERS AVAILABLE WITH SIMILAR SERVICES. YOU ARE FREE TO SHOP AROUND TO DETERMINE THAT YOU ARE RECEIVING THE BEST SERVICES AND THE BEST RATE FOR THESE SERVICES.</p>
                          <h3 className="pd-tp-10">Acknowledgement</h3>
                          <p>By clicking “Call now” or “Apply Online” I/We have read this disclosure form, and understand that Ark Homes is referring me/us to an affiliated business to purchase settlement services and that Ark Homes may receive financial or other benefit as the result of my/our use of this referral.</p>
                        </span>
                      )}
                    </div>
                  </Typography>
                ) : null}
                {showButton ? (
                  <>
                    {PropertyDetailPageCustomizationOptions &&
                    PropertyDetailPageCustomizationOptions.ExternalCallNowButtonNumber &&
                    PropertyDetailPageCustomizationOptions.ExternalCallNowButtonNumber !==
                      "" ? (
                      <div className="col-md-6 col-sm-12 mg-tp-40 text-center ">
                        <a
                          href={
                            "tel:" +
                            PropertyDetailPageCustomizationOptions.ExternalCallNowButtonNumber.replace(
                              / /g,
                              ""
                            )
                              .replace(/-/g, "")
                              .replace(/\(/g, "")
                              .replace(/\)/g, "")
                          }
                          alt="External Call Link"
                          aria-label="External Call Link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            variant="contained"
                            className={
                              ((classes && classes.typographybutton) || "") +
                              " theme-btn pre-approve-btn simple-btn"
                            }
                          >
                            {PropertyDetailPageCustomizationOptions &&
                            PropertyDetailPageCustomizationOptions.ExternalCallNowButtonText &&
                            PropertyDetailPageCustomizationOptions.ExternalCallNowButtonText !==
                              ""
                              ? PropertyDetailPageCustomizationOptions.ExternalCallNowButtonText
                              : "Call Now"}
                          </Button>
                        </a>
                      </div>
                    ) : null}
                    {PropertyDetailPageCustomizationOptions &&
                    PropertyDetailPageCustomizationOptions.ExternalGetPreApprovedButtonUrl &&
                    PropertyDetailPageCustomizationOptions.ExternalGetPreApprovedButtonUrl !==
                      "" ? (
                      <div className="col-md-6 col-sm-12 mg-tp-40 text-center">
                        <a
                          href={
                            PropertyDetailPageCustomizationOptions.ExternalGetPreApprovedButtonUrl
                          }
                          alt="Get Pre Approved Link"
                          aria-label="Get Pre Approved Link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            variant="contained"
                            className={
                              ((classes && classes.typographybutton) || "") +
                              " theme-btn pre-approve-btn"
                            }
                          >
                            {PropertyDetailPageCustomizationOptions &&
                            PropertyDetailPageCustomizationOptions.ExternalGetPreApprovedSubButtonText &&
                            PropertyDetailPageCustomizationOptions.ExternalGetPreApprovedSubButtonText !==
                              ""
                              ? PropertyDetailPageCustomizationOptions.ExternalGetPreApprovedSubButtonText
                              : "Apply Online"}
                          </Button>
                        </a>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Typography>
    </Modal>
  );
};

export default ExternalGetPreApprovalModal;

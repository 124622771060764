import { CALL_API } from "../store/api";
import Cookies from "universal-cookie";

export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REFRESH_SUCCESS = "AUTH_REFRESH_SUCCESS";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_MANUAL_LOGIN_SUCCESS = "AUTH_MANUAL_LOGIN_SUCCESS";
export const PURL_PREFIX_SAVE = "PURL_PREFIX_SAVE";
export const ACCESSTOKEN_LOGOUT_REQUEST = "ACCESSTOKEN_LOGOUT_REQUEST";
export const ACCESSTOKEN_LOGOUT_SUCCESS = "ACCESSTOKEN_LOGOUT_SUCCESS";
export const ACCESSTOKEN_LOGOUT_FAILURE = "ACCESSTOKEN_LOGOUT_FAILURE";
export const SYNCADS_REQUEST = "SYNCADS_REQUEST";
export const SYNCADS_SUCCESS = "SYNCADS_SUCCESS";
export const SYNCADS_FAILURE = "SYNCADS_FAILURE";

const endpoint = "accesstokens";

export const refreshAuthToken = (id) => (dispatch, getState) => {
  const cookies = new Cookies();
  const authToken = cookies.get("authDetails");

  if (!authToken) {
    return dispatch(logout());
  }

  return dispatch({
    [CALL_API]: {
      types: [AUTH_REQUEST, AUTH_REFRESH_SUCCESS, AUTH_FAILURE],
      method: "POST",
      body: {
        AudienceType: authToken.AudienceType,
        AudienceId: authToken.Audience.Id,
        TokenId: authToken.AccessToken,
      },
      endpoint: `${endpoint}/verifytoken`,
    },
  });
};

export const verifyAuthToken =
  (auth, shouldLogout = true) =>
  (dispatch, getState) => {
    if (shouldLogout) dispatch(logout(true));
    return dispatch({
      [CALL_API]: {
        types: [AUTH_REQUEST, AUTH_REFRESH_SUCCESS, AUTH_FAILURE],
        method: "POST",
        body: {
          AudienceType: auth.AudienceType,
          AudienceId: auth.AudienceId,
          TokenId: auth.AccessToken,
        },
        endpoint: `${endpoint}/verifytoken`,
      },
      skip: auth.skip || false,
    });
  };

export const login = (authType, body) => ({
  /* [CALL_API]: {
        types: [AUTH_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_FAILURE],
        method: 'POST',
        body: body,
        endpoint: `${endpoint}/${authType}`
    }
    */
  [CALL_API]: {
    types: [AUTH_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_FAILURE],
    method: "POST",
    body: body,
    endpoint: `${endpoint}/verifylogin`,
  },
});

export const logout = (PreventRedirect) => (dispatch, getState) => {
  dispatch(logoutAuthToken(PreventRedirect));
  return dispatch({
    type: AUTH_LOGOUT,
  });
};

export const manualLogin = (response) => (dispatch, getState) => {
  return dispatch({
    type: AUTH_MANUAL_LOGIN_SUCCESS,
    response: response,
  });
};

export const setAuthError = (errMsg) => (dispatch, getSelection) => {
  return dispatch({
    type: AUTH_FAILURE,
    error: errMsg,
  });
};

export const setPurlPrefix = (match) => (dispatch, getState) => {
  return dispatch({
    type: PURL_PREFIX_SAVE,
    purlPrefix: match,
  });
};

export const anonymousLogin = () => (dispatch, getState) => {
  if (
    process.env.REACT_APP_AllowAnonymous !== undefined &&
    process.env.REACT_APP_AllowAnonymous === "true"
  ) {
    return dispatch(
      login("Agent", JSON.parse(process.env.REACT_APP_AnonymousAuth))
    );
  }
  return false;
};

export const logoutAuthToken = (PreventRedirect) => (dispatch, getState) => {
  const cookies = new Cookies();
  const authToken = cookies.get("authDetails");
  if (!authToken) return false;
  return dispatch({
    [CALL_API]: {
      types: [
        ACCESSTOKEN_LOGOUT_REQUEST,
        ACCESSTOKEN_LOGOUT_SUCCESS,
        ACCESSTOKEN_LOGOUT_FAILURE,
      ],
      method: "DELETE",
      endpoint: `accesstokens/${authToken.AudienceType.replace(
        "MLSAGENT",
        "AGENTS"
      ).replace("CLIENT", "CLIENTS")}/${authToken.Audience.Id}/${
        authToken.AccessToken
      }`,
    },
    UId: authToken.Audience.Id,
    PreventRedirect: PreventRedirect,
  });
};

export const synchronizeAds = (flag) => (dispatch, getState) => {
  var DeviceUID = window.localStorage.getItem("DeviceUID");
  if (DeviceUID && DeviceUID !== "") {
    return dispatch(syncAds(DeviceUID));
  }

  return false;
};

export const syncAds = (duid) => (dispatch, getState) => {
  var endpoint = `ads/syncads/${duid}`;

  var LastAdsSyncDate = window.localStorage.getItem("LastAdsSyncDate");
  if (!LastAdsSyncDate || LastAdsSyncDate !== new Date().toLocaleDateString()) {
    window.localStorage.setItem(
      "LastAdsSyncDate",
      new Date().toLocaleDateString()
    );
    endpoint = `ads/fullsyncads/${duid}`;
  }

  return dispatch({
    [CALL_API]: {
      types: [SYNCADS_REQUEST, SYNCADS_SUCCESS, SYNCADS_FAILURE],
      method: "GET",
      endpoint: endpoint,
    },
  });
};

//import union from 'lodash/union';
//import difference from 'lodash/difference';
import {
  CHAT_SELECT_CLIENT_REQUEST, 
  CHAT_SELECT_CLIENT_SUCCESS, 
  CHAT_SELECT_CLIENT_FAILURE, 
  CHAT_SELECT_CLIENT_DESELECT,
  CHAT_CLIENT_CHAT_REQUEST, 
  CHAT_CLIENT_CHAT_SUCCESS, 
  CHAT_CLIENT_CHAT_FAILURE,
  CHAT_UPDATE_CHAT_SUCCESS,
  CHAT_UNREAD_COUNT_REQUEST, 
  CHAT_UNREAD_COUNT_SUCCESS, 
  CHAT_UNREAD_COUNT_FAILURE,
  CHAT_UPDATE_READON_REQUEST, 
  CHAT_UPDATE_READON_SUCCESS, 
  CHAT_UPDATE_READON_FAILURE,
  CHAT_CHAT_WINDOW_SUCCESS,
  ADCHAT_UNREAD_COUNT_REQUEST, 
  ADCHAT_UNREAD_COUNT_SUCCESS, 
  ADCHAT_UNREAD_COUNT_FAILURE,
  ADCHAT_CLIENT_CHAT_REQUEST, 
  ADCHAT_CLIENT_CHAT_SUCCESS, 
  ADCHAT_CLIENT_CHAT_FAILURE,
  ADCHAT_UPDATE_CHAT_SUCCESS
} from 'actions/chat';

export default (state = {
  isFetching: false,
  isFetchingClient: false,
  selectedClient: null,
  error: undefined,
  notificationMessage: undefined,
  chats: [],
  isUnreadFetching: false,
  unreadCount: [],
  adChats: [],
  isAdUnreadFetching: false,
  adUnreadCount: [],
  isReadOnUpdating: false,
  isChatWindowOpen: false,
  isChatWithLNO: false
}, action) => {
  switch (action.type) {
    case CHAT_SELECT_CLIENT_REQUEST:
      return {
        ...state,
        isFetchingClient: true,
        error: undefined,
        notificationMessage: undefined
      };
    case CHAT_SELECT_CLIENT_SUCCESS:
        return {
            ...state,
            isFetchingClient: false,
            selectedClient: action.response
        }
    case CHAT_SELECT_CLIENT_DESELECT:
        return {
            ...state,
            isFetchingClient: false,
            selectedClient: null,
            chats: [],
            adChats: []
        }
    case CHAT_SELECT_CLIENT_FAILURE:
      return {
        ...state,
        isFetchingClient: false,
        error: action.error
      };
    case CHAT_CLIENT_CHAT_REQUEST:
      return {
        ...state,
        isFetching: action.flag,
        error: undefined,
        notificationMessage: undefined
      };
    case CHAT_CLIENT_CHAT_SUCCESS:
      return {
          ...state,
          isFetching: false,
          chats: action.response
      }
    case CHAT_CLIENT_CHAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case ADCHAT_CLIENT_CHAT_REQUEST:
      return {
        ...state,
        isFetching: action.flag,
        error: undefined,
        notificationMessage: undefined
      };
    case ADCHAT_CLIENT_CHAT_SUCCESS:
      return {
          ...state,
          isFetching: false,
          adChats: action.response
      }
    case ADCHAT_CLIENT_CHAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case CHAT_UPDATE_CHAT_SUCCESS:
      return {
          ...state,
          isFetchingClient: false,
          chats: [...state.chats, ...action.result]
      }
    case ADCHAT_UPDATE_CHAT_SUCCESS:
      return {
          ...state,
          isFetchingClient: false,
          adChats: [...state.adChats, ...action.result]
      }
    case CHAT_UNREAD_COUNT_REQUEST:
      return {
        ...state,
        isUnreadFetching: true,
        error: undefined
      };
    case CHAT_UNREAD_COUNT_SUCCESS:
      /*var arr = [...state.unreadCount];
      if(action.response && action.response.length > 0){
        action.response.map(m => {
          var flg = false;
          arr.map(a => {
            if(m && a && m.ClientId === a.ClientId){
              a.UnreadCount = m.UnreadCount;
              flg = true;
            }
            return null;
          });
          if(!flg){
            arr.push(m);
          }
          return null;          
        });
      }*/
      return {
          ...state,
          isUnreadFetching: false,
          unreadCount: action.response
      }
    case CHAT_UNREAD_COUNT_FAILURE:
      return {
        ...state,
        isUnreadFetching: false,
        error: action.error
      };
    case ADCHAT_UNREAD_COUNT_REQUEST:
      return {
        ...state,
        isAdUnreadFetching: true,
        error: undefined
      };
    case ADCHAT_UNREAD_COUNT_SUCCESS:
      return {
          ...state,
          isAdUnreadFetching: false,
          adUnreadCount: action.response
      }
    case ADCHAT_UNREAD_COUNT_FAILURE:
      return {
        ...state,
        isAdUnreadFetching: false,
        error: action.error
      };
    case CHAT_UPDATE_READON_REQUEST:
      return {
        ...state,
        isReadOnUpdating: true,
        error: undefined
      };
    case CHAT_UPDATE_READON_SUCCESS:
      return {
          ...state,
          isReadOnUpdating: action.response
      }
    case CHAT_UPDATE_READON_FAILURE:
      return {
        ...state,
        isReadOnUpdating: false,
        error: action.error
      };
    case CHAT_CHAT_WINDOW_SUCCESS:
      return {
          ...state,
          isChatWindowOpen: (action.isOpen || false),
          isChatWithLNO: (action.isLNO || false)
      }
    default:
      return state;
  }
};

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { GetSectionCustomizationOptions } from "components/util";
import CjMlslogo from "../img/nd/cjmls-white.png";
import MonmouthMlslogo from "../img/nd/monmouth-mls-white.png";
import OnekeyMlslogo from "../img/nd/one-key-white.png";
import NJMlslogo from "../img/nd/njmls-white.png";
import KDPartnerlogo from "../img/nd/partner-logo-kw.png";
import ExteamPartnerlogo from "../img/nd/partner-logo-exteam.png";
import Poweredlogo from "../img/nd/tlc-white-logo.png";
import ExternalGetPreApprovalModal from "./propertydetail/externalgetpreapprovalmodal";

const PropertyDetailPageCustomizationOptions = GetSectionCustomizationOptions(
  "PropertyDetailPageOptions"
);
const CommonCustomizationOptions =
  GetSectionCustomizationOptions("CommonOptions");

class FooterArk extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { CAModal: false };
  }

  openModal = () => {
    this.setState({ CAModal: true });
  };

  closeModal = () => {
    this.setState({ CAModal: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="footer-otherstyle">
          <Typography
            className={(classes && classes.footercolorbg) || ""}
            component={"span"}
            variant="body2"
          >
            <div className="footer-patternbg">
              <div className="footer-bg-color">
                <div className="custom-container">
                  <div className="footer-other">
                    {/* <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="footer-logo-box">
                                                    <div className="footer-logo">
                                                        <Link alt="Footer Logo Link" aria-label="Footer Logo Link" to={'/'}><img src={"https://true-cms.tlcengine.com/wp-content/uploads/2022/07/arkhomes-new-logo.png"} alt=""/></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="row">
                          <div className="col-md-4 col-sm-12 col-xs-12 footer-column new-footer-column">
                            <div className="footer-column-logo">
                              <div className="footer-logo-box">
                                <div className="footer-logo">
                                  <Link alt="ARK Homes Link" to={"/"} aria-label="ARK Homes Link">
                                    <img
                                      src={
                                        "https://true-cms.tlcengine.com/wp-content/uploads/2023/01/arkhomes-new-logobig.png"
                                      }
                                      alt="ARK Homes Logo"
                                    />
                                  </Link>
                                </div>
                                <div className="footer-logo-address">
                                  <div className="company-name-info">
                                    Ark Homes
                                  </div>
                                  <div className="company-address-info">
                                    <div className="company-box">
                                      400 Rella Blvd, Suite 300
                                    </div>
                                    <div className="company-box">
                                      Montebello, NY 10901
                                    </div>
                                    <div className="company-box">
                                      888-275-8501
                                    </div>
                                  </div>
                                  <div className="footer-social-icon">
                                    <ul className="social-icon-list">
                                      <li>
                                        <a
                                          href="https://www.facebook.com/Ark-Homes-110096501991876"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="fb-footer-icon"
                                          alt="Facebook Link"
                                          aria-label="Facebook Link"
                                        ></a>
                                      </li>
                                      <li>
                                        <a
                                          href="https://instagram.com/myarkhomes"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="ig-footer-icon"
                                          alt="Instagram Link"
                                          aria-label="Instagram Link"
                                        ></a>
                                      </li>
                                      <li>
                                        <a
                                          href="https://www.linkedin.com/company/ark-homes-dot-com/"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="in-footer-icon"
                                          alt="Linkedin Link"
                                          aria-label="Linkedin Link"
                                        ></a>
                                      </li>
                                      <li>
                                        <a
                                          href="https://www.tiktok.com/@myarkhomes?lang=en"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="tk-footer-icon"
                                          alt="Tiktok Link"
                                          aria-label="Tiktok Link"
                                        ></a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="footer-left-info">
                                <nav className="footer-left-nav">
                                  <ul>
                                    <li>
                                      <Link alt="Terms of Use Link" aria-label="Terms of Use Link" to={"/terms"}>Terms of Use</Link>
                                    </li>
                                    <li>
                                      <Link alt="Privacy Policy Link" aria-label="Privacy Policy Link" to={"/privacypolicy"}>
                                        Privacy Policy
                                      </Link>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 col-sm-12 col-xs-12 footer-column new-othermls-column">
                            {/* start */}
                            <div className="footer-brokerage-info">
                              {/* start */}
                              <div className="footer-brokerage-box">
                                <div className="brokerage-cnt-box brokerage-logo-llc">
                                  <img src={ExteamPartnerlogo} alt=""></img>
                                </div>
                                <div className="footer-brokerage-data">
                                  <div className="brokerage-cnt-box ft-17">
                                    Shulem Ekstein
                                  </div>
                                  <div className="brokerage-cnt-box ft-17">
                                    ExTeam LLC
                                  </div>
                                  <div className="brokerage-cnt-box">
                                    412 N. Main St., Unit 307{" "}
                                    <span className="address-border">
                                      Monroe, NY 10950
                                    </span>
                                  </div>
                                  <div className="brokerage-cnt-box">
                                    License: 10491209763
                                  </div>
                                  <div className="brokerage-cnt-box">
                                    Phone: (845) 388-1450
                                  </div>
                                  {/* <div className="brokerage-cnt-box">
                                    Office: (845) 388-1450
                                  </div> */}
                                </div>
                              </div>
                              {/* End */}
                              {/* start */}
                              <div className="footer-brokerage-box">
                                <div className="brokerage-cnt-box brokerage-logo-kw">
                                  <img src={KDPartnerlogo} alt=""></img>
                                </div>
                                <div className="footer-brokerage-data">
                                  <div className="brokerage-cnt-box ft-17">
                                    Krishna Malyala
                                  </div>
                                  <div className="brokerage-cnt-box ft-17">
                                    Keller Williams Elite
                                  </div>
                                  <div className="brokerage-cnt-box">
                                    481 Memorial Pkwy,{" "}
                                    <span className="address-border">
                                      Metuchen, NJ 08840
                                    </span>
                                  </div>
                                  <div className="brokerage-cnt-box">
                                    License: 0566404
                                  </div>
                                  <div className="brokerage-cnt-box">
                                    Phone: (732)-549-1998
                                  </div>
                                </div>
                              </div>
                              {/* End */}
                            </div>
                            {/* End */}
                            <div className="footer-mls-logos">
                              {/* start */}
                              {CommonCustomizationOptions &&
                              CommonCustomizationOptions.ShowNJMLSLogo &&
                              CommonCustomizationOptions.ShowNJMLSLogo ===
                                "true" ? (
                                <div className="footer-mls-logobox">
                                  <img src={NJMlslogo} alt=""></img>
                                </div>
                              ) : null}
                              {/* End */}
                              {/* start */}
                              <div className="footer-mls-logobox">
                                <img src={CjMlslogo} alt=""></img>
                              </div>
                              {/* End */}
                              {/* start */}
                              <div className="footer-mls-logobox">
                                <img src={MonmouthMlslogo} alt=""></img>
                              </div>
                              {/* End */}
                              {/* start */}
                              <div className="footer-mls-logobox">
                                <img src={OnekeyMlslogo} alt=""></img>
                              </div>
                              {/* End */}
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-12 col-xs-12  footer-column new-ft-menulink-column">
                            <div className="footer-nav-box">
                              <nav className="footer-nav">
                                <ul>
                                  <li>
                                    <Link alt="Search Homes Link" aria-label="Search Homes Link"  to={"/search"}>Search Homes</Link>
                                  </li>
                                  {this.state.CAModal && (
                                    <ExternalGetPreApprovalModal
                                      onHide={this.closeModal}
                                      show={this.state.CAModal}
                                      title={"Get PreApproved"}
                                      classes={classes}
                                    />
                                  )}
                                  {PropertyDetailPageCustomizationOptions &&
                                    PropertyDetailPageCustomizationOptions.ExternalGetPreApprovedButtonUrl &&
                                    PropertyDetailPageCustomizationOptions.ExternalGetPreApprovedButtonUrl !==
                                      "" && (
                                      <li>
                                        <a
                                          href="#"
                                          onClick={this.openModal}
                                          alt="Get PreApproved Link"
                                          aria-label="Get PreApproved Link"
                                        >
                                          Get PreApproved
                                        </a>
                                      </li>
                                    )}
                                  <li>
                                    <Link alt="Contact Us Link" aria-label="Contact Us Link" to={"/contactus"}>Contact Us</Link>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                            <div className="powerby-logo">
                              <div className="powerby-text-box">Powered by</div>
                              <div className="powerby-logo-box">
                                <a
                                  href="https://www.tlcengine.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  alt="Tlcengine Link"
                                  aria-label="Tlcengine Link"
                                >
                                  <img src={Poweredlogo} alt=""></img>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Typography>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  footercolorbg: {
    "& .footer-bg-color": {
      /* backgroundColor:alpha(theme.palette.primary.main, 0.85), */
      /* backgroundColor:"rgba(17,48,82)", */
      /* backgroundColor:theme.palette.primary.main, */
      color: "#ffffff",
      "& a": {
        color: "#ffffff",
        "&:hover": {
          color: "#ffffff",
        },
      },
    },
    "& .footer-nav-box": {
      "& .footer-nav": {
        "& a": {
          color: "#ffffff",
          "&:hover": {
            color: "#ffffff",
          },
        },
      },
    },
    "& .footer-patternbg": {
      background: theme.palette.primary.main,
    },
  },
  propertydescription: {
    "& .property-description": {
      color: theme.palette.primary.text,
      "& .property-address": {
        "& h1": {
          color: theme.palette.primary.textbold,
        },
      },
      "& .sub-address": {
        color: theme.palette.primary.text,
      },
      "& .property-price": {
        color: theme.palette.primary.textbold,
      },
      "& .property-short-description": {
        color: theme.palette.primary.dark,
      },
      "& .hilight-text": {
        color: theme.palette.primary.textbold,
      },
      "& .hilight-number": {
        color: theme.palette.secondary.main,
      },
      "& .utility-list": {
        "& .utility-icon": {
          "& .utility-common-icon": {
            color: theme.palette.primary.main,
          },
        },
        "& .utility-text": {
          "& a": {
            color: theme.palette.secondary.main,
            "&:hover": {
              color: theme.palette.primary.main,
            },
            "&:focus": {
              color: theme.palette.primary.main,
            },
          },
        },
      },
      "& .other-utility-list": {
        "& .utility-icon": {
          "& .utility-common-icon": {
            color: theme.palette.primary.main,
          },
        },
        "& .utility-text": {
          color: theme.palette.primary.main,
        },
        "& a": {
          color: theme.palette.secondary.main,
          "&:hover": {
            color: theme.palette.primary.main,
          },
          "&:focus": {
            color: theme.palette.primary.main,
          },
          "&:active": {
            color: theme.palette.primary.main,
          },
          "& .utility-text": {
            color: theme.palette.secondary.main,
            "&:hover": {
              color: theme.palette.primary.main,
            },
            "&:focus": {
              color: theme.palette.primary.main,
            },
            "&:active": {
              color: theme.palette.primary.main,
            },
          },
        },
      },
      "& .map-location": {
        borderColor: theme.palette.primary.border,
        borderWidth: 1,
        borderStyle: "solid",
        "& .location-icon": {
          "& .material-icons": {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    "& .prop-addedit-link": {
      color: theme.palette.secondary.main,
    },
  },
  modalpopuptlc: {
    "& .modal-small-title": {
      color: theme.palette.primary.textbold,
    },
    "& .modal-body": {
      "& .sub-label": {
        color: theme.palette.primary.text,
      },
      "& [type=text]": {
        color: theme.palette.default.dark,
      },
      "& textarea": {
        color: theme.palette.default.dark,
      },
      "& .appointment-option": {
        "& label": {
          color: theme.palette.primary.main,
        },
      },
    },
    "& .react-datepicker__input-container": {
      "& [type=text]": {
        color: theme.palette.default.dark,
      },
    },
    "& .react-datepicker__day--selected": {
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "& .react-datepicker__time-container": {
      "& .react-datepicker__time": {
        "& .react-datepicker__time-box": {
          "& .react-datepicker__time-list": {
            "& .react-datepicker__time-list-item--selected": {
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            },
          },
        },
      },
    },
    "& .approved-name": {
      color: theme.palette.primary.main,
    },
    "& .approved-phone": {
      color: theme.palette.primary.main,
    },
    "& .commute-calculat-edit-popup": {
      color: theme.palette.primary.main,
      "& .commute-result": {
        "& .result-box": {
          "&.success": {
            borderColor: theme.palette.success.main,
            borderWidth: 1,
            borderStyle: "solid",
          },
          "&.error": {
            borderColor: theme.palette.error.main,
            borderWidth: 1,
            borderStyle: "solid",
          },
        },
        "& .transport-icon": {
          background: theme.palette.secondary.main,
        },
      },
    },
  },
  typographytitle: {
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "inherit",
    color: theme.palette.primary.title,
    "&.MuiTypography-h1": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
    "&.MuiTypography-h2": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
  },
  typographybutton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.text,
    "&.MuiButton-contained": {
      boxShadow: "inherit",
      letterSpacing: "inherit",
      lineHeight: "normal",
      textTransform: "none",
    },
    "&.MuiButton-root": {
      minWidth: "inherit",
      fontWeight: "inherit",
    },
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
    "&:focus": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
  },
});

const mapStateToProps = (state, props) => ({
  purlPrefix: state.session.purlPrefix || { url: "" },
});

const mapDispatchToProps = (dispatch) => ({});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterArk))
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import DocumentTitle from 'react-document-title';
import { PageTitle, GetSectionCustomizationOptions } from 'components/util';
import Loader from 'components/loader';
import {withStyles} from "@material-ui/styles";
import Logo from 'img/nd/bflogo.png';
import Typography from '@material-ui/core/Typography';
import {Button} from "@material-ui/core";
import 'scss/_loginND.scss';

import * as sessionActions from 'actions/session';

const LoginPageCustomizationOptions = GetSectionCustomizationOptions("LoginPageOptions");

class SetPasswordPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        var params = queryString.parse(props.location.search);
        const token = (params && params.t) ? params.t.replace(/ /g,"+") : null;

        this.state = {
            title: 'Set Password',
            token: token,
            isCheckingToken: true,
            clientId: null,
            password: '',
            confirmPassword: '',
            isSettingPassword: false,
            errorMessage: '',
            successMessage: 'Processing, please wait...',            
            isPasswordSet: false,            
        };
    }

    componentDidMount() {        
        if(!this.state.token || this.state.token === ""){
            if(this.props.history) this.props.history.push("/");
        }        
        else {
            this.checkToken();
        }        
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isAnonymous !== nextProps.isAnonymous && this.props.isAnonymous === undefined && nextProps.isAnonymous === false) {
            this.props.sessionActions.logout(true);
            setTimeout(() => {
                window.location.reload();
            },1000);
        }
    }

    checkToken = () => {
        var self = this;
        var token = this.state.token;
        if(token && token !== ""){        
            var url = window.sessionStorage.getItem("RootAPI") + "clients/checkallowsetpassword";
            var requestBody = {
                token: token
            }            
            self.setState({isCheckingToken: true});
            fetch(url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "POST",
                    body: JSON.stringify(requestBody)
                })
                .then(response =>  response.json())
                .then(json => {
                    if (json) {                        
                        if(self.refs.setPasswordRef){
                            self.setState({
                                isCheckingToken: false, clientId: json, successMessage: ""
                            });
                        }
                    }
                    else {
                        self.setState({
                            isCheckingToken: false, clientId: null, 
                            successMessage: "You have already set your password. Please sign in using the same. If you don't remember your password, try forgot password."
                        });
                    }
                })
                .catch(function (data) {
                    console.log(data);
                    self.setState({isCheckingToken: false });
                    if(self.props.history) self.props.history.push("/");
                }); 
        }
    }

    setPassword = () => {

        var self = this;

        if(self.state.clientId !== null && self.state.password && self.state.password !== ""){
         
            var url = window.sessionStorage.getItem("RootAPI") + "clients/" + self.state.clientId + "/setpassword";
            var requestBody = {
                password: self.state.password
            }
            
            //if(self.refs.btnSetPassword) self.refs.btnSetPassword.setAttribute("disabled", "disabled");
            self.setState({isSettingPassword: true});
            fetch(url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "POST",
                    body: JSON.stringify(requestBody)
                })
                .then(response =>  response.json())
                .then(json => {
                    if (json) {
                        //if(self.refs.btnSetPassword) self.refs.btnSetPassword.removeAttribute("disabled");
                        if(self.refs.setPasswordRef){
                            self.setState({
                                isCheckingToken: false, isSettingPassword: false, isPasswordSet: true,
                                successMessage: "Your Password has been set successfully. Please sign in using the same."
                            });
                        }
                    }
                    else {
                        //if(self.refs.btnSetPassword) self.refs.btnSetPassword.removeAttribute("disabled");
                        self.setState({
                            isCheckingToken: false, isSettingPassword: false,
                            errorMessage: "There was an error trying to set your password. Please try again later."
                        });
                    }
                })
                .catch(function (data) {
                    console.log(data);
                    //if(self.refs.btnSignup) self.refs.btnSignup.removeAttribute("disabled");
                    self.setState({isCheckingToken: false, isSettingPassword: false, errorMessage: "There was an error trying to set your password. Please try again later." });
                }); 
        }
    }

    handleInputChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    validatePassword = (newPassword) => {
        return new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$').test(newPassword);
    }
    
    validateCapsPassword = (newPassword) => {
        return new RegExp('^(?=.*[A-Z])').test(newPassword);
    }
    
    validateNumericPassword = (newPassword) => {
        return new RegExp('^(?=.*\\d)').test(newPassword);
    }
    
    validateLowerPassword = (newPassword) => {
        return new RegExp('^(?=.*[a-z])').test(newPassword);
    }

    handleSubmit = (event) => {

        var errorMessage = '';
        if (!this.state.password || this.state.password === ''){
            errorMessage = "Please enter new password. ";
        }
        else if(this.state.password.length < 8){
            errorMessage = "New password should be at least 8 characters. ";
        }
        else if(!this.validateLowerPassword(this.state.password)){
            errorMessage = "New password should contain at least one lowercase letter. ";
        }
        else if(!this.validateCapsPassword(this.state.password)){
            errorMessage = "New password should contain at least one UPPERCASE letter. ";
        }
        else if(!this.validateNumericPassword(this.state.password)){
            errorMessage = "New password should contain at least one numeric digit. ";
        }
        else if (!this.state.confirmPassword || this.state.confirmPassword === ''){
            errorMessage = "Please confirm new password. ";
        }
        else if (this.state.password !== this.state.confirmPassword){
            errorMessage = "New password and confirm password should match. ";
        }

        if (errorMessage.length === 0) {
            this.setState({isSettingPassword: true}, function() {
                this.setPassword();
            });            
        }
        else {
            if(this.refs.setPasswordRef){
                this.setState({errorMessage: errorMessage});
            }
        }

        event.preventDefault();
        return false;
    }

    render() {

        const { title, password, confirmPassword, isCheckingToken, isPasswordSet, isSettingPassword, errorMessage, successMessage, clientId } = this.state;
        const { classes, purlPrefix } = this.props;

        var siteLogo = Logo;
        if (process.env.REACT_APP_WebsiteLogo !== undefined && process.env.REACT_APP_WebsiteLogo !== null &&
            process.env.REACT_APP_WebsiteLogo !== ""){
            siteLogo = process.env.REACT_APP_WebsiteLogo;
        }
        
        var logoLink = "/";
        if(purlPrefix && purlPrefix.url && purlPrefix.url !== ""){
            logoLink = purlPrefix.url;
        }

        return (
            <DocumentTitle title={ PageTitle.replace('{title}', title) } ref='setPasswordRef'>
                <div>
                    <div className="container-fluid pd-lt-rt-0">
                        <div className="container-login">
                            <div className="login-lt-cnt col-md-6 col-sm-12">
                                <div className="login-form ft-none col-sm-12">
                                    <div className="form-mid mg-auto">
                                        <div className="login-logo"><Link alt="Logo Link" aria-label="Logo Link" to={logoLink} rel=""><img src={siteLogo} alt="logo"/></Link></div>
                                        <Typography className={classes.typographytitle} variant="h1">
                                            <div className="login-header">
                                                Set Password
                                            </div>
                                        </Typography>
                                        <Typography className={(successMessage && successMessage !== "") ? classes.typographysuccess : classes.typographyerror} component={'div'} variant="body2" gutterBottom><span>{(successMessage && successMessage !== "") ? successMessage : ((errorMessage && errorMessage !== "") ? errorMessage : "")}</span></Typography>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xl-12">
                                                <Typography className={classes.loginform} component={'span'} variant="body2">
                                                    <form>
                                                        {clientId !== null && !isPasswordSet &&
                                                            <div className="login-signup">
                                                                <div className="btn-group w-100">
                                                                    <input className="login-input" name="password" type="text" placeholder="New Password" value={password} onChange={this.handleInputChange} />
                                                                </div>
                                                                <div className="btn-group w-100">
                                                                    <input className="login-input" name="confirmPassword" type="text" placeholder="Confirm Password" value={confirmPassword} onChange={this.handleInputChange} />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 col-sm-12 col-xl-12 mg-bt-20">
                                                                        <div className="row">
                                                                            <div className="col-md-4 col-sm-7 col-xs-6">
                                                                            {isSettingPassword ?
                                                                                <Button variant="contained" className={classes.typographybutton + " continue-button theme-btn"}><Loader inline="true" width="17" height="17" /></Button> :
                                                                                <Button variant="contained" className={classes.typographybutton + " continue-button theme-btn"} onClick={this.handleSubmit}>Continue</Button>
                                                                            }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 col-sm-12 col-xl-12">
                                                                        <Typography className={classes.nolink} component={'span'} variant="body2"><span>Already have an account?</span></Typography>
                                                                        <Typography className={classes.typographylink + " pd-lt-5"} component={'span'} variant="body2">
                                                                            <Link alt="Sign In Link" aria-label="Sign In Link" to={`${purlPrefix.url}/login`}>Sign In</Link>
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {(!isCheckingToken && !isPasswordSet && successMessage && successMessage !== "" && clientId === null) &&
                                                            <div className="login-signup">
                                                                <div className="row">
                                                                    <div className="col-md-12 col-sm-12 col-xl-12 mg-bt-20">
                                                                        <div className="row">
                                                                            <div className="col-md-4 col-sm-7 col-xs-6 w-40 pd-tp-10">
                                                                                <Typography className={classes.typographylink} component={'span'} variant="body2">
                                                                                    <Link alt="Sign In Link" aria-label="Sign In Link" to={`${purlPrefix.url}/login`}>Sign In</Link> 
                                                                                </Typography>
                                                                            </div>
                                                                            <div className="col-md-8 col-sm-5 col-xs-6 w-60 text-right pd-tp-10">
                                                                                <Typography  className={classes.typographylink} component={'span'} variant="body2">
                                                                                    <Link alt="Forgot your password Link" aria-label="Forgot your password Link" to={`${purlPrefix.url}/forgotpassword`}>Forgot your password?</Link> 
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {(!isCheckingToken && isPasswordSet && successMessage && successMessage !== "") &&
                                                            <div className="login-signup">
                                                                <div className="row">
                                                                    <div className="col-md-12 col-sm-12 col-xl-12">
                                                                        <Typography className={classes.nolink} component={'span'} variant="body2"><span>Already set password?</span></Typography>
                                                                        <Typography className={classes.typographylink + " pd-lt-5"} component={'span'} variant="body2">
                                                                            <Link alt="Sign In Link" aria-label="Sign In Link" to={`${purlPrefix.url}/login`}>Sign In</Link>
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </form>
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="login-rt-cnt col-md-6 col-sm-12" style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.BackgroundImage && LoginPageCustomizationOptions.BackgroundImage !== "") ? {backgroundImage: "url(" + LoginPageCustomizationOptions.BackgroundImage + ")"} : {}}>
                                <div className="punchline-text">
                                    A new home search experience - <span>starting with what really matters to you.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

const styles = theme => ({
    loginform:{
        '& [type=text]':{
            color: theme.palette.default.dark,
        },
        '& [type=password]':{
            color: theme.palette.default.dark,
        },
    },
    typographytitle: {
        fontWeight:'normal',
        lineHeight:'normal',
        letterSpacing:'inherit',
        color:theme.palette.primary.title,
        '&.MuiTypography-h1': {
            fontWeight:'normal',
            lineHeight:'normal',
            letterSpacing:'inherit',
        } 
    },
    typographyerror: {
        color:theme.palette.error.main,
        marginBottom: 24,
        minHeight: 16,
    },
    typographysuccess: {
        color:theme.palette.success.main,
        marginBottom: 24,
        minHeight: 16,
    },
    typographybutton:{
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.text,
        '&.MuiButton-contained': {
          boxShadow:'inherit',
          letterSpacing:'inherit',
          lineHeight:'normal',
          textTransform:'none',
        }, 
        '&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.text,
        },
        '&:focus': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.text,
        },
    },
    typographylink: {
        '& a': {
            color: theme.palette.secondary.main,
            '&:hover': {
                color: theme.palette.primary.main,
            },
            '&:focus': {
                color: theme.palette.primary.main,
            },
        },
    },
    nolink: {
        '& span': {
            color: theme.palette.primary.main,
        }
    },
});

const mapStateToProps = (state, props) => ({
    isAnonymous: state.session.isAnonymous,
    purlPrefix: state.session.purlPrefix || { url: ''}
});

const mapDispatchToProps = dispatch => ({
    sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SetPasswordPage));
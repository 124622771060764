import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import DocumentTitle from 'react-document-title';
import { PageTitle, GetSectionCustomizationOptions } from 'components/util';
import Loader from 'components/loader';
import {withStyles} from "@material-ui/styles";
import Logo from 'img/nd/bflogo.png';
import Typography from '@material-ui/core/Typography';
import {Button} from "@material-ui/core";
import 'scss/_loginND.scss';


const LoginPageCustomizationOptions = GetSectionCustomizationOptions("LoginPageOptions");

class ResetPasswordPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        var params = queryString.parse(props.location.search);
        const token = (params && params.token) ? params.token : null;

        this.state = {
            password: '',
            confirmPassword: '',
            errorMessage: '',
            successMessage: '',
            fetchingDetails: false,
            resettingPassword: false,
            passwordReset: false,
            title: 'Reset Password',
            token: token
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    componentDidMount() {
    }

    resetPassword() {

        var self = this;
         
        var url = window.sessionStorage.getItem("RootAPI") + "accesstokens/resetpassword";
        //https://trueapi-uat.tlcengine.com/v3/api/true/accesstokens/setpassword
        var requestBody = {
            token: self.state.token,
            password: self.state.password
        }
        //If there is default agent then pass default agent id to use default agent specific email template
        if (process.env.REACT_APP_DefaultAgentId !== undefined && 
            process.env.REACT_APP_DefaultAgentId !== null && 
            process.env.REACT_APP_DefaultAgentId !== "")
        {
            requestBody.BrokerAgentId = process.env.REACT_APP_DefaultAgentId;
        }
        //---

        //if(self.refs.btnResetPassword) self.refs.btnResetPassword.setAttribute("disabled", "disabled");
        self.setState({creatingAccount: true});
        fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(requestBody)
            })
            .then(response =>  response.json())
            .then(json => {
                if (json) {
                    //if(self.refs.btnResetPassword) self.refs.btnResetPassword.removeAttribute("disabled");
                    var accountCreated = false;
                    var successMessage = "", errorMessage = "";
                    if(json.Message){
                        if(self.props.showNotification)
                        {
                            self.props.showNotification(
                                "success",
                                "",
                                "Your Password has been reset successfully.",
                                5000);
                        }
                        self.props.history.push(`/`);
                    }
                    else if(json.length > 0 && json[0].ErrorMessage){
                        errorMessage = json[0].ErrorMessage;
                    }

                    if(self.refs.resetPasswordRef)
                    {
                        self.setState({
                            creatingAccount: false, accountCreated: accountCreated,
                            errorMessage: errorMessage, successMessage: successMessage, resettingPassword: false
                        });
                    }
                }
                else {
                    //if(self.refs.btnResetPassword) self.refs.btnResetPassword.removeAttribute("disabled");
                    self.setState({
                        creatingAccount: false,
                        errorMessage: "There was an error trying to reset your password. Please contact support@truebytlc.com"
                    });
                }
            })
            .catch(function (data) {
                console.log(data);
                //if(self.refs.btnSignup) self.refs.btnSignup.removeAttribute("disabled");
                self.setState({creatingAccount: false, errorMessage: "There was an error trying to reset your password. Please try again later."});
            }); 
    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    validatePassword = (newPassword) => {
        return new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$').test(newPassword);
    }
    
    validateCapsPassword = (newPassword) => {
        return new RegExp('^(?=.*[A-Z])').test(newPassword);
    }
    
    validateNumericPassword = (newPassword) => {
        return new RegExp('^(?=.*\\d)').test(newPassword);
    }
    
    validateLowerPassword(newPassword) 
    {
        return new RegExp('^(?=.*[a-z])').test(newPassword);
    }

    handleSubmit(event) {

        var errorMessage = '';
        if (this.state.password === null || this.state.password === ''){
            errorMessage = "Please enter new password. ";
        }
        else if(this.state.password.length < 8){
            errorMessage = "New password should be at least 8 characters. ";
        }
        else if(!this.validateLowerPassword(this.state.password)){
            errorMessage = "New password should contain at least one lowercase letter. ";
        }
        else if(!this.validateCapsPassword(this.state.password)){
            errorMessage = "New password should contain at least one UPPERCASE letter. ";
        }
        else if(!this.validateNumericPassword(this.state.password)){
            errorMessage = "New password should contain at least one numeric digit. ";
        }
        else if (this.state.confirmPassword === null || this.state.confirmPassword === ''){
            errorMessage = "Please confirm new password. ";
        }
        else if (this.state.password !== this.state.confirmPassword){
            errorMessage = "New password and confirm password should match. ";
        }

        if (errorMessage.length === 0) {
            this.setState({resettingPassword: true}, function() {
                this.resetPassword();
            });            
        }
        else
        {
            if(this.refs.resetPasswordRef)
            {
                this.setState({errorMessage: errorMessage});
            }
        }

        event.preventDefault();
        return false;
    }

    render() {

        const { title, password, confirmPassword, passwordReset, resettingPassword, errorMessage, successMessage } = this.state;
        const { classes, purlPrefix } = this.props;

        var siteLogo = Logo;
        if (process.env.REACT_APP_WebsiteLogo !== undefined && process.env.REACT_APP_WebsiteLogo !== null &&
            process.env.REACT_APP_WebsiteLogo !== ""){
            siteLogo = process.env.REACT_APP_WebsiteLogo;
        }
        
        var logoLink = "/";
        if(purlPrefix && purlPrefix.url && purlPrefix.url !== ""){
            logoLink = purlPrefix.url;
        }

        return (
            <DocumentTitle title={ PageTitle.replace('{title}', title) } ref='resetPasswordRef'>
                <div>
                    <div className="container-fluid pd-lt-rt-0">
                        <div className="container-login">
                            <div className="login-lt-cnt col-md-6 col-sm-12">
                                <div className="login-form ft-none col-sm-12">
                                    <div className="form-mid mg-auto">
                                        <div className="login-logo"><Link alt={(process.env.REACT_APP_WebsiteTitle !== undefined && process.env.REACT_APP_WebsiteTitle !== null && process.env.REACT_APP_WebsiteTitle !== "") ? process.env.REACT_APP_WebsiteTitle : "Home"} aria-label={(process.env.REACT_APP_WebsiteTitle !== undefined && process.env.REACT_APP_WebsiteTitle !== null && process.env.REACT_APP_WebsiteTitle !== "") ? process.env.REACT_APP_WebsiteTitle : "Home"} to={logoLink} rel=""><img src={siteLogo} alt={(process.env.REACT_APP_WebsiteTitle !== undefined && process.env.REACT_APP_WebsiteTitle !== null && process.env.REACT_APP_WebsiteTitle !== "") ? process.env.REACT_APP_WebsiteTitle : "Home"}/></Link></div>
                                        <Typography className={classes.typographytitle} variant="h1">
                                            <div className="login-header">
                                                Let's Start by Entering <span>new Password.</span>
                                            </div>
                                        </Typography>
                                        <Typography className={(successMessage && successMessage !== "") ? classes.typographysuccess : classes.typographyerror} component={'div'} variant="body2" gutterBottom><span>{(successMessage && successMessage !== "") ? successMessage : ((errorMessage && errorMessage !== "") ? errorMessage : "")}</span></Typography>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xl-12">
                                                <Typography className={classes.loginform} component={'span'} variant="body2">
                                                    <form>
                                                        {!passwordReset &&
                                                            <div className="login-signup">
                                                                <div className="btn-group w-100">
                                                                    <input className="login-input" name="password" type="text" placeholder="New Password" value={password} onChange={this.handleInputChange} />
                                                                </div>
                                                                <div className="btn-group w-100">
                                                                    <input className="login-input" name="confirmPassword" type="text" placeholder="Confirm Password" value={confirmPassword} onChange={this.handleInputChange} />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 col-sm-12 col-xl-12 mg-bt-20">
                                                                        <div className="row">
                                                                            <div className="col-md-4 col-sm-7 col-xs-6">
                                                                            {resettingPassword ?
                                                                                <Button variant="contained" className={classes.typographybutton + " continue-button theme-btn"}><Loader inline="true" width="17" height="17" /></Button> :
                                                                                <Button variant="contained" className={classes.typographybutton + " continue-button theme-btn"} onClick={this.handleSubmit}>Continue</Button>
                                                                            }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 col-sm-12 col-xl-12">
                                                                        <Typography className={classes.nolink} component={'span'} variant="body2"><span>Already have an account?</span></Typography>
                                                                        <Typography className={classes.typographylink + " pd-lt-5"} component={'span'} variant="body2">
                                                                            <Link alt="Sign In Link" aria-label="Sign In Link" to={`${purlPrefix.url}/login`}>Sign In</Link>
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {passwordReset &&
                                                            <div className="login-signup">
                                                                <div className="row">
                                                                    <div className="col-md-12 col-sm-12 col-xl-12">
                                                                        <Typography className={classes.nolink} component={'span'} variant="body2"><span>Ready to try new password?</span></Typography>
                                                                        <Typography className={classes.typographylink + " pd-lt-5"} component={'span'} variant="body2">
                                                                            <Link alt="Sign In Link" aria-label="Sign In Link" to={`${purlPrefix.url}/login`}>Sign In</Link>
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </form>
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="login-rt-cnt col-md-6 col-sm-12" style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.BackgroundImage && LoginPageCustomizationOptions.BackgroundImage !== "") ? {backgroundImage: "url(" + LoginPageCustomizationOptions.BackgroundImage + ")"} : {}}>
                                <div className="punchline-text">
                                    A new home search experience - <span>starting with what really matters to you.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

const styles = theme => ({
    loginform:{
        '& [type=text]':{
            color: theme.palette.default.dark,
        },
        '& [type=password]':{
            color: theme.palette.default.dark,
        },
    },
    typographytitle: {
        fontWeight:'normal',
        lineHeight:'normal',
        letterSpacing:'inherit',
        color:theme.palette.primary.title,
        '&.MuiTypography-h1': {
            fontWeight:'normal',
            lineHeight:'normal',
            letterSpacing:'inherit',
        } 
    },
    typographyerror: {
        color:theme.palette.error.main,
        marginBottom: 24,
        minHeight: 16,
    },
    typographysuccess: {
        color:theme.palette.success.main,
        marginBottom: 24,
        minHeight: 16,
    },
    typographybutton:{
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.text,
        '&.MuiButton-contained': {
          boxShadow:'inherit',
          letterSpacing:'inherit',
          lineHeight:'normal',
          textTransform:'none',
        }, 
        '&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.text,
        },
        '&:focus': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.text,
        },
    },
    typographylink: {
        '& a': {
            color: theme.palette.secondary.main,
            '&:hover': {
                color: theme.palette.primary.main,
            },
            '&:focus': {
                color: theme.palette.primary.main,
            },
        },
    },
    nolink: {
        '& span': {
            color: theme.palette.primary.main,
        }
    },
});

const mapStateToProps = (state, props) => ({
    purlPrefix: state.session.purlPrefix || { url: ''}
});

const mapDispatchToProps = dispatch => ({
    
});

export default  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage));
import React, { useCallback, useRef, useState } from 'react';
import { LoginSocialAmazon } from 'reactjs-social-login';

const REDIRECT_URI = `${window.location.origin}/socialaccounts/amazon`;


const SocialLoginAmazon = (props) => {
    
    const [provider, setProvider] = useState('');
    const amazonRef = useRef();

    const onLoginStart = useCallback(() => {
        //console.log('onLoginStart');

    }, []);

    /* const onLogoutFailure = useCallback(() => {
        console.log('onLogoutFailure');

    }, []); */

    const onLogoutSuccess = useCallback(() => {
        setProvider('');
        //console.log('onLogoutSuccess');

    }, []);

    const onLogout = useCallback(() => {
        switch (provider) {
          case 'amazon':
            if(amazonRef && amazonRef.current) amazonRef.current.onLogout();
            break
          default:
            break
        }
    }, [provider]);

    

    return (
        <span className="social-btn sm-btn btn-amz">
            <LoginSocialAmazon
                ref={amazonRef}
                client_id={props.AmazonCID || 'amzn1.application-oa2-client.314b9a71ab88450aa46bcdbb12cfbf10'}
                redirect_uri={REDIRECT_URI}
                onLoginStart={onLoginStart}
                onLogoutSuccess={onLogoutSuccess}
                onResolve = {({ provider, data }) => {
                    setProvider(provider);
                    
                    var returnObj = {Error: "Invalid account or credentials"};
                    if(data && data.user_id && data.user_id !== "" && data.email && data.email !== ""){
                        returnObj = {};
                        returnObj.SToken = data.user_id;
                        if(data.user_id.indexOf(".") >= 0){
                            var arr = data.user_id.split(".");
                            if(arr && arr.length > 0){
                                returnObj.SToken = arr[arr.length - 1];
                            }
                        }
                        returnObj.Email = returnObj.Firstname = returnObj.LastName = data.email;                        
                        if(data.name && data.name !== ""){
                            var strNm = data.name.split(" ");
                            if(strNm && strNm.length > 0){
                                returnObj.Firstname = returnObj.LastName = strNm[0];
                                if(strNm.length > 1){
                                    returnObj.LastName = strNm[1];            
                                }
                            }
                        }                                                
                    }
                    onLogout();
                    
                    if(props && props.callBackFun){
                        props.callBackFun(returnObj);
                    }
                }}
                onReject = {(err) => {
                    console.log('Error in signInWithAmazon');
                    console.log(err);
                    if(props && props.callBackFun){
                        props.callBackFun({Error: "Invalid account or credentials"});
                    }
                }}
                >
                <span>
                    <span><i className="fa fa-amazon"></i></span>
                </span>
            </LoginSocialAmazon>
        </span>
    );    
    
}

export default SocialLoginAmazon;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { PageTitle, GetSectionCustomizationOptions } from 'components/util';
import Loader from 'components/loader';
import {withStyles} from "@material-ui/styles";
import Logo from 'img/nd/bflogo.png';
import Typography from '@material-ui/core/Typography';
import {Button} from "@material-ui/core";
import 'scss/_loginND.scss';


const LoginPageCustomizationOptions = GetSectionCustomizationOptions("LoginPageOptions");

class ForgotPasswordPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            errorMessage: '',
            authType: 'Agent',
            fetchingResult: false,
            title: 'Forgot Password'
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.forgotPasswordCall = this.forgotPasswordCall.bind(this);
        this.onChangeAuthType = this.onChangeAuthType.bind(this);
    }

    componentDidMount() {
    }

    forgotPasswordCall() {
        var self = this;
        var url = window.sessionStorage.getItem("RootAPI") + "accesstokens/";

        var requestType = {};
        url += "forgotpassword";

        requestType.email = this.state.username;

        //If there is default agent then pass default agent id to use default agent specific email template
        if (process.env.REACT_APP_DefaultAgentId !== undefined && 
            process.env.REACT_APP_DefaultAgentId !== null && 
            process.env.REACT_APP_DefaultAgentId !== "")
        {
            requestType.BrokerAgentId = process.env.REACT_APP_DefaultAgentId;
        }
        //---

        //self.refs.btnSubmit.setAttribute("disabled", "disabled");
        self.setState({ fetchingResult: true });
        fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(requestType)
            })
            .then(response =>  (response.ok) ? response : response.json())
            .then(json => {    
                if (json.ok) {            
                    if(self.props.showNotification)
                    {
                        self.props.showNotification(
                            "success",
                            "",
                            "A link to update your password has been emailed. Please check your email.",
                            5000);
                    }
                    self.props.history.push(`/`);
                }
                else
                {
                    var emsg = (json && json[0] && json[0].ErrorMessage) ? json[0].ErrorMessage : "The username you entered was not found.";
                    
                    // Override API error message for user not found
                    if (json && json[0] && json[0].ErrorCode && json[0].ErrorCode === "412") 
                    {
                        emsg = "The username you entered was not found."
                    }

                    //self.refs.btnSubmit.removeAttribute("disabled");
                    this.setState({fetchingResult: false, errorMessage: emsg});
                }
            })
            .catch(function (data) {
                console.log(data);
                //self.refs.btnSubmit.removeAttribute("disabled");
                var emsg = (data && data[0] && data[0].ErrorMessage) ? data[0].ErrorMessage : "The username you entered was not found.";
                self.setState({fetchingResult: false, errorMessage: emsg});
            });
    }
    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    handleSubmit(event) {

        if(this.state.username === null || this.state.username === '')
        {
            this.setState({errorMessage: "Username is required."});
        }
        else
        {
            this.setState({errorMessage: ""});
            this.forgotPasswordCall();
        }

        event.preventDefault();
        return false;
    }
    onChangeAuthType(authType) {
        this.setState({ authType: authType });
    }

    render() {

        const { title, username, fetchingResult, errorMessage } = this.state;
        const { classes, purlPrefix } = this.props;

        var siteLogo = Logo;
        if (process.env.REACT_APP_WebsiteLogo !== undefined && process.env.REACT_APP_WebsiteLogo !== null &&
            process.env.REACT_APP_WebsiteLogo !== ""){
            siteLogo = process.env.REACT_APP_WebsiteLogo;
        }
        
        var logoLink = "/";
        if(purlPrefix && purlPrefix.url && purlPrefix.url !== ""){
            logoLink = purlPrefix.url;
        }

        return (
            <DocumentTitle title={ PageTitle.replace('{title}', title) }>
                <div>
                    <div className="container-fluid pd-lt-rt-0">
                        <div className="container-login">
                            <div className="login-lt-cnt col-md-6 col-sm-12">
                                <div className="login-form ft-none col-sm-12">
                                    <div className="form-mid mg-auto">
                                        <div className="login-logo"><Link alt={(process.env.REACT_APP_WebsiteTitle !== undefined && process.env.REACT_APP_WebsiteTitle !== null && process.env.REACT_APP_WebsiteTitle !== "") ? process.env.REACT_APP_WebsiteTitle : "Home"} aria-label={(process.env.REACT_APP_WebsiteTitle !== undefined && process.env.REACT_APP_WebsiteTitle !== null && process.env.REACT_APP_WebsiteTitle !== "") ? process.env.REACT_APP_WebsiteTitle : "Home"} to={logoLink} rel=""><img src={siteLogo} alt={(process.env.REACT_APP_WebsiteTitle !== undefined && process.env.REACT_APP_WebsiteTitle !== null && process.env.REACT_APP_WebsiteTitle !== "") ? process.env.REACT_APP_WebsiteTitle : "Home"}/></Link></div>
                                        <Typography className={classes.typographytitle} variant="h1">
                                            <div className="login-header">
                                                Let's Start by Entering <span>your Username.</span>
                                            </div>
                                        </Typography>
                                        <Typography className={classes.typographyerror} component={'div'} variant="body2" gutterBottom><span>{(errorMessage && errorMessage !== "") ? errorMessage : ""}</span></Typography>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xl-12">
                                                <Typography className={classes.loginform} component={'span'} variant="body2">
                                                    <form>
                                                        <div className="login-signup">
                                                            <div className="btn-group w-100">
                                                                <input className="login-input" name="username" type="text" placeholder="Username" value={username} onChange={this.handleInputChange} />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 col-sm-12 col-xl-12 mg-bt-20">
                                                                    <div className="row">
                                                                        <div className="col-md-4 col-sm-7 col-xs-6">
                                                                        {fetchingResult ?
                                                                            <Button variant="contained" className={classes.typographybutton + " continue-button theme-btn"}><Loader inline="true" width="17" height="17" /></Button> :
                                                                            <Button variant="contained" className={classes.typographybutton + " continue-button theme-btn"} onClick={this.handleSubmit}>Continue</Button>
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 col-sm-12 col-xl-12">
                                                                    <Typography className={classes.nolink} component={'span'} variant="body2"><span>Already have an account?</span></Typography>
                                                                    <Typography className={classes.typographylink + " pd-lt-5"} component={'span'} variant="body2">
                                                                        <Link alt="Sign In Link" aria-label="Sign In Link" to={`${purlPrefix.url}/login`}>Sign In</Link>
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="login-rt-cnt col-md-6 col-sm-12" style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.BackgroundImage && LoginPageCustomizationOptions.BackgroundImage !== "") ? {backgroundImage: "url(" + LoginPageCustomizationOptions.BackgroundImage + ")"} : {}}>
                                <div className="punchline-text">
                                    A new home search experience - <span>starting with what really matters to you.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

const styles = theme => ({
    loginform:{
        '& [type=text]':{
            color: theme.palette.default.dark,
        },
        '& [type=password]':{
            color: theme.palette.default.dark,
        },
    },
    typographytitle: {
        fontWeight:'normal',
        lineHeight:'normal',
        letterSpacing:'inherit',
        color:theme.palette.primary.title,
        '&.MuiTypography-h1': {
            fontWeight:'normal',
            lineHeight:'normal',
            letterSpacing:'inherit',
        } 
    },
    typographyerror: {
        color:theme.palette.error.main,
        marginBottom: 24,
        minHeight: 16,
    },
    typographybutton:{
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.text,
        '&.MuiButton-contained': {
          boxShadow:'inherit',
          letterSpacing:'inherit',
          lineHeight:'normal',
          textTransform:'none',
        }, 
        '&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.text,
        },
        '&:focus': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.text,
        },
    },
    typographylink: {
        '& a': {
            color: theme.palette.secondary.main,
            '&:hover': {
                color: theme.palette.primary.main,
            },
            '&:focus': {
                color: theme.palette.primary.main,
            },
        },
    },
    nolink: {
        '& span': {
            color: theme.palette.primary.main,
        }
    },
});

const mapStateToProps = (state, props) => ({
    purlPrefix: state.session.purlPrefix || { url: ''}
});

const mapDispatchToProps = dispatch => ({
    
});

export default  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage));
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import qs from "query-string";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";
import {
  PageTitle,
  GetSectionCustomizationOptions,
  isProfileComplete,
} from "components/util";
import base64 from "base-64";
import utf8 from "utf8";
import Loader from "components/loader";
import { withStyles } from "@material-ui/styles";
import Logo from "img/nd/bflogo.png";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import SocialLogins from "components/sociallogins";
import "scss/_loginND.scss";

import * as sessionActions from "actions/session";
import * as profileActions from "actions/profile";
import * as settingActions from "actions/settings";
import * as listingActions from "actions/listings";
import * as agentActions from "actions/agents";

const CommonCustomizationOptions =
  GetSectionCustomizationOptions("CommonOptions");
const LoginPageCustomizationOptions =
  GetSectionCustomizationOptions("LoginPageOptions");
const IsLNOEnabled =
  CommonCustomizationOptions && CommonCustomizationOptions.IsLNOEnabled
    ? true
    : false;

class LoginPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  };

  state = {
    username: "",
    password: "",
    rememberMe: false,
    authType: "Agent",
    title: "Login",
    redirectToExSSO: true,
  };

  componentDidMount() {
    if (
      CommonCustomizationOptions &&
      CommonCustomizationOptions.IsExternalSSOEnabled &&
      CommonCustomizationOptions.IsExternalSSOEnabled === "true" &&
      this.state.redirectToExSSO
    ) {
      window.sessionStorage.setItem("ComingForExSSO", true);
      this.props.history.push(
        `${this.props.purlPrefix.url}/exsso?audiencetype=client&action=login`
      );
    }
    window.scrollTo(0, 0);

    if (this.props.sessionActions) {
      this.props.sessionActions.setAuthError("");
    }

    if (
      CommonCustomizationOptions &&
      CommonCustomizationOptions.ShowOneAllSocialLogins
    ) {
      const obj = qs.parse(this.props.location.query);
      var socialScript = obj.audienceId ? "social-link.js" : "social-login.js";
      // if weclome client email. wire up social to link and verify
      if (obj.audienceId) {
        window.sessionStorage.setItem(
          "SocialLinkAndVerify",
          JSON.stringify({
            Id: obj.audienceId,
            audienceType: obj.audienceType,
            mlsKey: obj.mlsKey,
          })
        );
      } else if (obj.socialSuccess === "false") {
        //social login failed
        this.props.sessionActions.setAuthError(
          "Error with social login. For the first time, you must use the link from your welcome email."
        );
      } else if (obj.socialLinkAndVerify === "false") {
        //social link failed
        this.props.sessionActions.setAuthError(
          "Error with social login. This social account already exists."
        );
      }
      const script = document.createElement("script");
      const scriptsPath = window.sessionStorage
        .getItem("BaseAPI")
        .replace("/api/", "/scripts/");
      script.src = scriptsPath + socialScript;
      script.async = true;

      document.body.appendChild(script);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.session.isAuthenticated) {
      if (
        (this.props.profile === undefined || this.props.profile === null) &&
        (nextProps.profile === undefined || nextProps.profile === null) &&
        !this.props.isFetchingProfile &&
        !nextProps.isFetchingProfile
      ) {
        if (this.props.settingActions) {
          this.props.settingActions.fetchSettings();
          this.props.settingActions.fetchAuthMechanism();
          if (
            CommonCustomizationOptions &&
            CommonCustomizationOptions.FetchPrintPieceUrl
          ) {
            this.props.settingActions.fetchPrintPieceURL();
          }
        }
        if (this.props.profileActions) {
          this.props.profileActions.fetchProfile();
        }
      }
      if (
        nextProps.profile !== undefined &&
        nextProps.profile !== null &&
        !nextProps.isFetchingProfile
      ) {
        if (nextProps.audience && nextProps.audience.type === "client") {
          if (nextProps.profileActions) {
            if (IsLNOEnabled) {
              nextProps.profileActions.fetchMyAdvisorProfile();
              nextProps.profileActions.fetchMyAgentProfile();
            } else {
              nextProps.profileActions.fetchMyAgentProfile();
            }
          }

          var gotoHome = isProfileComplete(nextProps.profile);
          this.props.listingActions.fetchAgentClientListings("favorites");
          this.props.listingActions.fetchAgentClientListings("bookmarks");
          this.props.listingActions.fetchAgentClientListings("removed");
          this.props.listingActions.fetchAgentClientListings("agentlistings");

          if (window.sessionStorage.getItem("RedirectToHome")) {
            window.sessionStorage.removeItem("RedirectToHome");
          }

          if (window.sessionStorage.getItem("ComingFromCampaign")) {
            //window.sessionStorage.removeItem("ComingFromCampaign");
            if (gotoHome) {
              window.sessionStorage.removeItem("ComingFromCampaign");
              this.props.history.push("/search");
              document.location.reload();
            } else {
              this.props.history.push("/onboarding");
              document.location.reload();
            }

            /* this.props.history.push('/onboarding');
                        document.location.reload(); */
          } else if (gotoHome) {
            window.sessionStorage.removeItem("ComingFromCampaign");
            if (window.sessionStorage.getItem("RedirectLocation")) {
              const redirectUrl = utf8.decode(
                base64.decode(window.sessionStorage.getItem("RedirectLocation"))
              );
              window.sessionStorage.removeItem("RedirectLocation");
              this.props.history.push(redirectUrl);
              document.location.reload();
            } else {
              this.props.history.push("/");
              document.location.reload();
            }
          } else {
            window.sessionStorage.removeItem("ComingFromCampaign");
            //this.props.history.push('/personalize');
            this.props.history.push("/onboarding");
            document.location.reload();
          }
        } else {
          if (nextProps.audience && nextProps.audience.type === "agent") {
            if (
              process.env.REACT_APP_AnonymousUserId !== undefined &&
              process.env.REACT_APP_AnonymousUserId !== null &&
              process.env.REACT_APP_AnonymousUserId !== "" &&
              nextProps.audience.Id &&
              nextProps.audience.Id !== "" &&
              process.env.REACT_APP_AnonymousUserId.toLowerCase() !==
                nextProps.audience.Id.toLowerCase()
            ) {
              this.props.listingActions.fetchAgentClientListings(
                "agentlistings"
              );
              window.sessionStorage.setItem("RedirectToHome", true);
            }
          }

          if (window.sessionStorage.getItem("ComingFromCampaign")) {
            window.sessionStorage.removeItem("ComingFromCampaign");
          }

          if (window.sessionStorage.getItem("RedirectToHome")) {
            window.sessionStorage.removeItem("RedirectToHome");
            if (window.sessionStorage.getItem("RedirectLocation")) {
              const redirectUrl = utf8.decode(
                base64.decode(window.sessionStorage.getItem("RedirectLocation"))
              );
              window.sessionStorage.removeItem("RedirectLocation");
              this.props.history.push(redirectUrl);
              document.location.reload();
            } else {
              this.props.history.push("/");
              document.location.reload();
            }
          }
        }
      }
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      error: "",
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.state.username || this.state.username === "") {
      this.setState({ error: "Username should not be empty" });
    } else if (!this.state.password || this.state.password === "") {
      this.setState({ error: "Password should not be empty" });
    } else {
      /* let authType;
            if (this.state.authType.toString().toLowerCase() === 'client') {
                authType = "clients";
            }
            else {
                authType = "agents";
            }

            //this.props.sessionActions.logout();
            window.sessionStorage.setItem("RedirectToHome",true);
            this.props.sessionActions.login(authType, {
                Application: "MOBILE",
                Username: this.state.username,
                Password: this.state.password,
                RememberMe: this.state.rememberMe
            }); */
      this.handleLogin({
        Application: "MOBILE",
        Username: this.state.username,
        Password: this.state.password,
        RememberMe: this.state.rememberMe,
      });
    }
  };

  handleLogin = (body) => {
    if (body) {
      let authType;
      if (this.state.authType.toString().toLowerCase() === "client") {
        authType = "clients";
      } else {
        authType = "agents";
      }

      window.sessionStorage.setItem("RedirectToHome", true);
      this.props.sessionActions.login(authType, body);
    }
  };

  onChangeAuthType = (authType) => {
    this.setState({ authType: authType });
  };

  handleSocialLogin = (user) => {
    if (user && user.Error && user.Error !== "") {
      this.setState({ error: user.Error });
    } else if (user && user.SToken && user.SToken !== "") {
      var lbody = {};
      lbody.SToken = user.SToken + "";
      if (user.Email && user.Email !== "") {
        lbody.Username = user.Email;
      }
      this.handleLogin(lbody);
    }
  };

  render() {
    const { session, isFetchingProfile, classes, purlPrefix } = this.props;

    var siteLogo = Logo;
    if (
      process.env.REACT_APP_WebsiteLogo !== undefined &&
      process.env.REACT_APP_WebsiteLogo !== null &&
      process.env.REACT_APP_WebsiteLogo !== ""
    ) {
      siteLogo = process.env.REACT_APP_WebsiteLogo;
    }

    var logoLink = "/";
    if (purlPrefix && purlPrefix.url && purlPrefix.url !== "") {
      logoLink = purlPrefix.url;
    }

    return (
      <DocumentTitle title={PageTitle.replace("{title}", this.state.title)}>
        <div>
          <div className="container-fluid pd-lt-rt-0">
            <div className="container-login">
              <div className="login-lt-cnt col-md-6 col-sm-12">
                <div className="login-form ft-none col-sm-12">
                  <div className="form-mid mg-auto">
                    <div className="login-logo">
                      <Link alt={(process.env.REACT_APP_WebsiteTitle !== undefined && process.env.REACT_APP_WebsiteTitle !== null && process.env.REACT_APP_WebsiteTitle !== "") ? process.env.REACT_APP_WebsiteTitle : "Home"} aria-label={(process.env.REACT_APP_WebsiteTitle !== undefined && process.env.REACT_APP_WebsiteTitle !== null && process.env.REACT_APP_WebsiteTitle !== "") ? process.env.REACT_APP_WebsiteTitle : "Home"} to={logoLink} rel="">
                        <img src={siteLogo} alt={(process.env.REACT_APP_WebsiteTitle !== undefined && process.env.REACT_APP_WebsiteTitle !== null && process.env.REACT_APP_WebsiteTitle !== "") ? process.env.REACT_APP_WebsiteTitle : "Home"} />
                      </Link>
                    </div>

                    <Typography
                      className={classes.typographytitle}
                      variant="h1"
                    >
                      <div className="login-header">
                        Let's Start by Logging <span>in to your account.</span>
                      </div>
                    </Typography>
                    <Typography
                      className={classes.typographyerror}
                      component={"div"}
                      variant="body2"
                      gutterBottom
                    >
                      <span>
                        {this.state.error && this.state.error !== ""
                          ? this.state.error
                          : session.error && session.error !== ""
                          ? session.error
                          : ""}
                      </span>
                    </Typography>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xl-12">
                        <Typography
                          className={classes.loginform}
                          component={"span"}
                          variant="body2"
                        >
                          <form>
                            <div className="login-signin">
                              <div className="btn-group w-100">
                                <input
                                  className="login-input"
                                  name="username"
                                  type="text"
                                  placeholder="Username"
                                  value={this.state.username}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                              <div className="btn-group w-100">
                                <input
                                  className="login-input"
                                  name="password"
                                  type="password"
                                  placeholder="Password"
                                  value={this.state.password}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                              <div className="row">
                                <div className="col-md-12 col-sm-12 col-xl-12 mg-bt-20">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-7 col-xs-6 w-40">
                                      {session.isAuthenticating ||
                                      isFetchingProfile ? (
                                        <Button
                                          variant="contained"
                                          className={
                                            classes.typographybutton +
                                            " continue-button theme-btn"
                                          }
                                        >
                                          <Loader
                                            inline="true"
                                            width="17"
                                            height="17"
                                          />
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          className={
                                            classes.typographybutton +
                                            " continue-button theme-btn"
                                          }
                                          onClick={this.handleSubmit}
                                        >
                                          Continue
                                        </Button>
                                      )}
                                    </div>
                                    <div className="col-md-8 col-sm-5 col-xs-6 w-60 text-right pd-tp-10">
                                      <Typography
                                        className={classes.typographylink}
                                        component={"span"}
                                        variant="body2"
                                      >
                                        <Link
                                          to={`${
                                            purlPrefix && purlPrefix.url
                                              ? purlPrefix.url
                                              : ""
                                          }/forgotpassword`}
                                          alt="Forgot your password Link"
                                          aria-label="Forgot your password Link"
                                        >
                                          Forgot your password?
                                        </Link>
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 col-sm-12 col-xl-12">
                                  <Typography
                                    className={classes.nolink}
                                    component={"span"}
                                    variant="body2"
                                  >
                                    <span>Don't have an account?</span>
                                  </Typography>
                                  <Typography
                                    className={
                                      classes.typographylink + " pd-lt-5"
                                    }
                                    component={"span"}
                                    variant="body2"
                                  >
                                    <Link
                                      to={`${
                                        purlPrefix && purlPrefix.url
                                          ? purlPrefix.url
                                          : ""
                                      }/register`}
                                      alt="Sign Up Link"
                                      aria-label="Sign Up Link"
                                    >
                                      Sign Up
                                    </Link>
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </form>
                        </Typography>
                      </div>
                    </div>
                    {CommonCustomizationOptions &&
                    CommonCustomizationOptions.ShowOneAllSocialLogins ? (
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="login-or">
                            <span className="middle-text">or</span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {CommonCustomizationOptions &&
                    CommonCustomizationOptions.ShowOneAllSocialLogins ? (
                      <div className="login-social">
                        <div id="oa_social_login_container"></div>
                      </div>
                    ) : null}
                    {CommonCustomizationOptions &&
                    CommonCustomizationOptions.FirebaseConfig ? (
                      <SocialLogins
                        callBackFun={this.handleSocialLogin}
                        title="OR Sign In with"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                className="login-rt-cnt col-md-6 col-sm-12"
                style={
                  LoginPageCustomizationOptions &&
                  LoginPageCustomizationOptions.BackgroundImage &&
                  LoginPageCustomizationOptions.BackgroundImage !== ""
                    ? {
                        backgroundImage:
                          "url(" +
                          LoginPageCustomizationOptions.BackgroundImage +
                          ")",
                      }
                    : {}
                }
              >
                <div className="punchline-text">
                  A new home search experience -{" "}
                  <span>starting with what really matters to you.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = (theme) => ({
  loginform: {
    "& [type=text]": {
      color: theme.palette.default.dark,
    },
    "& [type=password]": {
      color: theme.palette.default.dark,
    },
  },
  typographytitle: {
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "inherit",
    color: theme.palette.primary.title,
    "&.MuiTypography-h1": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
  },
  typographyerror: {
    color: theme.palette.error.main,
    marginBottom: 24,
    minHeight: 16,
  },
  typographybutton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.text,
    "&.MuiButton-contained": {
      boxShadow: "inherit",
      letterSpacing: "inherit",
      lineHeight: "normal",
      textTransform: "none",
    },
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
    "&:focus": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
  },
  typographylink: {
    "& a": {
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
      "&:focus": {
        color: theme.palette.primary.main,
      },
    },
  },
  nolink: {
    "& span": {
      color: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = (state, props) => ({
  session: state.session,
  audience: state.session.audience,
  profile: state.profile.mine,
  isFetchingProfile: state.profile.isFetching,
  purlPrefix: state.session.purlPrefix || { url: "" },
});

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  profileActions: bindActionCreators(profileActions, dispatch),
  settingActions: bindActionCreators(settingActions, dispatch),
  listingActions: bindActionCreators(listingActions, dispatch),
  agentActions: bindActionCreators(agentActions, dispatch),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);

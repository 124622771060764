import {
  ANT_CNT_REQUEST, ANT_CNT_SUCCESS, ANT_CNT_FAILURE,
  ANT_LST_REQUEST, ANT_LST_SUCCESS, ANT_LST_FAILURE,
  ANT_ADD_SUCCESS, ANT_DEL_SUCCESS, ANT_RESET_SUCCESS, ANT_UPDATE_SUCCESS,
  ANT_POST_REQUEST, ANT_POST_SUCCESS, ANT_POST_FAILURE
} from 'actions/analytics';

export default (state = {
  isFetching: false,
  counts: [],
  list: [],
  error: undefined,
  bucket: []
}, action) => {
  switch (action.type) {
    case ANT_CNT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };
    case ANT_CNT_SUCCESS:
      var obj = state.counts || [];
      if(action && action.paramkey && action.response !== undefined && action.response !== null){
        obj = obj.filter(c => c.paramkey !== action.paramkey);
        //if(action.paramkey === "regusers" && action.response.length > 0){
        if(action.response.length > 0){
          var cval = action.response.filter(v => v.key === "Current");
          if(cval && cval.length > 0 && cval[0].value !== undefined && cval[0].value !== null){
            cval = cval[0].value;
            //obj = obj.concat([{"paramkey": action.paramkey, "value": fval[0].value, params: {...action.params}}]);
          }
          else {
            cval = 0;
          }
          var pval = action.response.filter(v => v.key === "Previous");
          if(pval && pval.length > 0 && pval[0].value !== undefined && pval[0].value !== null){
            pval = pval[0].value;
          }
          else {
            pval = 0;
          }
          obj = obj.concat([{"paramkey": action.paramkey, "value": cval, "pvalue": pval, params: {...action.params}}]);
        }
        else {
          obj = obj.concat([{"paramkey": action.paramkey, "value": action.response, pval: 0, params: {...action.params}}]);
        }        
      }
      return {
          ...state,
          isFetching: false,
          counts: obj
      }
    case ANT_CNT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case ANT_LST_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };
    case ANT_LST_SUCCESS:
      var obj = state.list || [];
      if(action && action.paramkey && action.response !== undefined && action.response !== null){
        obj = obj.filter(c => c.paramkey !== action.paramkey);
        obj = obj.concat([{"paramkey": action.paramkey, "value": action.response, params: {...action.params}}]);
      }
      return {
          ...state,
          isFetching: false,
          list: obj
      }
    case ANT_LST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case ANT_ADD_SUCCESS:
      //console.log("ANT_ADD_SUCCESS");
      var obj = state.bucket || [];
      //console.log("Before obj: ", JSON.stringify(obj));
      if(action && action.obj && action.obj.key){
        obj = obj.filter(c => c.key !== action.obj.key);
        if(action.obj.value !== undefined && action.obj.value !== null){
          obj.push({
            key: action.obj.key,
            value: Array.isArray(action.obj.value) ? action.obj.value.join(",") : action.obj.value
          });
        }        
      }
      //console.log("After obj: ", JSON.stringify(obj));
      return {
          ...state,
          bucket: obj
      }
    case ANT_DEL_SUCCESS:
      var obj = state.bucket || [];
      if(action && action.obj && action.obj.key){
        obj = obj.filter(c => c.key !== action.obj.key);
      }
      //console.log("ANT_DEL_SUCCESS: ", JSON.stringify(obj));
      return {
          ...state,
          bucket: obj
      }
    case ANT_RESET_SUCCESS:
      //console.log("ANT_RESET_SUCCESS: ", "[]");
      return {
          ...state,
          bucket: []
      }
    case ANT_UPDATE_SUCCESS:
      //console.log("ANT_ADD_SUCCESS");
      /* var obj = state.bucket || [];
      //console.log("Before obj: ", JSON.stringify(obj));
      if(action && action.obj && action.obj.key){
        obj = obj.filter(c => c.key !== action.obj.key);
        if(action.obj.value !== undefined && action.obj.value !== null){
          obj.push({
            key: action.obj.key,
            value: Array.isArray(action.obj.value) ? action.obj.value.join(",") : action.obj.value
          });
        }        
      } */
      //console.log("After obj: ", JSON.stringify(obj));
      return {
          ...state,
          bucket: [].concat(action.obj)
      }
    case ANT_POST_REQUEST:
      //console.log("ANT_POST_REQUEST");
      return {
        ...state,
        error: undefined
      };
    case ANT_POST_SUCCESS:
      //console.log("ANT_POST_SUCCESS: ", "[]");
      return {
          ...state,
          bucket: []
      }
    case ANT_POST_FAILURE:
      //console.log("ANT_POST_FAILURE");
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store/store'
import App from 'containers/App';
import { unregister } from 'registerServiceWorker';
import {isIE, browserVersion} from 'react-device-detect';
import setupGlobalVariables from 'setupGlobalVariables';
import setupLoginState from 'setupLoginState';

//import 'css/index.css';
//import 'bootstrap/dist/css/bootstrap.css';
//import 'font-awesome/css/font-awesome.css';
//import 'css/select-box.css';
//Carousel  css
//import 'react-responsive-carousel/lib/styles/carousel.min.css';
//import 'css/variable.css';
//import './css/App.css';
//import 'scss/App.scss';
// Print Css
//import 'css/print.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import 'material-icons/iconfont/material-icons.scss';
import 'scss/AppND.scss';
import 'scss/App-materialND.scss';

if (process.env.REACT_APP_SyncFusionLKey && process.env.REACT_APP_SyncFusionLKey !== ""){
    import('@syncfusion/ej2-base').then(m => m.registerLicense(process.env.REACT_APP_SyncFusionLKey));
}

setupGlobalVariables();
setupLoginState(store);

if(isIE && browserVersion <= 10){
    ReactDOM.render(
        <div>
            <h2 className="text-center">Internet Explorer 10 or lower versions are not supported</h2>
            <h4 className="text-center">Either update to Internet Explorer 11 or Edge <span style={{marginLeft: 10}}><a href="https://support.microsoft.com/en-in/help/4028118/windows-run-the-latest-version-of-internet-explorer-11" alt="Internet Explorer Link" aria-label="Internet Explorer Link" target="_blank" rel="noopener noreferrer">[Check here]</a></span></h4>
            <h4 className="text-center">Or use Google Chrome <span style={{marginLeft: 10}}><a href="https://www.google.com/chrome/" alt="Download Here Link" aria-label="Download Here Link" target="_blank" rel="noopener noreferrer">[Download here]</a></span></h4>
        </div>,
        document.getElementById('root')
    );
}
else {
    ReactDOM.render(
        <Provider store={ store }>
            <App />
        </Provider>,
        document.getElementById('root')
    );
}

//registerServiceWorker();
// Because Service Workers were added before we have to disable them
unregister();

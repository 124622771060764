import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import qs from "query-string";
import store from '../store/store';
import { verifyAuthToken } from 'actions/session';
import Loader from 'components/loader';
import { GetSectionCustomizationOptions } from 'components/util';
import * as profileActions from 'actions/profile';
import * as settingActions from 'actions/settings';
import * as listingActions from 'actions/listings';
import * as sessionActions from 'actions/session';

import 'css/App.css';
import 'scss/App.scss';

const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");
const IsLNOEnabled = (CommonCustomizationOptions && CommonCustomizationOptions.IsLNOEnabled) ? true : false;

class CampaignPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }

    state = {}

    componentDidMount() {
        const obj = qs.parse(this.props.location.search);
        if(obj && obj.t && obj.audienceType && obj.audienceId){
            var self = this;
            setTimeout(() => {
                store.dispatch(verifyAuthToken({
                    AudienceType: obj.audienceType,
                    AudienceId: obj.audienceId,
                    AccessToken: obj.t,
                    skip: true
                })).then(r => {
                    
                    if (r.response && r.response.AudienceType === "CLIENT" && r.response.Audience && r.response.Audience.Id && r.response.Audience.Id === obj.audienceId) {

                        self.props.profileActions.fetchProfile().then((res) => { 
                            if(IsLNOEnabled){
                                self.props.profileActions.fetchMyAdvisorProfile();
                                self.props.profileActions.fetchMyAgentProfile();
                            }
                            else {
                                self.props.profileActions.fetchMyAgentProfile();
                            }                            
                            self.props.listingActions.fetchAgentClientListings("favorites");
                            self.props.listingActions.fetchAgentClientListings("bookmarks");
                            self.props.listingActions.fetchAgentClientListings("removed");
                            self.props.listingActions.fetchAgentClientListings("agentlistings");
                        });
                        self.props.settingActions.fetchSettings();
                        if(CommonCustomizationOptions && CommonCustomizationOptions.FetchPrintPieceUrl){
                            self.props.settingActions.fetchPrintPieceURL();
                        }
                        self.props.settingActions.fetchAuthMechanism();
                        
                        if(self.props.history) self.props.history.push('/onboarding');
                    }
                    else{
                        window.location.href = "/";                        
                    }
                    
                });
            },500);
        }
        else {
            window.sessionStorage.setItem("ComingFromCampaign", "true");
            window.location.href = "/login";
        }
    }    

    render() {
        return (
            <div style={{display: 'block', width: '100%', margin: "50px auto"}}>
                <Loader message={'Loading...'} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    session: state.session,
    audience: state.session.audience,
    profile: state.profile.mine
});

const mapDispatchToProps = dispatch => ({
    sessionActions: bindActionCreators(sessionActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    listingActions: bindActionCreators(listingActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignPage);
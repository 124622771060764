import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import qs from "query-string";
import DocumentTitle from "react-document-title";
import base64 from "base-64";
import utf8 from "utf8";
import {
  PageTitle,
  GetSectionCustomizationOptions,
  isProfileComplete,
} from "components/util";
import store from "store/store";
import "scss/_login.scss";
import * as sessionActions from "actions/session";
import * as settingActions from "actions/settings";
import * as profileActions from "actions/profile";
import * as clientActions from "actions/clients";
import * as listingActions from "actions/listings";
import * as agentActions from "actions/agents";
import { verifyAuthToken } from "actions/session";
import { Typography } from "@material-ui/core";

const CommonCustomizationOptions =
  GetSectionCustomizationOptions("CommonOptions");
const IsLNOEnabled =
  CommonCustomizationOptions && CommonCustomizationOptions.IsLNOEnabled
    ? true
    : false;

class ExSSOPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = {
    title: "Login",
    SSOAuthDetails: null,
  };

  componentDidMount() {
    const obj = qs.parse(this.props.location.search);
    if (obj && obj.t && obj.audienceType && obj.audienceId) {
      setTimeout(() => {
        store
          .dispatch(
            verifyAuthToken(
              {
                AudienceType: obj.audienceType,
                AudienceId: obj.audienceId,
                AccessToken: obj.t,
                skip: true,
              },
              false
            )
          )
          .then((r) => {
            var self = this;
            if (r.response && r.response.AudienceType === "MLSAGENT") {
              this.props.profileActions.fetchProfile().then((res) => {
                self.props.listingActions.fetchAgentClientListings(
                  "agentlistings"
                );
              });
              this.props.settingActions.fetchSettings();
              if (
                CommonCustomizationOptions &&
                CommonCustomizationOptions.FetchPrintPieceUrl
              ) {
                this.props.settingActions.fetchPrintPieceURL();
              }
              this.props.settingActions.fetchAuthMechanism();
              this.props.clientActions.fetchClients();
              if (
                CommonCustomizationOptions &&
                CommonCustomizationOptions.EnableTeamMembers &&
                this.props.agentActions
              ) {
                this.props.agentActions.fetchTeamAgents();
              }
              if (this.props.history) {
                this.props.history.push(this.props.purlPrefix.url + "/");
                document.location.reload();
              }
            } else if (r.response && r.response.AudienceType === "CLIENT") {
              this.props.settingActions.fetchSettings();
              this.props.settingActions.fetchAuthMechanism();
              if (
                CommonCustomizationOptions &&
                CommonCustomizationOptions.FetchPrintPieceUrl
              ) {
                this.props.settingActions.fetchPrintPieceURL();
              }

              this.props.profileActions.fetchProfile().then((res) => {
                self.props.profileActions.fetchMyAgentProfile();
                if (IsLNOEnabled) {
                  self.props.profileActions.fetchMyAdvisorProfile();
                }
                self.props.listingActions.fetchAgentClientListings("favorites");
                self.props.listingActions.fetchAgentClientListings("bookmarks");
                self.props.listingActions.fetchAgentClientListings("removed");
                self.props.listingActions.fetchAgentClientListings(
                  "agentlistings"
                );

                var gotoHome = true; // isProfileComplete(self.props.profile);

                if (gotoHome) {
                  if (window.sessionStorage.getItem("RedirectLocation")) {
                    const redirectUrl = utf8.decode(
                      base64.decode(
                        window.sessionStorage.getItem("RedirectLocation")
                      )
                    );
                    window.sessionStorage.removeItem("RedirectLocation");
                    self.props.history.push(redirectUrl);
                    document.location.reload();
                  } else {
                    if (obj.newuser && obj.newuser == "1") {
                      if (
                        CommonCustomizationOptions &&
                        CommonCustomizationOptions.NewUserRedirection &&
                        CommonCustomizationOptions.NewUserRedirection !== ""
                      ) {
                        var url = base64.encode(
                          utf8.encode(
                            CommonCustomizationOptions.NewUserRedirection
                          )
                        );
                        window.sessionStorage.setItem(
                          "ExSSORedirectLocation",
                          url
                        );
                      }
                      self.props.history.push("/");
                      document.location.reload();
                    } else {
                      self.props.history.push("/");
                      document.location.reload();
                    }
                  }
                } else {
                  self.props.history.push("/onboarding");
                  document.location.reload();
                }
              });
              //if(this.props.history) this.props.history.push(this.props.purlPrefix.url + "/");
            }
          });
      }, 500);
    } else {
      setTimeout(() => {
        var authDt = window.sessionStorage.getItem("AuthMechanism");
        if (authDt) {
          if (
            window.sessionStorage.getItem("ComingForExSSO") ||
            (obj && obj.rm && obj.rm === "1")
          ) {
            window.sessionStorage.removeItem("ComingForExSSO");
            authDt = JSON.parse(authDt);
            if (
              authDt &&
              authDt.Idp &&
              authDt.Idp !== "" &&
              authDt.SAMLRequest &&
              authDt.SAMLRequest !== ""
            ) {
              window.sessionStorage.setItem("SubmitExSSORequest", "true");
              if (
                obj &&
                obj.audiencetype &&
                obj.audiencetype != "" &&
                obj.action &&
                obj.action != ""
              ) {
                this.props.settingActions.fetchAuthMechanism(
                  null,
                  obj.audiencetype,
                  obj.action
                );
              } else {
                this.props.settingActions.fetchAuthMechanism();
              }
            }
          } else {
            if (this.props.history)
              this.props.history.push(this.props.purlPrefix.url + "/");
          }
        } else {
          if (
            obj &&
            obj.audiencetype &&
            obj.audiencetype != "" &&
            obj.action &&
            obj.action != ""
          ) {
            if (obj.audiencetype === "client" && obj.action === "signup") {
              if (this.props.history)
                this.props.history.push(
                  this.props.purlPrefix.url + "/register"
                );
            } else if (
              obj.audiencetype === "client" &&
              obj.action === "login"
            ) {
              if (this.props.history)
                this.props.history.push(this.props.purlPrefix.url + "/login");
            }
          } else {
            if (this.props.history)
              this.props.history.push(this.props.purlPrefix.url + "/");
          }
        }
      }, 500);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.isAuthMechFetching === true &&
      nextProps.isAuthMechFetching === false
    ) {
      var authDt = window.sessionStorage.getItem("AuthMechanism");
      if (authDt) {
        authDt = JSON.parse(authDt);
        if (
          authDt &&
          authDt.Idp &&
          authDt.Idp !== "" &&
          authDt.SAMLRequest &&
          authDt.SAMLRequest !== ""
        ) {
          if (window.sessionStorage.getItem("SubmitExSSORequest")) {
            window.sessionStorage.removeItem("SubmitExSSORequest");
            this.setState({ SSOAuthDetails: authDt }, () => {
              setTimeout(() => {
                if (this.ssoForm) this.ssoForm.submit();
              }, 500);
            });
          }
        }
      }
    }
  }

  render() {
    const { SSOAuthDetails } = this.state;
    const { classes } = this.props;
    if (SSOAuthDetails !== undefined && SSOAuthDetails !== null) {
      return (
        <DocumentTitle title={PageTitle.replace("{title}", this.state.title)}>
          <div>
            <form
              method="POST"
              action={SSOAuthDetails.Idp}
              ref={(ref) => (this.ssoForm = ref)}
            >
              <input
                type="hidden"
                name="SAMLRequest"
                value={SSOAuthDetails.SAMLRequest}
              />
            </form>
            <div className="redirect-loading">
              <div className="pd-loader-icon">
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              </div>
              <div className="pd-loader-text">
                <Typography className="pd-loader-text-t1" variant="h1">
                  Redirecting...
                </Typography>
                <Typography className="pd-loader-text-t2" variant="h2">
                  Please wait
                </Typography>
              </div>
            </div>
          </div>
        </DocumentTitle>
      );
    }

    return (
      <DocumentTitle title={PageTitle.replace("{title}", this.state.title)}>
        <div></div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state, props) => ({
  session: state.session,
  audience: state.session.audience,
  profile: state.profile.mine,
  isFetchingProfile: state.profile.isFetching,
  isAuthMechFetching: state.settings.isAuthMechFetching,
  purlPrefix: state.session.purlPrefix || { url: "" },
});

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  settingActions: bindActionCreators(settingActions, dispatch),
  profileActions: bindActionCreators(profileActions, dispatch),
  clientActions: bindActionCreators(clientActions, dispatch),
  listingActions: bindActionCreators(listingActions, dispatch),
  agentActions: bindActionCreators(agentActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExSSOPage);

import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  UPDATE_PARAMS_REQUEST,
  RESET_PARAMS_REQUEST,
} from "actions/search";
import { getDefaultSearchParams } from "components/util";

/*const defaultParams = {
    limitrecords: "100",
    ficoscore: "760-850",
    loantype: "30YrFixed",
    lifestyle: "couple-no-kids",
    orderby: "newest",
    orderbydirection: "DESC",
    skip: 0,
    propertytype: "sf"
};*/

export default (
  state = {
    isFetching: false,
    error: undefined,
    notificationMessage: undefined,
    //parameters: defaultParams
    parameters: getDefaultSearchParams(),
  },
  action
) => {
  switch (action.type) {
    case SEARCH_REQUEST:
    case SEARCH_SUCCESS:
    case SEARCH_FAILURE:
    case UPDATE_PARAMS_REQUEST:
      var params = action.parameters;
      if (!action.skipFlag) delete params["pagechange"];
      return {
        ...state,
        lastParameters: state.parameters,
        //parameters: Object.assign({}, defaultParams, params)
        parameters: Object.assign({}, getDefaultSearchParams(), params),
      };

    case RESET_PARAMS_REQUEST:
      return {
        ...state,
        lastParameters: state.parameters,
        //parameters: defaultParams,
        parameters: Object.assign(
          {},
          getDefaultSearchParams(),
          action.params || {}
        ),
      };

    default:
      return state;
  }
};

import { normalize, schema } from 'normalizr';

import {isStaticRendering} from '../react-static/staticRenderState';

// const profileSchema = new schema.Entity('profile', {}, {
//   idAttribute: client => client.Id ? client.Id : client.AgentId
// });
//

const clientSchema = new schema.Entity('clients', {}, {
  idAttribute: client => client.Id
});

const listingSchema = new schema.Entity('listings', {}, {
  idAttribute: listing => listing.Id
});

const activitySchema = new schema.Entity('activities', {}, {
  idAttribute: activity => `${activity.Id}`
});

const feedItemSchema = new schema.Entity('feedItems', {
    // Listing: listingSchema
}, {
  idAttribute: feedItem => `${feedItem.ClientId}_${feedItem.ListingId}`
});

const listingArraySchema = { Listings: new schema.Array(listingSchema) };
const clientArraySchema = { Clients: new schema.Array(clientSchema) };

const invitationSchema = new schema.Entity('invitations', {}, {
  idAttribute: invitation => invitation.Id
});

export const Schemas = {
  CLIENT: clientSchema,
  CLIENT_ARRAY: clientArraySchema,
  LISTING: listingSchema,
  LISTING_ARRAY: listingArraySchema,
  FEED_ITEM: feedItemSchema,
  FEED_ITEM_ARRAY: [feedItemSchema],
  ACTIVITY_ITEM: activitySchema,
  ACTIVITY_ITEM_ARRAY: [activitySchema],
  INVITATION_ITEM: invitationSchema,
  INVITATION_ITEM_ARRAY: [invitationSchema]
};

export const CALL_API = 'Call API';

const callApi = (endpoint, schema, options) => {
  const API_ROOT = window.sessionStorage.getItem('RootAPI');
  return fetch(API_ROOT + endpoint, options)
    .then(response =>
      response.json().then(json => {
        if (!response.ok) {
          return Promise.reject(json);
        }

        if (schema) {
            return normalize(json, schema);
        }
        else {
            return json;
        }

      })
    );
};

export default store => next => action => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  // TODO react static - handle static render cases here if necessary.
  // Note that this store would need to be updated in the static.config.js
  // getData before any React components are rendered. react-static will
  // not wait for async calls elsewhere to complete.
  if (isStaticRendering) {
    return;
  }

  const { endpoint, schema, types, method, body } = callAPI;

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }
  if (!method) {
    throw new Error('Specify a method for api call.');
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const options = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + window.sessionStorage.getItem('APIKey')
    },
    method
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const actionWith = data => {
    const finalAction = { ...action, ...data };
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [ requestType, successType, failureType ] = types;
  next(actionWith({ type: requestType }));

  return callApi(endpoint, schema, options).then(
    response => next(actionWith({
      response,
      type: successType
    })),
    error => next(actionWith({
      type: failureType,
      error: error.ExceptionMessage || error.ErrorMessage || (error[0] && error[0].ErrorMessage) || 'An error has occurred.'
    }))
  );
}

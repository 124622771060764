import React from "react";
import { connect } from "react-redux";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { GetSectionCustomizationOptions } from "./util";

const CommonCustomizationOptions =
  GetSectionCustomizationOptions("CommonOptions");

class NavbarLinksBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      navExpanded: false,
      menuItems: [
        {
          title: "Dashboard",
          url: "http://truebytlc/dashboard",
        },
        {
          title: "Find Homes",
          url: "http://truebytlc/search",
        },
        {
          title: "About Us",
          url: "http://truebytlc/aboutus",
        },
      ],
    };

    this.truePlaceholder = "http://truebytlc";
    this.trueCmsPlaceholder = "http://cms.truebytlc";
  }

  contains = (url, key) => {
    return url.includes(key);
  };

  toUrl = (url) => {
    var result = url
      .replace(this.truePlaceholder, "")
      .replace(this.trueCmsPlaceholder, "/info");
    if (this.props.purlPrefix && this.props.purlPrefix.url)
      result = this.props.purlPrefix.url + result;

    return result;
  };

  goTo = (url) => {
    this.props.history.push(url);
  };

  buildUrlWithMapping = (item) => {
    var result = item.url;
    if (
      item.url.includes(this.truePlaceholder) ||
      item.url.includes(this.trueCmsPlaceholder)
    ) {
      result = this.toUrl(item.url);
    }
    return result;
  };

  onClickSubMenu = (subChild, newWindow = true) => {
    var url = this.buildUrlWithMapping(subChild);
    if (url !== subChild.url) {
      if (this.props.closeNav) this.props.closeNav();
      this.goTo(url);
    } else {
      if (this.props.closeNav) this.props.closeNav();
      if (newWindow) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    }
  };

  buildDropDown = (item, i) => {
    return (
      <NavDropdown
        key={i}
        title={item.title}
        className="neighborhoods-list"
        to={item.url && item.url !== "" ? this.toUrl(item.url) : ""}
        id={"collasible-nav-dropdown-" + i}
        alt={item.title} 
        aria-label={item.title}
      >
        {item.children.map((child, j) => {
          return this.buildLink(child, j, true);
        })}
      </NavDropdown>
    );
  };

  buildLink = (item, i, isNestedLink) => {
    const { isAnonymous, isAuthenticated } = this.props;
    switch (true) {
      case this.contains(item.url, `${this.truePlaceholder}/dashboard`): {
        if (isAuthenticated && !isAnonymous) {
          if (isNestedLink) {
            return (
              <NavDropdown.Item
                key={i}
                as={NavLink}
                to={this.toUrl(item.url)}
                alt="Dashboard" 
                aria-label="Dashboard"
                onClick={() => {
                  if (this.props.closeNav) {
                    this.props.closeNav();
                  }
                }}
              >
                Dashboard
              </NavDropdown.Item>
            );
          }
          return (
            <Nav.Link alt="Dashboard" aria-label="Dashboard" key={i} as={NavLink} to={this.toUrl(item.url)}>
              Dashboard
            </Nav.Link>
          );
        } else return null;
      }
      case this.contains(item.url, `${this.truePlaceholder}/mybudget`): {
        var url = "";

        if (
          CommonCustomizationOptions &&
          CommonCustomizationOptions.MyBudgetURL &&
          CommonCustomizationOptions.MyBudgetURL !== ""
        ) {
          url = CommonCustomizationOptions.MyBudgetURL;
        } else {
          url = "/";
        }

        if (isNestedLink) {
          return (
            <NavDropdown.Item
              key={i}
              as={NavLink}
              to="#"
              onClick={() => {
                if (!isAuthenticated && isAnonymous) {
                  this.props.showSignupModal(true);
                } else if (url != "/") this.onClickSubMenu({ url }, false);
              }}
              alt={item.title} 
              aria-label={item.title}
            >
              {item.title}
            </NavDropdown.Item>
          );
        }
        return (
          <Nav.Link
            key={i}
            as={NavLink}
            alt={item.title}
            aria-label={item.title}
            to="#"
            onClick={() => {
              if (!isAuthenticated && isAnonymous) {
                this.props.showSignupModal(true);
              } else if (url != "/") this.onClickSubMenu({ url }, false);
            }}
          >
            {item.title}
          </Nav.Link>
        );
      }
      case this.contains(item.url, this.truePlaceholder): {
        if (isNestedLink) {
          return (
            <NavDropdown.Item
              key={i}
              as={NavLink}
              to={this.toUrl(item.url)}
              alt={item.title}
              aria-label={item.title}
              onClick={() => {
                if (this.props.closeNav) {
                  this.props.closeNav();
                }
              }}
            >
              {item.title}
            </NavDropdown.Item>
          );
        }
        return (
          <Nav.Link alt={item.title} aria-label={item.title}  key={i} as={NavLink} to={this.toUrl(item.url)}>
            {item.title}
          </Nav.Link>
        );
      }
      default: {
        if (isNestedLink) {
          return (
            <NavDropdown.Item
              key={i}
              as={NavLink}
              to="#"
              alt={item.title} 
              aria-label={item.title} 
              onClick={() => this.onClickSubMenu(item)}
            >
              {item.title}
            </NavDropdown.Item>
          );
        }
        return (
          <Nav.Link
            key={i}
            as={NavLink}
            to="#"
            alt={item.title} 
            aria-label={item.title} 
            onClick={() => this.onClickSubMenu(item)}
          >
            {item.title}
          </Nav.Link>
        );
      }
    }
  };

  render() {
    var menuItems = [];
    if (this.state.menuItems && this.state.menuItems.length > 0) {
      menuItems = [].concat(this.state.menuItems);
    }
    if (
      process.env.REACT_APP_WebsiteMenu !== undefined &&
      process.env.REACT_APP_WebsiteMenu !== null &&
      process.env.REACT_APP_WebsiteMenu !== ""
    ) {
      menuItems = JSON.parse(process.env.REACT_APP_WebsiteMenu);
    }

    return (
      <Navbar.Text className="nav-links tour-menu-links">
        {window.location.pathname !== "/personalize" &&
          menuItems &&
          menuItems.map((item, i) => {
            if (item.children && item.children.length > 0) {
              return this.buildDropDown(item, i);
            } else {
              return this.buildLink(item, i);
            }
          })}
      </Navbar.Text>
    );
  }
}

const mapStateToProps = (state, props) => ({
  purlPrefix: state.session.purlPrefix || { url: "" },
  isAuthenticated: state.session.isAuthenticated || false,
  isAnonymous: state.session.isAnonymous,
});

export default connect(mapStateToProps)(NavbarLinksBuilder);

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChatMessages from 'components/directchat/chatmessages';
import DefaultPhoto from 'img/nd/default-photo.png';
import { GetSectionCustomizationOptions } from 'components/util';
import 'scss/_livechatND.scss';
import * as chatActions from 'actions/chat';


const ProfilePageCustomizationOptions = GetSectionCustomizationOptions("ProfilePageOptions");
const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");
const IsLNOEnabled = (CommonCustomizationOptions && CommonCustomizationOptions.IsLNOEnabled) ? true : false;

class DirectChatPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showWindow: props.showWindow || false
        };
    }

    componentWillReceiveProps(nextProps){
        if(this.props.showWindow !== nextProps.showWindow && nextProps.showWindow !== this.state.showWindow){
            /* if(nextProps.showWindow){
                this.props.chatActions.fetchChats((nextProps.selectedClient && nextProps.selectedClient.Id) ? nextProps.selectedClient.Id : null);
            } */
            this.setState({showWindow: nextProps.showWindow});    
        }
    }

    toggleShowWindow = () => {
        this.setState({showWindow: !this.state.showWindow});
        if(this.props.hideChatWindow){
            this.props.hideChatWindow(!this.state.showWindow);
        }
    }

    render() {

        //console.log('chatpopup - render');
        //console.log('this.props: ');
        //console.log(this.props);

        var scName = "";
        //var scClass = "chat_button_bg";
        var scPhotoClass = "";
        var fromPhoto = null, toPhoto = null;
        if(this.props.audience && this.props.audience.type === 'agent'){
            if(this.props.selectedClient){
                scName = "Your Chat with " + this.props.selectedClient.FirstName;
                //scClass = "chat_button_bg";                

                fromPhoto = toPhoto = <img src={DefaultPhoto} alt=""/>;
                if(this.props.selectedClient.Profile && this.props.selectedClient.Profile.BasicData){
                    toPhoto = (
                        <div className="chatprofile_name">
                            <div className="chatprofile_box">{this.props.selectedClient.Profile.BasicData.FirstName.substring(0, 1) + this.props.selectedClient.Profile.BasicData.LastName.substring(0, 1)}</div>
                        </div>
                    );
                }
                if(this.props.profile){
                    fromPhoto = (
                        <div className="chatprofile_name">
                            <div className="chatprofile_box">{this.props.profile.FirstName.substring(0, 1) + this.props.profile.LastName.substring(0, 1)}</div>
                        </div>
                    );
                }
                if(this.props.selectedClient.Profile && this.props.selectedClient.Profile.BasicData &&
                    this.props.selectedClient.Profile.BasicData.ProfileImageUrl &&
                    this.props.selectedClient.Profile.BasicData.ProfileImageUrl !== ""){
                    toPhoto = <img src={this.props.selectedClient.Profile.BasicData.ProfileImageUrl} alt=""/>;
                }
                if(ProfilePageCustomizationOptions && ProfilePageCustomizationOptions.AllowAgentToUpdateProfile){
                    if(this.props.profile && this.props.profile.UseMemberPhotoURL) {
                        if(this.props.profile.MemberPhotoURL !== undefined &&
                            this.props.profile.MemberPhotoURL !== null && this.props.profile.MemberPhotoURL !== "") {
                            fromPhoto = <img src={this.props.profile.MemberPhotoURL} alt=""/>;
                        }
                    }
                    else{
                        if(this.props.profile && this.props.profile.Profile &&
                            this.props.profile.Profile.ProfileImageName &&
                            this.props.profile.Profile.ProfileImageName !== ""){
                            fromPhoto = <img src={this.props.profile.Profile.ProfileImageName} alt=""/>;
                        }
                    }
                }
                else{
                    if(this.props.profile && this.props.profile.Profile &&
                        this.props.profile.Profile.ProfileImageName &&
                        this.props.profile.Profile.ProfileImageName !== ""){
                        fromPhoto = <img src={this.props.profile.Profile.ProfileImageName} alt=""/>;
                    }
                }

                if(fromPhoto && toPhoto){
                    scPhotoClass = "has_photo";
                }
            }
            else{
                scName = "";
                //scClass = "chat_button_bg gray_bg";
            }
        }
        else if(this.props.audience && this.props.audience.type === 'client') {
            scName = "Chat with your Agent";
            if(IsLNOEnabled) scName = "Chat with your Advisor";

            fromPhoto = toPhoto = <img src={DefaultPhoto} alt=""/>;
            
            if(this.props.profile && this.props.profile.Profile && this.props.profile.Profile.BasicData){
                fromPhoto = (
                    <div className="chatprofile_name">
                        <div className="chatprofile_box">{this.props.profile.Profile.BasicData.FirstName.substring(0, 1) + this.props.profile.Profile.BasicData.LastName.substring(0, 1)}</div>
                    </div>
                );
            }
            if(this.props.agent){
                toPhoto = (
                    <div className="chatprofile_name">
                        <div className="chatprofile_box">{this.props.agent.FirstName.substring(0, 1) + this.props.agent.LastName.substring(0, 1)}</div>
                    </div>
                );
            }
            if(this.props.profile && this.props.profile.Profile && this.props.profile.Profile.BasicData &&
                this.props.profile.Profile.BasicData.ProfileImageUrl &&
                this.props.profile.Profile.BasicData.ProfileImageUrl !== ""){
                fromPhoto = <img src={this.props.profile.Profile.BasicData.ProfileImageUrl} alt=""/>;
            }
            if(this.props.agent){
                if(ProfilePageCustomizationOptions && ProfilePageCustomizationOptions.AllowAgentToUpdateProfile){
                    if(this.props.agent && this.props.agent.UseMemberPhotoURL) {
                        if(this.props.agent.MemberPhotoURL !== undefined &&
                            this.props.agent.MemberPhotoURL !== null && this.props.agent.MemberPhotoURL !== "") {
                            toPhoto = <img src={this.props.agent.MemberPhotoURL} alt=""/>;
                        }
                    }
                    else{
                        if(this.props.agent &&this.props.agent.Profile && 
                            this.props.agent.Profile.ProfileImageName &&
                            this.props.agent.Profile.ProfileImageName !== ""){
                            toPhoto = <img src={this.props.agent.Profile.ProfileImageName} alt=""/>;
                        }
                    }
                }
                else{
                    if(this.props.agent &&this.props.agent.Profile && 
                        this.props.agent.Profile.ProfileImageName &&
                        this.props.agent.Profile.ProfileImageName !== ""){
                        toPhoto = <img src={this.props.agent.Profile.ProfileImageName} alt=""/>;
                    }
                }
                scName = "Your Chat with " + this.props.agent.FirstName;
            }
            if(fromPhoto && toPhoto){
                scPhotoClass = "has_photo";
            }
        }

        var cls = this.state.showWindow ? "chat-window active  " + scPhotoClass : "chat-window inactive " + scPhotoClass;
        var commentWindow = null;        

        commentWindow = (
            <div className={cls}>
                <div className="chat-header">
                    <div className="chat-header-name">
                        <span className="header-short-name">{(this.props.isSelectedClientFetching || this.props.isChatFetching) ? "Fetching Chat messages..." : scName}</span>
                        {(!this.props.isSelectedClientFetching && !this.props.isChatFetching) &&
                            <div className="window-close" onClick={this.toggleShowWindow}>
                                <span className="material-icons close-icon">close</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="message-list">
                    <ChatMessages classes={this.props.classes} listingId={this.props.listingId} {...this.props} />
                </div>
            </div>
        );

        return (
            <div id="direct-chat">
                {commentWindow}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    audience: state.session.audience,
    isFetchingProfile: state.profile.isFetching,
    profile: state.profile.mine,
    agent: (IsLNOEnabled && state.chat && state.chat.isChatWithLNO) ? (state.profile.advisor || null) : (state.profile.agent || null),
    isSelectedClientFetching: (state.chat && state.chat.isFetchingClient) ? state.chat.isFetchingClient : false,
    isChatFetching: (state.chat && state.chat.isFetching) ? state.chat.isFetching : false,
    selectedClient: (state.chat && state.chat.selectedClient) ? state.chat.selectedClient : null
});

const mapDispatchToProps = dispatch => ({
    chatActions: bindActionCreators(chatActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DirectChatPopup);
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CommonAdComponent from "components/commonadcomponent";
import { getFooterAdId, GetSectionCustomizationOptions } from "components/util";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { faBloggerB } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@material-ui/core/Typography";
import FooterArk from "components/footerarkstyle";
import * as manageActions from "actions/manage";
import AndroidLogo from "img/nd/google-play.png";
import IOSLogo from "img/nd/svg/ios_app_store.svg";

const FooterCustomizationOptions = GetSectionCustomizationOptions(
  "FooterOptions"
);
const MobileAppOptions = GetSectionCustomizationOptions("MobileAppOptions");

const IsArkSite =
  process.env.REACT_APP_IsArkSite !== undefined &&
  process.env.REACT_APP_IsArkSite !== null &&
  process.env.REACT_APP_IsArkSite !== ""
    ? true
    : false;

class Footer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};

    window.sessionStorage.removeItem("RefreshFooterAd");
  }

  componentWillReceiveProps(nextProps) {
    if (
      !IsArkSite &&
      FooterCustomizationOptions &&
      FooterCustomizationOptions.ShowCustomAds
    ) {
      var refreshFlag = window.sessionStorage.getItem("RefreshFooterAd");
      if (!refreshFlag || refreshFlag !== window.location.href) {
        window.sessionStorage.setItem("RefreshFooterAd", window.location.href);

        var adObj = { ads: [] };
        adObj.ads.push({ adsize: getFooterAdId(), count: 1 });
        var self = this;
        setTimeout(() => {
          self.props.manageActions.fetchFrontEndAds("footerads", adObj);
        }, 2000);
      }

      if (
        JSON.stringify(this.props.pAdsList) !==
        JSON.stringify(nextProps.pAdsList)
      ) {
        var adsList = [].concat(
          JSON.stringify(this.props.pAdsList) !==
            JSON.stringify(nextProps.pAdsList)
            ? nextProps.pAdsList
            : []
        );
        adsList = adsList.filter(
          (a) => a.AdType !== "googletag" && !a.SkipEvent
        );

        if (adsList && adsList.length > 0) {
          this.props.manageActions.trackEvent("post_impress", {
            EventName: "post_impress",
            UserId: this.props.audience.Id,
            AdModel: adsList,
          });
        }
      }
    }
  }

  getCompanyLogoColumn = (item, idx, allItems, isSearch) => {
    if (item) {
      var lnks = [];
      if (item.Links) {
        item.Links.map((l, i) => {
          if (l.Image && l.Image !== "") {
            if (l.Url && l.Url !== "" && l.Url.indexOf("/") === 0) {
              if (l.Text && l.Text !== "") {
                lnks.push(
                  <span key={"cmplogo-" + i}>
                    <span className="footer-logo-text">{l.Text}</span>
                    <Link
                      alt="Footer Logo Link"
                      aria-label="Footer Logo Link"
                      to={this.props.purlPrefix.url + l.Url}
                    >
                      <img
                        src={l.Image}
                        className={l.Class || ""}
                        alt="Footer Logo"
                      />
                    </Link>
                  </span>
                );
              } else {
                lnks.push(
                  <Link
                    key={"cmplogo-" + i}
                    alt="Footer Logo Link"
                    aria-label="Footer Logo Link"
                    to={this.props.purlPrefix.url + l.Url}
                  >
                    <img
                      src={l.Image}
                      className={l.Class || ""}
                      alt="Footer Logo"
                    />
                  </Link>
                );
              }
            } else if (l.Url && l.Url !== "") {
              if (l.Text && l.Text !== "") {
                lnks.push(
                  <span key={"cmplogo-" + i}>
                    <span className="footer-logo-text">{l.Text}</span>
                    <a
                      alt="Logo Link"
                      aria-label="Logo Link"
                      href={l.Url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img src={l.Image} className={l.Class || ""} alt="Logo" />
                    </a>
                  </span>
                );
              } else {
                lnks.push(
                  <a
                    alt="Logo Link"
                    aria-label="Logo Link"
                    key={"cmplogo-" + i}
                    href={l.Url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={l.Image} className={l.Class || ""} alt="Logo" />
                  </a>
                );
              }
            } else {
              if (l.Text && l.Text !== "") {
                lnks.push(
                  <span>
                    <span className="footer-logo-text">{l.Text}</span>
                    <img
                      key={"cmplogo-" + i}
                      src={l.Image}
                      className={l.Class || ""}
                      alt="Logo"
                    />
                  </span>
                );
              } else {
                lnks.push(
                  <img
                    key={"cmplogo-" + i}
                    src={l.Image}
                    className={l.Class || ""}
                    alt="Logo"
                  />
                );
              }
            }
          }
          return null;
        });
      }

      if (isSearch) {
        var addrItem = allItems.filter((i) => i.Type === "address");
        if (addrItem && addrItem.length > 0) {
          addrItem = { ...addrItem[0] };
          addrItem = this.getAddressColumn(addrItem, idx, true);
        } else {
          addrItem = null;
        }

        return (
          <div
            key={"sr-fc-cmplogo-" + idx}
            className={item.SearchClass || "footer-column"}
          >
            <div className="footer-logo">{lnks}</div>
            {addrItem}
          </div>
        );
      }

      return (
        <div
          key={"fc-cmplogo-" + idx}
          className={item.Class || "col-md-2 col-xl-1 col-sm-12"}
        >
          <div className="footer-logo">{lnks}</div>
        </div>
      );
    }

    return null;
  };

  getAddressColumn = (item, idx, isSearch) => {
    if (item) {
      var lnks = [];
      if (item.Links) {
        item.Links.map((l, i) => {
          if (l.Text && l.Text !== "") {
            if (l.Type === "phone") {
              lnks.push(
                <span key={"addr-" + i} className="company_address">
                  <a
                    alt="Phone Link"
                    aria-label="Phone Link"
                    href={
                      "tel:" +
                      l.Text.replace(/ /g, "")
                        .replace(/\./g, "")
                        .replace(/-/g, "")
                        .replace(/\(/g, "")
                        .replace(/\)/g, "")
                    }
                  >
                    {l.Text}
                  </a>
                </span>
              );
            } else if (l.Type === "email") {
              lnks.push(
                <span key={"addr-" + i} className="company_address">
                  <a
                    alt="Email Link"
                    aria-label="Email Link"
                    href={"mailto:" + l.Text}
                  >
                    {l.Text}
                  </a>
                </span>
              );
            } else {
              lnks.push(
                <span key={"addr-" + i} className="company_address">
                  {l.Text}
                </span>
              );
            }
          }
          return null;
        });
      }

      if (isSearch) {
        return (
          <div
            key={"sr-fc-addr-" + idx}
            className={item.SearchClass || "footer-sub-column"}
          >
            <div className="footer-address">
              <div className="address-details">{lnks}</div>
            </div>
          </div>
        );
      }

      return (
        <div
          key={"fc-addr-" + idx}
          className={item.Class || "col-md-2 col-xl-2 col-sm-3 col-6"}
        >
          <div className="footer-address">
            <div className="address-details">{lnks}</div>
          </div>
        </div>
      );
    }
    return null;
  };

  getLinksColumn = (item, idx, isSearch) => {
    if (item) {
      var lnks = [];
      if (item.Links) {
        item.Links.map((l, i) => {
          if (l.Title && l.Title !== "") {
            if (l.Url && l.Url !== "" && l.Url.indexOf("/") === 0) {
              lnks.push(
                <li key={"lnks-" + i}>
                  <Link
                    alt={l.Title}
                    aria-label={l.Title}
                    to={this.props.purlPrefix.url + l.Url}
                  >
                    {l.Title}
                  </Link>
                </li>
              );
            } else if (l.Url && l.Url !== "") {
              lnks.push(
                <li key={"lnks-" + i}>
                  <a
                    alt={l.Title}
                    aria-label={l.Title}
                    href={l.Url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {l.Title}
                  </a>
                </li>
              );
            } else {
              lnks.push(<li key={"lnks-" + i}>{l.Title}</li>);
            }
          }
          return null;
        });
      }

      if (isSearch) {
        return (
          <div
            key={"sr-fc-lnks-" + idx}
            className={item.SearchClass || "footer-column"}
          >
            <div className="footer-nav-box">
              {item.Title && item.Title !== "" && (
                <span className="footer-nav-sm-title">{item.Title}</span>
              )}
              <nav className="footer-nav">
                <ul>{lnks}</ul>
              </nav>
            </div>
          </div>
        );
      }

      return (
        <div
          key={"fc-lnks-" + idx}
          className={item.Class || "col-md-2 col-xl-2 col-sm-3 col-6 col-xs-12"}
        >
          <div className="footer-nav-box">
            {item.Title && item.Title !== "" && (
              <span className="footer-nav-sm-title">{item.Title}</span>
            )}
            <nav className="footer-nav">
              <ul>{lnks}</ul>
            </nav>
          </div>
        </div>
      );
    }

    return null;
  };

  getExternalLogos = (item, idx, allItems, isSearch) => {
    if (item) {
      var lnks = [];
      if (item.Links) {
        item.Links.map((l, i) => {
          if (l.Image && l.Image !== "") {
            if (l.Url && l.Url !== "" && l.Url.indexOf("/") === 0) {
              if (l.Text && l.Text !== "") {
                lnks.push(
                  <span key={"extlogo-" + i} className="parter-logo">
                    <span className="parter-logo-text">{l.Text}</span>
                    <Link
                      alt="Partner Link"
                      aria-label="Partner Link"
                      to={this.props.purlPrefix.url + l.Url}
                    >
                      <img
                        src={l.Image}
                        className={l.Class || ""}
                        alt="Partner Logo"
                      />
                    </Link>
                  </span>
                );
              } else {
                lnks.push(
                  <span key={"extlogo-" + i} className="parter-logo">
                    <Link
                      alt="Partner Link"
                      aria-label="Partner Link"
                      to={this.props.purlPrefix.url + l.Url}
                    >
                      <img
                        src={l.Image}
                        className={l.Class || ""}
                        alt="Partner Logo"
                      />
                    </Link>
                  </span>
                );
              }
            } else if (l.Url && l.Url !== "") {
              if (l.Text && l.Text !== "") {
                lnks.push(
                  <span key={"extlogo-" + i} className="parter-logo">
                    <span className="parter-logo-text">{l.Text}</span>
                    <a
                      alt="Tlcengine Link"
                      href={l.Url}
                      aria-label="Tlcengine Link"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src={l.Image}
                        className={l.Class || ""}
                        alt="Tlcengine Logo"
                      />
                    </a>
                  </span>
                );
              } else {
                lnks.push(
                  <span key={"extlogo-" + i} className="parter-logo">
                    <a
                      alt="Tlcengine Link"
                      href={l.Url}
                      aria-label="Tlcengine Link"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src={l.Image}
                        className={l.Class || ""}
                        alt="Tlcengine Logo"
                      />
                    </a>
                  </span>
                );
              }
            } else {
              if (l.Text && l.Text !== "") {
                lnks.push(
                  <span key={"extlogo-" + i} className="parter-logo">
                    <span className="parter-logo-text">{l.Text}</span>
                    <img
                      src={l.Image}
                      className={l.Class || ""}
                      alt="Partner Logo"
                    />
                  </span>
                );
              } else {
                lnks.push(
                  <span key={"extlogo-" + i} className="parter-logo">
                    <img
                      src={l.Image}
                      className={l.Class || ""}
                      alt="Partner Logo"
                    />
                  </span>
                );
              }
            }
          }
          return null;
        });
      }

      var siItem = null;
      var siItems = null;
      if (item.SIClass && item.SIClass !== "") {
        siItem = allItems.filter((i) => i.Type === "socialicons");
        if (siItem && siItem.length > 0) {
          siItem = { ...siItem[0] };
          siItem.Class = item.SIClass;
          siItems = this.getSocialIconsColumn(siItem, idx);
        }
      }

      if (isSearch) {
        return (
          <div
            key={"sr-fc-extlogo-" + idx}
            className={item.SearchClass || "footer-column full-column"}
          >
            {lnks && lnks.length > 0 && (
              <div className="footer-parter">{lnks}</div>
            )}
          </div>
        );
      }

      return (
        <div
          key={"fc-extlogo-" + idx}
          className={item.Class || "col-md-2 col-xl-1 col-sm-12"}
        >
          {lnks && lnks.length > 0 && (
            <div className="footer-parter">{lnks}</div>
          )}
          {siItem && siItems}
        </div>
      );
    }

    return null;
  };

  getSocialIconsColumn = (item, idx, isSearch) => {
    const { classes } = this.props;

    if (item) {
      var lnks = [];
      if (item.Links) {
        item.Links.map((l, i) => {
          if (l.Type && l.Type !== "") {
            var tyline = null;
            if (l.Type === "blog") {
              tyline = <FontAwesomeIcon icon={faBloggerB} />;
            } else {
              tyline = <i className={"fa fa-" + l.Type}></i>;
            }
            if (l.Url && l.Url !== "") {
              lnks.push(
                <li key={"sicon-" + i}>
                  <a
                    alt={"Social-" + l.Type}
                    aria-label={"Social-" + l.Type}
                    href={l.Url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {tyline}
                  </a>
                </li>
              );
            } else {
              lnks.push(<li key={"sicon-" + i}>{tyline}</li>);
            }
          }
          return null;
        });
      }
      var imgs = [];
      if (item.Images) {
        item.Images.map((l, i) => {
          if (l.Image && l.Image !== "") {
            if (l.Url && l.Url !== "" && l.Url.indexOf("/") === 0) {
              imgs.push(
                <span key={"extimg-" + i} className="external-image">
                  <Link
                    alt="BlogsPot Link"
                    aria-label="BlogsPot Link"
                    to={this.props.purlPrefix.url + l.Url}
                  >
                    <img src={l.Image} className={l.Class || ""} alt="" />
                  </Link>
                </span>
              );
            } else if (l.Url && l.Url !== "") {
              imgs.push(
                <span key={"extimg-" + i} className="external-image">
                  <a
                    alt="BlogsPot Link"
                    aria-label="BlogsPot Link"
                    href={l.Url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={l.Image} className={l.Class || ""} alt="" />
                  </a>
                </span>
              );
            } else {
              imgs.push(
                <span key={"extimg-" + i} className="external-image">
                  <img src={l.Image} className={l.Class || ""} alt="" />
                </span>
              );
            }
          }
          return null;
        });
      }

      if (isSearch) {
        return (
          <div
            key={"sr-fc-sicon-" + idx}
            className={item.SearchClass || "footer-column full-column"}
          >
            {imgs && imgs.length > 0 && (
              <div className="external-images">{imgs}</div>
            )}
            <div
              className={
                classes.sociallink +
                " footer-social" +
                (imgs && imgs.length > 0 ? " footer-social-sm" : "") +
                (item.SubClass && item.SubClass !== ""
                  ? " " + item.SubClass
                  : "")
              }
            >
              <ul>{lnks}</ul>
            </div>
          </div>
        );
      }

      return (
        <div
          key={"fc-sicon-" + idx}
          className={
            item.Class ||
            "col-md-1 col-xl-2 col-sm-12 float-right d-sm-none d-md-none d-xl-block"
          }
        >
          {imgs && imgs.length > 0 && (
            <div className="external-images">{imgs}</div>
          )}
          <div
            className={
              classes.sociallink +
              " footer-social" +
              (imgs && imgs.length > 0 ? " footer-social-sm" : "") +
              (item.SubClass && item.SubClass !== "" ? " " + item.SubClass : "")
            }
          >
            <ul>{lnks}</ul>
          </div>
          {MobileAppOptions && MobileAppOptions.AppStores && (
            <div className="col-sm-12 text-center mg-tp-15">
              {MobileAppOptions.AppStores.android && (
                <div className="dp-inline mg-rt-7 mg-bt-8">
                  <a
                    alt="AndroidLogo"
                    aria-label="AndroidLogo"
                    href={`https://play.google.com/store/apps/details?id=${MobileAppOptions.AppStores.android}`}
                    target="_blank"
                  >
                    <img
                      className="ft-app-store-bt"
                      src={AndroidLogo}
                      alt="Android Store Logo"
                    />
                  </a>
                </div>
              )}
              {MobileAppOptions.AppStores.ios && (
                <div className="dp-inline mg-bt-8">
                  <a
                    alt="IOSLogo"
                    aria-label="IOSLogo"
                    href={`https://apps.apple.com/us/app/the-mike-delrose-team/id${MobileAppOptions.AppStores.ios}`}
                    target="_blank"
                  >
                    <img
                      className="ft-app-store-bt"
                      src={IOSLogo}
                      alt="iOS AppStore Logo"
                    />
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  getFormattedLinkData = (para) => {
    if (
      para &&
      para !== "" &&
      para.indexOf("ANCLINKSTART") >= 0 &&
      para.indexOf("ANCLINKEND") >= 0
    ) {
      var lcPara = para;
      var iSt = 0;
      var iEd = 0;
      var cnt = 1;
      var strArr = [];
      while (iSt !== -1) {
        iSt = lcPara.indexOf("ANCLINKSTART");
        iEd = lcPara.indexOf("ANCLINKEND");
        if (iSt >= 0 && iEd >= 0) {
          strArr.push(
            <span key={"txt-" + cnt}>{lcPara.substring(0, iSt)}</span>
          );
          strArr.push(
            this.getLinkElement(lcPara.substring(iSt + 12, iEd), cnt)
          );
          lcPara = lcPara.substring(iEd + 10, lcPara.length);
          cnt++;
        }
      }
      if (strArr && strArr.length > 0) {
        if (lcPara && lcPara !== "") {
          strArr.push(<span key={"txt-" + cnt}>{lcPara}</span>);
        }
        return strArr;
      }
    }
    return para;
  };

  getLinkElement = (ele, id) => {
    if (ele && ele !== "" && ele.indexOf("||") >= 0) {
      var arr = ele.split("||");
      if (
        arr &&
        arr.length === 2 &&
        arr[0] &&
        arr[0] !== "" &&
        arr[1] &&
        arr[1] !== ""
      ) {
        return (
          <a
            alt="Footer Link"
            aria-label="Footer Link"
            key={id}
            href={arr[0]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {arr[1]}
          </a>
        );
      }
    }
    return null;
  };

  getCopyRightText = () => {
    if (
      FooterCustomizationOptions &&
      FooterCustomizationOptions.CopyRightTextInFooter &&
      FooterCustomizationOptions.CopyRightTextInFooter !== ""
    ) {
      var crText = FooterCustomizationOptions.CopyRightTextInFooter;
      crText = crText.replace(/COPYRYEAR/g, new Date().getFullYear());
      var crArr = crText.split("COPYRSYMBOL");
      if (crArr && crArr.length > 0) {
        crText = [];
        crArr.map((s, i) => {
          if (s && s !== "") {
            crText.push(<span key={i}>{this.getFormattedLinkData(s)}</span>);
          }
          if (i !== crArr.length - 1) {
            crText.push(<span key={"sq_" + i}>&copy;</span>);
          }
          return null;
        });
      }

      return <span className="team-text">{crText}</span>;
    }
    return null;
  };

  getCopyRightSubText = () => {
    if (
      FooterCustomizationOptions &&
      FooterCustomizationOptions.CopyRightSubTextInFooter &&
      FooterCustomizationOptions.CopyRightSubTextInFooter !== ""
    ) {
      var crText = FooterCustomizationOptions.CopyRightSubTextInFooter;
      crText = crText.replace(/COPYRYEAR/g, new Date().getFullYear());
      var crArr = crText.split("COPYRSYMBOL");
      if (crArr && crArr.length > 0) {
        crText = [];
        crArr.map((s, i) => {
          if (s && s !== "") {
            crText.push(<span key={i}>{this.getFormattedLinkData(s)}</span>);
          }
          if (i !== crArr.length - 1) {
            crText.push(<span key={"sq_" + i}>&copy;</span>);
          }
          return null;
        });
      }

      return <span className="team-text">{crText}</span>;
    }
    return null;
  };

  getLicenseText = () => {
    if (
      FooterCustomizationOptions &&
      FooterCustomizationOptions.LicenseTextInFooter &&
      FooterCustomizationOptions.LicenseTextInFooter !== ""
    ) {
      return (
        <span className="team-text">
          {FooterCustomizationOptions.LicenseTextInFooter}
        </span>
      );
    }
    return null;
  };

  getPublishersNoticeText = () => {
    if (
      FooterCustomizationOptions &&
      FooterCustomizationOptions.PublishersNoticeInFooter &&
      FooterCustomizationOptions.PublishersNoticeInFooter !== ""
    ) {
      return (
        <span className="team-text">
          {FooterCustomizationOptions.PublishersNoticeInFooter}
        </span>
      );
    }
    return null;
  };

  render() {
    const { classes } = this.props;

    if (IsArkSite) {
      return <FooterArk showNotification={this.props.showNotification} />;
    }

    var fItems = [];
    if (
      FooterCustomizationOptions &&
      FooterCustomizationOptions.FooterColumnsList &&
      FooterCustomizationOptions.FooterColumnsList.length > 0
    ) {
      fItems = [].concat(FooterCustomizationOptions.FooterColumnsList);
    }

    var fColumns = [];
    var searchColumns = [];
    fItems.map((item, i) => {
      if (item && item.Type === "companylogo") {
        fColumns.push(this.getCompanyLogoColumn(item, i));
        searchColumns.push(this.getCompanyLogoColumn(item, i, fItems, true));
      } else if (item && item.Type === "address") {
        fColumns.push(this.getAddressColumn(item, i));
      } else if (item && item.Type === "links") {
        fColumns.push(this.getLinksColumn(item, i));
        searchColumns.push(this.getLinksColumn(item, i, true));
      } else if (item && item.Type === "externallogos") {
        fColumns.push(this.getExternalLogos(item, i, fItems));
        searchColumns.push(this.getExternalLogos(item, i, fItems, true));
      } else if (item && item.Type === "socialicons") {
        fColumns.push(this.getSocialIconsColumn(item, i));
        searchColumns.push(this.getSocialIconsColumn(item, i, true));
      }
      return null;
    });

    var fbgImgStyle = {};
    if (
      FooterCustomizationOptions &&
      FooterCustomizationOptions.BackgroundImageUrl &&
      FooterCustomizationOptions.BackgroundImageUrl !== ""
    ) {
      fbgImgStyle = {
        backgroundImage:
          "url(" + FooterCustomizationOptions.BackgroundImageUrl + ")",
      };
    }

    return (
      <div>
        <div className="footer">
          {FooterCustomizationOptions &&
            FooterCustomizationOptions.ShowCustomAds && (
              <div className="footer-banner">
                <div className="banner-ads">
                  <CommonAdComponent
                    adObj={
                      this.props.pAdsList && this.props.pAdsList.length > 0
                        ? this.props.pAdsList[0]
                        : null
                    }
                  />
                </div>
              </div>
            )}
          <div className={classes.footerbg + " footer-bg"}>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xl-12">
                <div className="row custom-row standard-footer">{fColumns}</div>
              </div>
            </div>
            <div className="footer-bg-image" style={fbgImgStyle}>
              &nbsp;
            </div>
            <div className="search-footer">{searchColumns}</div>
          </div>
          <Typography
            className={classes.teamcondition}
            component={"span"}
            variant="body2"
          >
            <div className="footer-team">
              {this.getCopyRightText()}
              {this.getCopyRightSubText()}
              {this.getLicenseText()}
              {this.getPublishersNoticeText()}
            </div>
          </Typography>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  footerbg: {
    background: theme.palette.footer.main,
    color: theme.palette.footer.text,
    "& a": {
      color: theme.palette.footer.linkcolor,
      "&:hover": {
        color: theme.palette.footer.linkhover,
      },
    },
    "& h4": {
      color: theme.palette.footer.text,
    },
    "& .footer-nav-sm-title": {
      color: theme.palette.footer.text,
    },
  },
  sociallink: {
    "& li": {
      "&:after": {
        borderColor: theme.palette.footer.linkcolor,
        borderWidth: 1,
        borderStyle: "solid",
      },
    },
    "& a": {
      color: theme.palette.footer.linkcolor,
      "&:hover": {
        color: theme.palette.footer.linkcolor,
      },
    },
  },
  teamcondition: {
    "& .footer-team": {
      background: theme.palette.footer.dark,
      color: theme.palette.footer.light,
      "& a": {
        color: theme.palette.footer.light,
        "&:hover": {
          color: theme.palette.footer.light,
        },
      },
    },
  },
});

const mapStateToProps = (state, props) => ({
  purlPrefix: state.session.purlPrefix || { url: "" },
  profile: state.profile.mine || {},
  isAnonymous: state.session.isAnonymous,
  audience: state.session.audience,
  pAdsList:
    state.manage.footerads && state.manage.footerads.ids
      ? state.manage.footerads.ids
      : [],
});

const mapDispatchToProps = (dispatch) => ({
  manageActions: bindActionCreators(manageActions, dispatch),
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer))
);

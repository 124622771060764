import React, { Component } from 'react';
import GLogo from 'img/nd/icon-google.png';
import { signInWithGoogle, signInWithFacebook, signInWithTwitter, signInWithGithub } from 'components/firebase';
import SocialLoginLinkedIn from 'components/socialloginlinkedin';
import SocialLoginAmazon from 'components/socialloginamazon';
import { GetSectionCustomizationOptions } from 'components/util';

const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");


class SocialLogins extends Component {    
    
    logInWithGoogle = () => {
        signInWithGoogle(this.props.callBackFun);
    }

    logInWithFacebook = () => {
        signInWithFacebook(this.props.callBackFun);
    }

    logInWithTwitter = () => {
        signInWithTwitter(this.props.callBackFun);
    }

    logInWithGithub = () => {
        signInWithGithub(this.props.callBackFun);
    }

    render () {

        return (
            <div className="login-social-wrapper">
                {!this.props.hideTitle ?
                (<div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="login-or">
                            <span className="middle-text large-text">{this.props.title || "OR Sign Up with"}</span>
                        </div>
                    </div>
                </div>) : null
                }
                <div className={this.props.hideTitle ? "login-social" : "login-social sm-social"}>
                    <span className="social-btn sm-btn btn-google" onClick={this.logInWithGoogle}>
                        <span><img src={GLogo} alt="Google" /></span>
                    </span>
                    <span className="social-btn sm-btn btn-face" onClick={this.logInWithFacebook}>
                        <span><i className="fa fa-facebook"></i></span>
                    </span>
                    <span className="social-btn sm-btn btn-twit" onClick={this.logInWithTwitter}>
                        <span><i className="fa fa-twitter"></i></span>
                    </span>
                    <span className="social-btn sm-btn btn-git" onClick={this.logInWithGithub}>
                        <span><i className="fa fa-github"></i></span>
                    </span>
                    {(CommonCustomizationOptions && CommonCustomizationOptions.SALinkedInCID && CommonCustomizationOptions.SALinkedInCID !== "") ? 
                        <SocialLoginLinkedIn callBackFun={this.props.callBackFun} LinkedInCID={CommonCustomizationOptions.SALinkedInCID} /> : null
                    }
                    {(CommonCustomizationOptions && CommonCustomizationOptions.SAAmazonCID && CommonCustomizationOptions.SAAmazonCID !== "") ? 
                        <SocialLoginAmazon callBackFun={this.props.callBackFun} AmazonCID={CommonCustomizationOptions.SAAmazonCID} /> : null
                    }
                </div>
            </div>
        );    
    }
}

export default SocialLogins;
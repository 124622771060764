import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, 
    GoogleAuthProvider, FacebookAuthProvider, 
    TwitterAuthProvider, GithubAuthProvider  } from 'firebase/auth';
import { GetSectionCustomizationOptions } from 'components/util';


const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");

const defaultFirebaseConfig = {
    apiKey: "AIzaSyCKzNu4bWGHZjyvl7pELEzn9WqJgQKdCjo",
    authDomain: "tlcengine-social-login.firebaseapp.com",
    projectId: "tlcengine-social-login",
    storageBucket: "tlcengine-social-login.appspot.com",
    messagingSenderId: "774806480236",
    appId: "1:774806480236:web:2468ce3fa2aa6d691e3f49"
};

// Initialize Firebase 
const app = initializeApp((CommonCustomizationOptions && CommonCustomizationOptions.FirebaseConfig) ? CommonCustomizationOptions.FirebaseConfig : defaultFirebaseConfig);

export const firebaseAuth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

const fbProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();
const ghProvider = new GithubAuthProvider();


export const signInWithGoogle = async (callBackFun) => { 
    try {

        const res = await signInWithPopup(firebaseAuth, googleProvider);
        //console.log('signInWithGoogle');
        //console.log(JSON.stringify(res));
        const user = res.user;

        var resUser = null;
        if(user) {
            resUser = {};
            if(user.uid && user.uid !== ""){
                resUser.SToken = user.uid;
            }
            if(user.providerData && user.providerData.length > 0 && user.providerData[0].uid && user.providerData[0].uid !== ""){
                resUser.SToken = user.providerData[0].uid;
            }
            if(user.email && user.email !== ""){
                resUser.Email = resUser.Firstname = user.email;
            }
            if(user.displayName && user.displayName !== "") {
                if(user.displayName.indexOf(" ") >= 0){
                    const nameArr = user.displayName.split(" ");
                    if(nameArr && nameArr.length > 1){
                        resUser.Firstname = nameArr[0];
                        resUser.LastName = nameArr[1];
                    }
                    else if(nameArr && nameArr.length > 0){
                        resUser.Firstname = nameArr[0];
                    }                     
                }
                else {
                    resUser.Firstname = user.displayName;
                }
            }
            else if(user.screenName && user.screenName !== "") {
                if(user.screenName.indexOf(" ") >= 0){
                    const nameArr = user.screenName.split(" ");
                    if(nameArr && nameArr.length > 1){
                        resUser.Firstname = nameArr[0];
                        resUser.LastName = nameArr[1];
                    }
                    else if(nameArr && nameArr.length > 0){
                        resUser.Firstname = nameArr[0];
                    }                    
                }
                else {
                    resUser.Firstname = user.screenName;
                }
            }
            if(res["_tokenResponse"]){
                if(res._tokenResponse.firstName && res._tokenResponse.firstName !== ""){
                    resUser.Firstname = res._tokenResponse.firstName;
                }
                if(res._tokenResponse.lastName && res._tokenResponse.lastName !== ""){
                    resUser.LastName = res._tokenResponse.lastName;
                }
            }
            if(!resUser.LastName || resUser.LastName === ""){
                resUser.LastName = resUser.Firstname;
            }
        }

        if(callBackFun && resUser){                        
            callBackFun(resUser);    
        }
        else if(callBackFun){
            callBackFun({Error: "Invalid account or credentials"});
        }       
    }
    catch(err) {
        console.log('Error in signInWithGoogle');
        console.log(JSON.stringify(err));
        
        if(err && err.code === "auth/account-exists-with-different-credential"){
            if(err.customData && err.customData._tokenResponse && err.customData._tokenResponse.email && 
                err.customData._tokenResponse.email !== "" &&
                ((err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== "") ||
                (err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== "")) && 
                err.customData._tokenResponse.rawUserInfo && err.customData._tokenResponse.rawUserInfo !== ""){
                var user = JSON.parse(err.customData._tokenResponse.rawUserInfo);
                if(user && user.id && user.id !== ""){
                    var objUser = {};
                    objUser.SToken = user.id;
                    objUser.Email = objUser.Firstname = err.customData._tokenResponse.email;                        
                    if(err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== "" &&
                        err.customData._tokenResponse.displayName.indexOf(" ") >= 0){
                        const nmArr = err.customData._tokenResponse.displayName.split(" ");                    
                        if(nmArr && nmArr.length > 1){
                            objUser.Firstname = nmArr[0];
                            objUser.LastName = nmArr[1];
                        }
                        else if(nmArr && nmArr.length > 0){
                            objUser.Firstname = nmArr[0];
                        }
                    }
                    else if(err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== "" &&
                        err.customData._tokenResponse.screenName.indexOf(" ") >= 0){
                        const nmArr = err.customData._tokenResponse.screenName.split(" ");                    
                        if(nmArr && nmArr.length > 1){
                            objUser.Firstname = nmArr[0];
                            objUser.LastName = nmArr[1];
                        }
                        else if(nmArr && nmArr.length > 0){
                            objUser.Firstname = nmArr[0];
                        }
                    }
                    else if(err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== ""){
                        objUser.Firstname = err.customData._tokenResponse.displayName;
                    }
                    else if(err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== ""){
                        objUser.Firstname = err.customData._tokenResponse.screenName;
                    }
                    if(!objUser.LastName || objUser.LastName === ""){
                        objUser.LastName = objUser.Firstname;
                    }
                    if(callBackFun){
                        callBackFun(objUser);
                    }
                }
            }                        
        }
        else if(!err || (err.code !== "auth/popup-closed-by-user" && err.code !== "auth/user-cancelled" && err.code !== "auth/cancelled-popup-request")){
            if(callBackFun){
                callBackFun({Error: "Invalid account or credentials"});
            }
        }
    }
}

export const signInWithFacebook = async (callBackFun) => { 
    try {

        const res = await signInWithPopup(firebaseAuth, fbProvider);
        //console.log('signInWithFacebook');
        //console.log(JSON.stringify(res));
        const user = res.user;

        var resUser = null;
        if(user) {
            resUser = {};
            if(user.uid && user.uid !== ""){
                resUser.SToken = user.uid;
            }
            if(user.providerData && user.providerData.length > 0 && user.providerData[0].uid && user.providerData[0].uid !== ""){
                resUser.SToken = user.providerData[0].uid;
            }
            if(user.email && user.email !== ""){
                resUser.Email = resUser.Firstname = user.email;
            }            
            if(user.displayName && user.displayName !== "") {
                if(user.displayName.indexOf(" ") >= 0){
                    const nameArr = user.displayName.split(" ");
                    if(nameArr && nameArr.length > 1){
                        resUser.Firstname = nameArr[0];
                        resUser.LastName = nameArr[1];
                    }
                    else if(nameArr && nameArr.length > 0){
                        resUser.Firstname = nameArr[0];
                    }                    
                }
                else {
                    resUser.Firstname = user.displayName;
                }
            }
            else if(user.screenName && user.screenName !== "") {
                if(user.screenName.indexOf(" ") >= 0){
                    const nameArr = user.screenName.split(" ");
                    if(nameArr && nameArr.length > 1){
                        resUser.Firstname = nameArr[0];
                        resUser.LastName = nameArr[1];
                    }
                    else if(nameArr && nameArr.length > 0){
                        resUser.Firstname = nameArr[0];
                    }                    
                }
                else {
                    resUser.Firstname = user.screenName;
                }
            }
            if(res["_tokenResponse"]){
                if(res._tokenResponse.firstName && res._tokenResponse.firstName !== ""){
                    resUser.Firstname = res._tokenResponse.firstName;
                }
                if(res._tokenResponse.lastName && res._tokenResponse.lastName !== ""){
                    resUser.LastName = res._tokenResponse.lastName;
                }
            }
            if(!resUser.LastName || resUser.LastName === ""){
                resUser.LastName = resUser.Firstname;
            }            
        }

        if(callBackFun && resUser){                        
            callBackFun(resUser);    
        }
        else if(callBackFun){
            callBackFun({Error: "Invalid account or credentials"});
        }       
    }
    catch(err) {
        console.log('Error in signInWithFacebook');
        console.log(JSON.stringify(err));
        
        if(err && err.code === "auth/account-exists-with-different-credential"){
            if(err.customData && err.customData._tokenResponse && err.customData._tokenResponse.email && 
                err.customData._tokenResponse.email !== "" &&
                ((err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== "") ||
                (err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== "")) && 
                err.customData._tokenResponse.rawUserInfo && err.customData._tokenResponse.rawUserInfo !== ""){
                var user = JSON.parse(err.customData._tokenResponse.rawUserInfo);
                if(user && user.id && user.id !== ""){
                    var objUser = {};
                    objUser.SToken = user.id;
                    objUser.Email = objUser.Firstname = err.customData._tokenResponse.email;                        
                    if(err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== "" &&
                        err.customData._tokenResponse.displayName.indexOf(" ") >= 0){
                        const nmArr = err.customData._tokenResponse.displayName.split(" ");                    
                        if(nmArr && nmArr.length > 1){
                            objUser.Firstname = nmArr[0];
                            objUser.LastName = nmArr[1];
                        }
                        else if(nmArr && nmArr.length > 0){
                            objUser.Firstname = nmArr[0];
                        }
                    }
                    else if(err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== "" &&
                        err.customData._tokenResponse.screenName.indexOf(" ") >= 0){
                        const nmArr = err.customData._tokenResponse.screenName.split(" ");                    
                        if(nmArr && nmArr.length > 1){
                            objUser.Firstname = nmArr[0];
                            objUser.LastName = nmArr[1];
                        }
                        else if(nmArr && nmArr.length > 0){
                            objUser.Firstname = nmArr[0];
                        }
                    }
                    else if(err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== ""){
                        objUser.Firstname = err.customData._tokenResponse.displayName;
                    }
                    else if(err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== ""){
                        objUser.Firstname = err.customData._tokenResponse.screenName;
                    }
                    if(!objUser.LastName || objUser.LastName === ""){
                        objUser.LastName = objUser.Firstname;
                    }
                    if(callBackFun){
                        callBackFun(objUser);
                    }
                }
            }                        
        }
        else if(!err || (err.code !== "auth/popup-closed-by-user" && err.code !== "auth/user-cancelled" && err.code !== "auth/cancelled-popup-request")){
            if(callBackFun){
                callBackFun({Error: "Invalid account or credentials"});
            }
        }
    }
}

export const signInWithTwitter = async (callBackFun) => { 
    try {

        const res = await signInWithPopup(firebaseAuth, twitterProvider);
        //console.log('signInWithTwitter');
        //console.log(JSON.stringify(res));
        const user = res.user;

        var resUser = null;
        if(user) {
            resUser = {};
            if(user.uid && user.uid !== ""){
                resUser.SToken = user.uid;
            }
            if(user.providerData && user.providerData.length > 0 && user.providerData[0].uid && user.providerData[0].uid !== ""){
                resUser.SToken = user.providerData[0].uid;
            }
            if(user.email && user.email !== ""){
                resUser.Email = resUser.Firstname = user.email;
            }            
            if(user.displayName && user.displayName !== "") {
                if(user.displayName.indexOf(" ") >= 0){
                    const nameArr = user.displayName.split(" ");                    
                    if(nameArr && nameArr.length > 1){
                        resUser.Firstname = nameArr[0];
                        resUser.LastName = nameArr[1];
                    }
                    else if(nameArr && nameArr.length > 0){
                        resUser.Firstname = nameArr[0];
                    }
                }
                else {
                    resUser.Firstname = user.displayName;
                }
            }
            else if(user.screenName && user.screenName !== "") {
                if(user.screenName.indexOf(" ") >= 0){
                    const nameArr = user.screenName.split(" ");
                    if(nameArr && nameArr.length > 1){
                        resUser.Firstname = nameArr[0];
                        resUser.LastName = nameArr[1];
                    }
                    else if(nameArr && nameArr.length > 0){
                        resUser.Firstname = nameArr[0];
                    }                    
                }
                else {
                    resUser.Firstname = user.screenName;
                }
            }
            if(res["_tokenResponse"]){
                if(res._tokenResponse.firstName && res._tokenResponse.firstName !== ""){
                    resUser.Firstname = res._tokenResponse.firstName;
                }
                if(res._tokenResponse.lastName && res._tokenResponse.lastName !== ""){
                    resUser.LastName = res._tokenResponse.lastName;
                }
            }
            if(!resUser.LastName || resUser.LastName === ""){
                resUser.LastName = resUser.Firstname;
            }
        }

        if(callBackFun && resUser){                        
            callBackFun(resUser);    
        }
        else if(callBackFun){
            callBackFun({Error: "Invalid account or credentials"});
        }        
    }
    catch(err) {
        console.log('Error in signInWithTwitter');
        console.log(JSON.stringify(err));

        if(err && err.code === "auth/account-exists-with-different-credential"){
            if(err.customData && err.customData._tokenResponse && err.customData._tokenResponse.email && 
                err.customData._tokenResponse.email !== "" &&
                ((err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== "") ||
                (err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== "")) && 
                err.customData._tokenResponse.rawUserInfo && err.customData._tokenResponse.rawUserInfo !== ""){
                var user = JSON.parse(err.customData._tokenResponse.rawUserInfo);
                if(user && user.id && user.id !== ""){
                    var objUser = {};
                    objUser.SToken = user.id;
                    objUser.Email = objUser.Firstname = err.customData._tokenResponse.email;                        
                    if(err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== "" &&
                        err.customData._tokenResponse.displayName.indexOf(" ") >= 0){
                        const nmArr = err.customData._tokenResponse.displayName.split(" ");                    
                        if(nmArr && nmArr.length > 1){
                            objUser.Firstname = nmArr[0];
                            objUser.LastName = nmArr[1];
                        }
                        else if(nmArr && nmArr.length > 0){
                            objUser.Firstname = nmArr[0];
                        }
                    }
                    else if(err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== "" &&
                        err.customData._tokenResponse.screenName.indexOf(" ") >= 0){
                        const nmArr = err.customData._tokenResponse.screenName.split(" ");                    
                        if(nmArr && nmArr.length > 1){
                            objUser.Firstname = nmArr[0];
                            objUser.LastName = nmArr[1];
                        }
                        else if(nmArr && nmArr.length > 0){
                            objUser.Firstname = nmArr[0];
                        }
                    }
                    else if(err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== ""){
                        objUser.Firstname = err.customData._tokenResponse.displayName;
                    }
                    else if(err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== ""){
                        objUser.Firstname = err.customData._tokenResponse.screenName;
                    }
                    if(!objUser.LastName || objUser.LastName === ""){
                        objUser.LastName = objUser.Firstname;
                    }
                    if(callBackFun){
                        callBackFun(objUser);
                    }
                }
            }                        
        }
        else if(!err || (err.code !== "auth/popup-closed-by-user" && err.code !== "auth/user-cancelled" && err.code !== "auth/cancelled-popup-request")){
            if(callBackFun){
                callBackFun({Error: "Invalid account or credentials"});
            }
        }
    }
}

export const signInWithGithub = async (callBackFun) => { 
    try {

        const res = await signInWithPopup(firebaseAuth, ghProvider);
        //console.log('signInWithGithub');
        //console.log(JSON.stringify(res));
        const user = res.user;

        var resUser = null;
        if(user) {
            resUser = {};
            if(user.uid && user.uid !== ""){
                resUser.SToken = user.uid;
            }
            if(user.providerData && user.providerData.length > 0 && user.providerData[0].uid && user.providerData[0].uid !== ""){
                resUser.SToken = user.providerData[0].uid;
            }
            if(user.email && user.email !== ""){
                resUser.Email = resUser.Firstname = user.email;
            }            
            if(user.displayName && user.displayName !== "") {
                if(user.displayName.indexOf(" ") >= 0){
                    const nameArr = user.displayName.split(" ");
                    if(nameArr && nameArr.length > 1){
                        resUser.Firstname = nameArr[0];
                        resUser.LastName = nameArr[1];
                    }
                    else if(nameArr && nameArr.length > 0){
                        resUser.Firstname = nameArr[0];
                    }                    
                }
                else {
                    resUser.Firstname = user.displayName;
                }
            }
            else if(user.screenName && user.screenName !== "") {
                if(user.screenName.indexOf(" ") >= 0){
                    const nameArr = user.screenName.split(" ");
                    if(nameArr && nameArr.length > 1){
                        resUser.Firstname = nameArr[0];
                        resUser.LastName = nameArr[1];
                    }
                    else if(nameArr && nameArr.length > 0){
                        resUser.Firstname = nameArr[0];
                    }                    
                }
                else {
                    resUser.Firstname = user.screenName;
                }
            }
            if(res["_tokenResponse"]){
                if(res._tokenResponse.firstName && res._tokenResponse.firstName !== ""){
                    resUser.Firstname = res._tokenResponse.firstName;
                }
                if(res._tokenResponse.lastName && res._tokenResponse.lastName !== ""){
                    resUser.LastName = res._tokenResponse.lastName;
                }
            }
            if(!resUser.LastName || resUser.LastName === ""){
                resUser.LastName = resUser.Firstname;
            }
        }

        if(callBackFun && resUser){                        
            callBackFun(resUser);    
        }
        else if(callBackFun){
            callBackFun({Error: "Invalid account or credentials"});
        }        
    }
    catch(err) {
        console.log('Error in signInWithGithub');
        console.log(JSON.stringify(err));
        
        if(err && err.code === "auth/account-exists-with-different-credential"){
            if(err.customData && err.customData._tokenResponse && err.customData._tokenResponse.email && 
                err.customData._tokenResponse.email !== "" &&
                ((err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== "") ||
                (err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== "")) && 
                err.customData._tokenResponse.rawUserInfo && err.customData._tokenResponse.rawUserInfo !== ""){
                var user = JSON.parse(err.customData._tokenResponse.rawUserInfo);
                if(user && user.id && user.id !== ""){
                    var objUser = {};
                    objUser.SToken = user.id;
                    objUser.Email = objUser.Firstname = err.customData._tokenResponse.email;                        
                    if(err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== "" &&
                        err.customData._tokenResponse.displayName.indexOf(" ") >= 0){
                        const nmArr = err.customData._tokenResponse.displayName.split(" ");                    
                        if(nmArr && nmArr.length > 1){
                            objUser.Firstname = nmArr[0];
                            objUser.LastName = nmArr[1];
                        }
                        else if(nmArr && nmArr.length > 0){
                            objUser.Firstname = nmArr[0];
                        }
                    }
                    else if(err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== "" &&
                        err.customData._tokenResponse.screenName.indexOf(" ") >= 0){
                        const nmArr = err.customData._tokenResponse.screenName.split(" ");                    
                        if(nmArr && nmArr.length > 1){
                            objUser.Firstname = nmArr[0];
                            objUser.LastName = nmArr[1];
                        }
                        else if(nmArr && nmArr.length > 0){
                            objUser.Firstname = nmArr[0];
                        }
                    }
                    else if(err.customData._tokenResponse.displayName && err.customData._tokenResponse.displayName !== ""){
                        objUser.Firstname = err.customData._tokenResponse.displayName;
                    }
                    else if(err.customData._tokenResponse.screenName && err.customData._tokenResponse.screenName !== ""){
                        objUser.Firstname = err.customData._tokenResponse.screenName;
                    }
                    if(!objUser.LastName || objUser.LastName === ""){
                        objUser.LastName = objUser.Firstname;
                    }
                    if(callBackFun){
                        callBackFun(objUser);
                    }
                }
            }                        
        }
        else if(!err || (err.code !== "auth/popup-closed-by-user" && err.code !== "auth/user-cancelled" && err.code !== "auth/cancelled-popup-request")){
            if(callBackFun){
                callBackFun({Error: "Invalid account or credentials"});
            }
        }
    }
}

import React from 'react';
import { GetSectionCustomizationOptions } from '../components/util';

const ThemeCustomizationOptions =
  GetSectionCustomizationOptions("ThemeOptions");

function ComingSoon () {
    return <div style={{
        height: '100vh',
        background: ThemeCustomizationOptions.PrimaryMainColor,
        display: 'flex',
        flexDirection:"column",
        justifyContent: 'center',
        alignItems: 'center',
        color:"white"
    }}>
        <img src={process.env.REACT_APP_WebsiteLogo} alt="Footer Logo" style={{
           width:"50vw"
        }} />
        <br /><br />
        
        <p style={{
            fontSize: 40,
        }}>Coming Soon!</p>
    </div>
}

export default ComingSoon;
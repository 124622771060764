import {
  SETTING_REQUEST,
  SETTING_SUCCESS,
  SETTING_FAILURE,
  AUTH_MECH_REQUEST,
  AUTH_MECH_SUCCESS,
  AUTH_MECH_FAILURE,
  PRINTPIECE_REQUEST,
  PRINTPIECE_SUCCESS,
  PRINTPIECE_FAILURE,
  BASEDATA_REQUEST,
  BASEDATA_SUCCESS,
  BASEDATA_FAILURE,
  FNEIGHBORHOODS_REQUEST,
  FNEIGHBORHOODS_SUCCESS,
  FNEIGHBORHOODS_FAILURE,
  FNEIGHBORHOODD_REQUEST,
  FNEIGHBORHOODD_SUCCESS,
  FNEIGHBORHOODD_FAILURE,
  VEHICLES_REQUEST,
  VEHICLES_SUCCESS,
  VEHICLES_FAILURE,
  MLS_LIST_REQUEST,
  MLS_LIST_SUCCESS,
  MLS_LIST_FAILURE,
} from "actions/settings";

export default (
  state = {
    isFetching: false,
    data: [],
    error: undefined,
    notificationMessage: undefined,
    isAuthMechFetching: false,
    printPieceUrl: "",
    featuredNeighborhoods: [],
    featuredNeighborhoodDetail: {},
    isFNLFetching: false,
    isFNDFetching: false,
    vehicles: [],
    isVehiclesFetching: false,
    isMLSListFetching: false,
    MLSList: [],
  },
  action
) => {
  switch (action.type) {
    case SETTING_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
        notificationMessage: undefined,
      };
    case SETTING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data:
          action.response.Settings && action.response.Settings.length > 0
            ? Object.assign(
                ...action.response.Settings.map((setting) => ({
                  [setting["SettingTitle"]]: setting["Value"],
                }))
              )
            : [],
        error: undefined,
      };
    case SETTING_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case AUTH_MECH_REQUEST:
      return {
        ...state,
        isAuthMechFetching: true,
        isSSOLoggingOut: false,
      };
    case AUTH_MECH_SUCCESS:
      const res = action.response;
      if (
        res &&
        res.AuthenticationMechanism &&
        res.AuthenticationMechanism.length > 0 &&
        res.AuthenticationMechanism[0] &&
        res.AuthenticationMechanism[0].AuthenticationMechanism &&
        res.AuthenticationMechanism[0].AuthenticationMechanism !== "" &&
        res.AuthenticationMechanism[0].AuthenticationMechanism.toLowerCase() ===
          "sso-saml" &&
        res.AuthenticationMechanism[0].AuthDetails &&
        res.AuthenticationMechanism[0].AuthDetails.Idp &&
        res.AuthenticationMechanism[0].AuthDetails.Idp !== "" &&
        res.AuthenticationMechanism[0].AuthDetails.SAMLRequest &&
        res.AuthenticationMechanism[0].AuthDetails.SAMLRequest !== ""
      ) {
        window.sessionStorage.setItem(
          "AuthMechanism",
          JSON.stringify(res.AuthenticationMechanism[0].AuthDetails)
        );
      } else {
        window.sessionStorage.removeItem("AuthMechanism");
      }

      return {
        ...state,
        isAuthMechFetching: false,
        isSSOLoggingOut: false,
      };
    case AUTH_MECH_FAILURE:
      return {
        ...state,
        isAuthMechFetching: false,
        isSSOLoggingOut: false,
      };
    case PRINTPIECE_REQUEST:
      return {
        ...state,
        error: undefined,
        notificationMessage: undefined,
      };
    case PRINTPIECE_SUCCESS:
      return {
        ...state,
        printPieceUrl: action.response,
        error: undefined,
      };
    case PRINTPIECE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case BASEDATA_REQUEST:
      return {
        ...state,
        error: undefined,
        notificationMessage: undefined,
      };
    case BASEDATA_SUCCESS:
      var st = {
        ...state,
        error: undefined,
      };

      if (action.key && action.response) {
        st[action.key] = action.response;
      }

      return st;

    case BASEDATA_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case FNEIGHBORHOODS_REQUEST:
      return {
        ...state,
        isFNLFetching: true,
        error: undefined,
        notificationMessage: undefined,
      };
    case FNEIGHBORHOODS_SUCCESS:
      return {
        ...state,
        isFNLFetching: false,
        featuredNeighborhoods: action.response,
        error: undefined,
      };
    case FNEIGHBORHOODS_FAILURE:
      return {
        ...state,
        isFNLFetching: false,
        error: action.error,
      };
    case FNEIGHBORHOODD_REQUEST:
      return {
        ...state,
        isFNDFetching: true,
        error: undefined,
        notificationMessage: undefined,
      };
    case FNEIGHBORHOODD_SUCCESS:
      return {
        ...state,
        isFNDFetching: false,
        featuredNeighborhoodDetail: action.response,
        error: undefined,
      };
    case FNEIGHBORHOODD_FAILURE:
      return {
        ...state,
        isFNDFetching: false,
        error: action.error,
      };
    case VEHICLES_REQUEST:
      return {
        ...state,
        isVehiclesFetching: true,
        error: undefined,
        notificationMessage: undefined,
      };
    case VEHICLES_SUCCESS:
      return {
        ...state,
        isVehiclesFetching: false,
        vehicles: action.response,
        error: undefined,
      };
    case VEHICLES_FAILURE:
      return {
        ...state,
        isVehiclesFetching: false,
        error: action.error,
      };
    case MLS_LIST_REQUEST:
      return {
        ...state,
        isMLSListFetching: true,
        error: undefined,
      };
    case MLS_LIST_SUCCESS:
      return {
        ...state,
        isMLSListFetching: false,
        error: undefined,
        MLSList: action.response,
      };
    case MLS_LIST_FAILURE:
      return {
        ...state,
        isMLSListFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

import { isStaticRendering } from 'react-static/staticRenderState';
import { getGUID } from 'components/util';


export default function setupGlobalVariables() {

    // TODO - Make this data available on server side as needed.
    if (isStaticRendering) {
        return;
    }

    // Use SessionStorage for now but we will want to tie this to users settings
    window.sessionStorage.setItem("APIKey", "");
    window.sessionStorage.setItem("SoundScoreKey", "IYzwQFXCXqlSirWy");
    window.sessionStorage.setItem("WalkScoreKey", "d654eda8614498e8685981ae8320ca2c");
    window.sessionStorage.setItem("MLSKey", "true");
    window.sessionStorage.setItem('APIAudience', 'agents/');

    // Check for Non Expired Auth Token Env Variable to be used for Anonymous
    if (process.env.REACT_APP_NonExpiredAuthToken && process.env.REACT_APP_NonExpiredAuthToken !== ""){
        window.sessionStorage.setItem("APIKey", process.env.REACT_APP_NonExpiredAuthToken);
    }

    // Check for BaseAPI Env Variable
    if (process.env.REACT_APP_BaseAPI !== undefined){
        window.sessionStorage.setItem("BaseAPI", process.env.REACT_APP_BaseAPI);
    }
    else {
        window.sessionStorage.setItem("BaseAPI", "https://uat-api.truebytlc.com/v3/api/");
    }
    // Check for Portal Key Env Variable
    if (process.env.REACT_APP_PortalKey && process.env.REACT_APP_PortalKey !== ""){
        window.sessionStorage.setItem("RootAPI", window.sessionStorage.getItem("BaseAPI") + process.env.REACT_APP_PortalKey + "/");
        window.sessionStorage.setItem("MLSKey", process.env.REACT_APP_PortalKey);
    }
    else if (process.env.REACT_APP_MLSKey && process.env.REACT_APP_MLSKey !== ""){
        window.sessionStorage.setItem("RootAPI", window.sessionStorage.getItem("BaseAPI") + process.env.REACT_APP_MLSKey + "/");
        window.sessionStorage.setItem("MLSKey", process.env.REACT_APP_MLSKey);
    }
    else {
        window.sessionStorage.setItem("RootAPI", window.sessionStorage.getItem("BaseAPI") + "true/");
    }

    // Generate Device ID, if not already done
    window.localStorage.removeItem("DeviceIDs");
    window.localStorage.removeItem("DeviceID");
    var DeviceUID = window.localStorage.getItem("DeviceUID");
    if(!DeviceUID || DeviceUID === ""){
        window.localStorage.setItem("DeviceUID", getGUID());
    }

}

import React, { useState } from "react";
import {
  GetSectionCustomizationOptions,
  getFormattedValueForReactSelect,
  getFormattedOptionsForReactSelect,
} from "components/util";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProfilePhoto from "img/profile_photo.jpg";
import { DropdownButton, Modal } from "react-bootstrap";
import SelectClient, { SelectedClientName } from "components/selectclient";
import QuickBar from "components/quickbar";
import base64 from "base-64";
import utf8 from "utf8";
import ClientEULAText from "components/clienteulatext";
import Dialog from "react-bootstrap-dialog";
import Cookies from "universal-cookie";
import NavbarLinksBuilder from "components/navbarlinksbuilder";
import NavbarSubLinksBuilder from "components/navbarsublinksbuilder";
import { getProfilePhoto } from "containers/AgentOfficePage";
import { NavLink } from "react-router-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import Logo from "img/nd/bflogo.png";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import DirectChatPopup from "components/directchat/directchatpopup";
import { orderBy } from "lodash";
import * as sessionActions from "actions/session";
import * as settingActions from "actions/settings";
import * as profileActions from "actions/profile";
import * as clientActions from "actions/clients";
import * as listingActions from "actions/listings";
import * as activityActions from "actions/activity";
import * as agentActions from "actions/agents";
import * as chatActions from "actions/chat";
import "scss/_navbarND.scss";
import { Loader } from "react-bootstrap-typeahead";

const CommonCustomizationOptions =
  GetSectionCustomizationOptions("CommonOptions");

const SignupModal = ({ isBudgetModal, ...props }) => {
  const { classes, purlPrefix } = props;

  const [state, setState] = useState({
    agentVerificationData: {},
  });

  const getLogo = () => {
    var siteLogo = Logo;
    if (
      process.env.REACT_APP_WebsiteLogo !== undefined &&
      process.env.REACT_APP_WebsiteLogo !== null &&
      process.env.REACT_APP_WebsiteLogo !== ""
    ) {
      siteLogo = process.env.REACT_APP_WebsiteLogo;
    }

    var logoLink = "/";
    if (purlPrefix && purlPrefix.url && purlPrefix.url !== "") {
      logoLink = purlPrefix.url;
    }

    return (
      <div className="center-logo">
        <Navbar.Brand
          href={logoLink}
          alt="Navbar Logo"
          aria-label="Navbar Logo"
          className="top-menu-logo">
          <img src={siteLogo} alt="Navbar Logo" />
        </Navbar.Brand>
      </div>
    );
  };

  const handleSSOButtonClick = ({ audiencetype, action }) => {
    const { history, purlPrefix } = props;

    window.sessionStorage.setItem("ComingForExSSO", true);
    if (action === "login") {
      if (isBudgetModal == true) {
        if (
          CommonCustomizationOptions &&
          CommonCustomizationOptions.MyBudgetURL &&
          CommonCustomizationOptions.MyBudgetURL !== ""
        ) {
          var url = base64.encode(
            utf8.encode(CommonCustomizationOptions.MyBudgetURL)
          );
          window.sessionStorage.setItem("ExSSORedirectLocation", url);
        }
      } else {
        var url = base64.encode(utf8.encode(window.location.href));
        window.sessionStorage.setItem("ExSSORedirectLocation", url);
      }
    } else if (action === "signup") {
      var url = base64.encode(utf8.encode(window.location.href));
      window.sessionStorage.setItem("ExSSORedirectLocation", url);
      // if (
      //   CommonCustomizationOptions &&
      //   CommonCustomizationOptions.MyBudgetURL &&
      //   CommonCustomizationOptions.MyBudgetURL !== ""
      // ) {
      //   var url = base64.encode(
      //     utf8.encode(CommonCustomizationOptions.MyBudgetURL)
      //   );
      //   window.sessionStorage.setItem("ExSSORedirectLocation", url);
      // }
    }
    history.push(
      `${purlPrefix.url}/exsso?audiencetype=${audiencetype}&action=${action}`
    );
    return true;
  };

  //AGENT VERIFICATION//
  var _MLSList = [];
  var showEmail = false;
  var showMLSList = false;
  if (
    CommonCustomizationOptions &&
    CommonCustomizationOptions.ShowMLSList &&
    CommonCustomizationOptions.ShowMLSList == "true"
  ) {
    showMLSList = true;
  }
  if (showMLSList && !props.isMLSListFetching) {
    if (props.MLSList) {
      _MLSList = props.MLSList;
    }
  }

  const getLicenseLookupURL = (MLSkey) => {
    var url = null;
    _MLSList.forEach((item) => {
      if (item.value === MLSkey) url = item.LicenseNumberLookupURL;
    });

    return url;
  };

  const handleInputChange = (event) => {
    //this.setState({ errorMessage: "" });
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name == "MLSKey") {
      var _data = {
        MLSKey: value,
      };

      setState((prev) => {
        if (
          CommonCustomizationOptions &&
          CommonCustomizationOptions.ShowEmailForAgentVerification &&
          CommonCustomizationOptions.ShowEmailForAgentVerification.indexOf(
            value
          ) < 0
        ) {
          delete prev.agentVerificationData["Email"];
        }

        return {
          ...prev,
          agentVerificationData: { ...prev.agentVerificationData, ..._data },
        };
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          agentVerificationData: {
            ...state.agentVerificationData,
            [name]: value,
          },
        };
      });
    }
  };

  const handleAgentVerification = () => {
    var url =
      window.sessionStorage.getItem("RootAPI") + "agents/verifyagentprofile";

    var body = Object.assign({}, state.agentVerificationData);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.sessionStorage.getItem("APIKey"),
      },
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((response) => (response.ok ? response.text() : response.json()))
      .then((data) => {
        setState((prev) => {
          return {
            ...prev,
            isVerifying: false,
          };
        });
        const isVerified = data == "true";
        if (isVerified) {
          handleSSOButtonClick({
            audiencetype: "agent",
            action: "signup",
          });
        } else {
          setState((prev) => {
            return {
              ...prev,
              agentVerificationData: {
                ...state.agentVerificationData,
                error: "Unable to Verify. Please check your details",
              },
            };
          });
        }
      })
      .catch(function (data) {
        setState((prev) => {
          return {
            ...prev,
            isVerifying: false,
          };
        });
        console.log(data);
      });
  };
  //AGENT VERIFICATION//

  if (
    CommonCustomizationOptions &&
    CommonCustomizationOptions.UseSignupModal &&
    CommonCustomizationOptions.UseSignupModal == "true"
  ) {
    return (
      <span>
        <Modal
          show={props.modal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => props.hideModal()}>
            <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <div className="mb-3">{getLogo()}</div>
            </center>
            <Button
              variant="contained"
              fullWidth={true}
              className={
                classes.typographybutton + " continue-button theme-btn mb-4"
              }
              onClick={() =>
                handleSSOButtonClick({
                  audiencetype: "client",
                  action: "signup",
                })
              }>
              Sign Up
            </Button>
            <Button
              variant="contained"
              fullWidth={true}
              className={
                classes.typographybutton + " continue-button theme-btn mb-4"
              }
              onClick={() => {
                props.hideModal();
                props.showAgentVerificationModal();
              }}>
              I'm a real estate agent
            </Button>

            <Typography
              className={classes.nolink}
              component={"span"}
              variant="body2">
              <span>Already have an account?</span>
            </Typography>
            <Typography
              className={classes.typographylink + " pd-lt-5"}
              component={"span"}
              variant="body2">
              <Link
                to={`${
                  purlPrefix && purlPrefix.url ? purlPrefix.url : ""
                }/exsso?audiencetype=client&action=login`}
                onClick={() =>
                  handleSSOButtonClick({
                    audiencetype: "client",
                    action: "login",
                  })
                }
                alt="Login Link"
                aria-label="Login Link">
                Login
              </Link>
            </Typography>
            <Typography
              className={classes.typographylink + " accept-signing-cnt"}
              component={"span"}
              variant="body2">
              <div>
                By signing up, I accept Ark Home's{" "}
                <Link
                  alt="Terms of use Link"
                  aria-label="Terms of use Link"
                  to={"/terms"}>
                  Terms of use.
                </Link>
              </div>
            </Typography>
            <Typography
              className={classes.typographylink + " accept-signing-cnt"}
              component={"span"}
              variant="body2">
              <div>
                Having trouble signing up?
                <Link
                  alt="Contact Us"
                  aria-label="Contact Us"
                  to={"/contactus"}>
                  Contact Us
                </Link>
              </div>
            </Typography>

          </Modal.Body>
        </Modal>
        <Modal
          show={props.agentVerificationModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => props.hideAgentVerificationModal}>
          <Modal.Body>
            <center>
              <div className="mb-3">{getLogo()}</div>
              <Typography
                className={classes.typographylink + " pd-lt-5"}
                component={"span"}
                variant="body2">
                <Link
                  to="#"
                  onClick={() => {
                    props.hideAgentVerificationModal();
                    props.showModal();
                  }}
                  alt="Back Link"
                  aria-label="Back Link">
                  Back
                </Link>
              </Typography>
            </center>

            <div className="row mt-3">
              <div className="col-md-12 col-sm-12 col-xl-12 mg-bt-20">
                <div className="row">
                  {/*  from start*/}
                  <div className="mls-form-mid">
                    <div className="error">
                      <p>{state.agentVerificationData.error}</p>
                    </div>

                    {/* start */}
                    {showMLSList && (
                      <div className="col-md-12 col-sm-12 col-xl-12 mls-list-option">
                        <Typography
                          className={classes.selectdropdown}
                          component={"span"}
                          variant="body2">
                          <Select
                            value={getFormattedValueForReactSelect(
                              _MLSList,
                              state.agentVerificationData.MLSKey,
                              false,
                              true
                            )}
                            onChange={(e) => {
                              handleInputChange({
                                target: { name: "MLSKey", value: e.value },
                              });
                            }}
                            options={getFormattedOptionsForReactSelect(
                              _MLSList,
                              true
                            )}
                            className="type-select without-label-dropdown"
                            classNamePrefix="react-select"
                          />
                        </Typography>
                      </div>
                    )}

                    {state.agentVerificationData.MLSKey && (
                      <div>
                        {/* start */}
                        <div className="col-md-12 col-sm-12 col-xl-12 mls-from-input mg-tp-15">
                          <input
                            className="input-with-border"
                            type="text"
                            name="FirstName"
                            placeholder="First Name"
                            onChange={handleInputChange}
                          />
                        </div>
                        {/* End */}
                        {/* start */}
                        <div className="col-md-12 col-sm-12 col-xl-12 mls-from-input mg-tp-15">
                          <input
                            className="input-with-border"
                            type="text"
                            name="LastName"
                            placeholder="Last Name"
                            onChange={handleInputChange}
                          />
                        </div>
                        {/* End */}
                        {/* start */}
                        {CommonCustomizationOptions &&
                        CommonCustomizationOptions.ShowEmailForAgentVerification &&
                        CommonCustomizationOptions.ShowEmailForAgentVerification.indexOf(
                          state.agentVerificationData.MLSKey
                        ) > -1 ? (
                          <div className="col-md-12 col-sm-12 col-xl-12 mls-from-input mg-tp-15">
                            <input
                              className="input-with-border"
                              type="text"
                              name="Email"
                              placeholder="Email"
                              onChange={handleInputChange}
                            />
                          </div>
                        ) : null}
                        {!(
                          CommonCustomizationOptions &&
                          CommonCustomizationOptions.HideLicenseForAgentVerification &&
                          CommonCustomizationOptions.HideLicenseForAgentVerification.indexOf(
                            state.agentVerificationData.MLSKey
                          ) > -1
                        ) ? (
                          <div className="col-md-12 col-sm-12 col-xl-12 mls-from-input mg-tp-15">
                            <input
                              className="input-with-border"
                              type="text"
                              name="LicenseNumber"
                              placeholder="License Number"
                              onChange={handleInputChange}
                            />
                            {getLicenseLookupURL(
                              state.agentVerificationData.MLSKey
                            ) !== null ? (
                              <p>
                                Don't know license number? Check
                                <Typography
                                  className={
                                    classes.typographylink + " pd-lt-5"
                                  }
                                  component={"span"}
                                  variant="body2">
                                  <a
                                    target="_blank"
                                    href={getLicenseLookupURL(
                                      state.agentVerificationData.MLSKey
                                    )}>
                                    here
                                  </a>
                                </Typography>
                              </p>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  {/* from button start*/}
                  <div className="col-md-12 col-sm-12 col-xl-12 text-center mg-tp-15">
                    <Button
                      variant="contained"
                      disabled={
                        state.isVerifying || !state.agentVerificationData.MLSKey
                      }
                      className={
                        classes.typographybutton + " full-width theme-btn"
                      }
                      onClick={() => handleAgentVerification()}>
                      {state.isVerifying ? (
                        <Loader label="Verifying" />
                      ) : (
                        "Verify"
                      )}
                    </Button>
                  </div>
                </div>
                {/* from button End*/}
                {/* from End*/}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </span>
    );
  }

  return null;
};

export default SignupModal;

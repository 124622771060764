import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { PageTitle } from '../components/util';
import '../scss/_login.scss';

class MBSSOPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }

    state = {
        title: 'Login',
        SSOAuthDetails: null
    }

    componentDidMount() {
        this.fetchAuthMechanism();
    }

    fetchAuthMechanism = () => {
        var self = this;
        var url = window.sessionStorage.getItem("RootAPI") + "accesstokens/auth?m-app=1";
        fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + window.sessionStorage.getItem("APIKey")
                }
            })
            .then(response => response.json())
            .then(json => {

                if(json && json.AuthenticationMechanism && json.AuthenticationMechanism.length > 0 && json.AuthenticationMechanism[0] && json.AuthenticationMechanism[0].AuthDetails){
                    if(json.AuthenticationMechanism[0].AuthDetails.Idp && json.AuthenticationMechanism[0].AuthDetails.Idp !== "" && 
                    json.AuthenticationMechanism[0].AuthDetails.SAMLRequest && json.AuthenticationMechanism[0].AuthDetails.SAMLRequest !== "" ){
                        self.setState({SSOAuthDetails: json.AuthenticationMechanism[0].AuthDetails}, () => {
                            setTimeout(() => {
                                if(self.ssoForm) self.ssoForm.submit();
                            },500);
                        });    
                    }
                }
            })
            .catch(function (data) {
                console.log('Error');
                console.log(data);
            });
    }

    render() {
        const { SSOAuthDetails } = this.state;
        if(SSOAuthDetails !== undefined && SSOAuthDetails !== null){
            return (
                <DocumentTitle title={PageTitle.replace('{title}', this.state.title)}> 
                    <div>
                        <form method="POST" action={SSOAuthDetails.Idp} ref={(ref) => this.ssoForm = ref}>                        
                            <input type="hidden" name="SAMLRequest" value={SSOAuthDetails.SAMLRequest} />
                        </form>                    
                        <div style={{margin: "100px auto", width: "100px"}}>
                            <div className="loader" style={{width: "50px", height: "50px"}}></div>
                        </div>
                    </div>
                </DocumentTitle>    
            )
        }

        return (            
            <DocumentTitle title={PageTitle.replace('{title}', this.state.title)}> 
                <div style={{margin: "100px auto", width: "100px"}}>
                    <div className="loader" style={{width: "50px", height: "50px"}}></div>
                </div>
            </DocumentTitle>
        );
    }
}

export default MBSSOPage;

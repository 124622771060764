import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import DefaultPhoto from "img/nd/default-photo.png";
import { convertToDate, GetSectionCustomizationOptions } from "components/util";
import { orderBy } from "lodash";
import { Element, scroller } from "react-scroll";
import { Button } from "@material-ui/core";
import Loader from "components/loader";
import * as feedActions from "actions/feed";
import * as chatActions from "actions/chat";

const ProfilePageCustomizationOptions =
  GetSectionCustomizationOptions("ProfilePageOptions");

class MessageItem extends React.Component {
  render() {
    const { audience } = this.props;
    var userProfile = this.props.profile;

    var aBtns = null;
    var cls = "chat-left";
    if (this.props.Message) {
      if (
        audience &&
        audience.type === "agent" &&
        this.props.Message.LastActionBy === "Agent"
      ) {
        cls = "chat-right";
      } else if (
        audience &&
        audience.type === "agent" &&
        this.props.Message.LastActionBy === "Client"
      ) {
        cls = "chat-left";
      } else if (
        audience &&
        audience.type === "client" &&
        this.props.Message.LastActionBy === "Client"
      ) {
        cls = "chat-right";
      } else if (
        audience &&
        audience.type === "client" &&
        this.props.Message.LastActionBy === "Agent"
      ) {
        cls = "chat-left";
      }
    }
    if (this.props.repeat && !this.props.showDate) {
      cls += " chat-repeater";
    }

    var pCls = "chat-profile-round";
    var uphoto = <img src={DefaultPhoto} alt="" />;
    if (userProfile && userProfile.Profile) {
      if (audience && audience.type === "agent") {
        uphoto =
          userProfile.FirstName.substring(0, 1) +
          userProfile.LastName.substring(0, 1);
        pCls = "chat-profile-round text-bg";
      } else if (audience && audience.type === "client") {
        uphoto =
          userProfile.Profile.BasicData.FirstName.substring(0, 1) +
          userProfile.Profile.BasicData.LastName.substring(0, 1);
        pCls = "chat-profile-round text-bg";
      }
      if (audience && audience.type === "agent") {
        if (
          ProfilePageCustomizationOptions &&
          ProfilePageCustomizationOptions.AllowAgentToUpdateProfile
        ) {
          if (userProfile && userProfile.UseMemberPhotoURL) {
            if (
              userProfile.MemberPhotoURL !== undefined &&
              userProfile.MemberPhotoURL !== null &&
              userProfile.MemberPhotoURL !== ""
            ) {
              uphoto = <img alt="" src={userProfile.MemberPhotoURL} />;
              pCls = "chat-profile-round chat-photo";
            }
          } else {
            if (
              userProfile &&
              userProfile.Profile &&
              userProfile.Profile.ProfileImageName !== undefined &&
              userProfile.Profile.ProfileImageName !== null &&
              userProfile.Profile.ProfileImageName !== ""
            ) {
              uphoto = (
                <img alt="" src={userProfile.Profile.ProfileImageName} />
              );
              pCls = "chat-profile-round chat-photo";
            }
          }
        } else {
          if (
            userProfile &&
            userProfile.Profile &&
            userProfile.Profile.ProfileImageName !== undefined &&
            userProfile.Profile.ProfileImageName !== null &&
            userProfile.Profile.ProfileImageName !== ""
          ) {
            uphoto = <img alt="" src={userProfile.Profile.ProfileImageName} />;
            pCls = "chat-profile-round chat-photo";
          }
        }
      } else if (
        audience &&
        audience.type === "client" &&
        userProfile.Profile.BasicData &&
        userProfile.Profile.BasicData.ProfileImageUrl !== undefined &&
        userProfile.Profile.BasicData.ProfileImageUrl !== null &&
        userProfile.Profile.BasicData.ProfileImageUrl !== ""
      ) {
        uphoto = (
          <img src={userProfile.Profile.BasicData.ProfileImageUrl} alt="" />
        );
        pCls = "chat-profile-round chat-photo";
      }
    }

    if (
      this.props.Message &&
      this.props.Message.Author &&
      this.props.Message.Author !== "" &&
      ((audience &&
        audience.type === "agent" &&
        this.props.Message.LastActionBy === "Client") ||
        (audience &&
          audience.type === "client" &&
          this.props.Message.LastActionBy === "Agent"))
    ) {
      pCls = "chat-profile-round text-bg";
      uphoto = this.props.Message.Author.substring(0, 1);
      if (this.props.Message.Author.indexOf(" ") >= 0) {
        var idx = this.props.Message.Author.indexOf(" ");
        uphoto += this.props.Message.Author.substring(idx + 1, idx + 2);
      }
    }
    if (
      this.props.Message &&
      this.props.Message.ProfileImageUrl &&
      this.props.Message.ProfileImageUrl !== ""
    ) {
      pCls = "chat-profile-round chat-photo";
      uphoto = <img src={this.props.Message.ProfileImageUrl} alt="" />;
    }

    var mesCls = "chat-right-meg";

    return (
      <div className={cls}>
        {this.props.showDate && (
          <div className="chat-date-time">
            <span className="tag-date">
              {convertToDate(this.props.Message.ActivityOn, "wwww, mmm dd")}
            </span>
          </div>
        )}
        <div className={mesCls}>
          <div className="chat-box">
            <span>{this.props.Message.Message}</span>
            {aBtns}
          </div>
          {(this.props.showDate || !this.props.repeat) && (
            <div className={pCls}>{uphoto}</div>
          )}
        </div>
      </div>
    );
  }
}

class MessageTable extends React.Component {
  render() {
    var self = this;
    var rows = [];
    var lastAuthor = "";
    var lastDt = "";

    orderBy(this.props.Messages, (a) => new Date(a.ActivityOn), [
      "asc",
    ]).forEach(function (msg) {
      var repeat = false;
      if (lastAuthor === msg.Author) repeat = true;
      lastAuthor = msg.Author;

      var showDate = true;
      if (
        lastDt !== "" &&
        convertToDate(lastDt, "mm/dd/yyyy") ===
          convertToDate(msg.ActivityOn, "mm/dd/yyyy")
      )
        showDate = false;
      //console.log('Id: ' + msg.Id + ', Author: ' + msg.Author + ', Message: ' + msg.Message + ', repeat: ' + repeat + ', Date: ' + convertToDate(msg.ActivityOn, "mm/dd/yyyy") + ', lastDt: ' + convertToDate(lastDt, "mm/dd/yyyy") + ', showDate: ' + showDate);
      lastDt = msg.ActivityOn;

      rows.push(
        <MessageItem
          Message={msg}
          key={msg.Id}
          repeat={repeat}
          showDate={showDate}
          {...self.props}
        />
      );
    });
    return <div className="chat-mainbox">{rows}</div>;
  }
}

class ChatMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listingId: this.props.listingId,
      messages: [],
      messageValue: "",
      editId: null,
      isSending: false,
    };

    this.addMessage = this.addMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  loadChats = (selectedClient) => {
    if (this.props.audience) {
      const { type } = this.props.audience;
      if (type === "agent" && selectedClient && selectedClient.Id) {
        //this.props.chatActions.fetchChats(selectedClient.Id, false, (this.props.isChatWithLNO || false)).then(r => this.props.chatActions.fetchUnreadCount(null, (this.props.isChatWithLNO || false)));
        if (this.props.isChatWithLNO) {
          this.props.chatActions
            .fetchAdvisorChats(selectedClient.Id, false)
            .then((r) => this.props.chatActions.fetchAdvisorUnreadCount(null));
        } else {
          this.props.chatActions
            .fetchChats(selectedClient.Id, false)
            .then((r) => this.props.chatActions.fetchUnreadCount(null));
        }
      } else {
        //this.props.chatActions.fetchChats(null, false, (this.props.isChatWithLNO || false)).then(r => this.props.chatActions.fetchUnreadCount(null, (this.props.isChatWithLNO || false)));
        if (this.props.isChatWithLNO) {
          this.props.chatActions
            .fetchAdvisorChats(null, false)
            .then((r) => this.props.chatActions.fetchAdvisorUnreadCount(null));
        } else {
          this.props.chatActions
            .fetchChats(null, false)
            .then((r) => this.props.chatActions.fetchUnreadCount(null));
        }
      }
    }
  };

  componentDidMount() {
    //this.loadChats(this.props.selectedClient);
  }

  componentWillReceiveProps(nextProps) {
    /* if(JSON.stringify(this.props.audience) !== JSON.stringify(nextProps.audience)){
            this.loadChats();
        }
        if(JSON.stringify(this.props.selectedClient) !== JSON.stringify(nextProps.selectedClient)){
            this.loadChats(nextProps.selectedClient);
        } */
    /* if(JSON.stringify(this.props.chatItems) !== JSON.stringify(nextProps.chatItems)){
            this.setState({messages: nextProps.chatItems})
        } */
  }

  addMessage(event) {
    var self = this;
    event.preventDefault();

    if (self.state.isSending) return;

    if (self.state.messageValue.length < 2) {
      if (self.props.showNotification) {
        self.props.showNotification(
          "info",
          "",
          "Message must be at least 2 characters",
          5000
        );
      }

      return;
    }

    const cookies = new Cookies();
    const authToken = cookies.get("authDetails");

    var url = "";

    url =
      window.sessionStorage.getItem("RootAPI") +
      window.sessionStorage.getItem("APIAudience") +
      authToken.Audience.Id;

    if (window.sessionStorage.getItem("APIAudience") === "agents/") {
      const { selectedClient } = self.props;

      if (self.props.clientId !== undefined) {
        url += "/clients/" + self.props.clientId;
      } else if (selectedClient !== undefined && selectedClient !== null) {
        url += "/clients/" + selectedClient.Id;
      }
    }
    url += "/addchat";

    var msgBody = { Message: self.state.messageValue };
    if (
      process.env.REACT_APP_PortalKey !== undefined &&
      process.env.REACT_APP_PortalKey !== null &&
      process.env.REACT_APP_PortalKey !== ""
    ) {
      msgBody.PortalId = process.env.REACT_APP_PortalKey;
    }

    if (this.props.isChatWithLNO) {
      msgBody.ForLNO = true;
    }

    self.setState({ isSending: true });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.sessionStorage.getItem("APIKey"),
      },
      method: "POST",
      body: JSON.stringify(msgBody),
    })
      .then((response) => response.json())
      .then((json) => {
        // Add to state
        self.setState({
          messageValue: "",
          messages: self.state.messages.concat(json),
          isSending: false,
        });

        self.loadChats(self.props.selectedClient);
      })
      .catch(function (data) {
        console.log(data);
        self.setState({ isSending: false });
      });
  }

  handleChange(event) {
    this.setState({ messageValue: event.target.value });
  }

  componentDidUpdate() {
    try {
      scroller.scrollTo("messages_bottom", { containerId: "message_inner" });
    } catch (err) {
      //console.log(err.message);
    }
  }

  render() {
    var returnData = [];
    if (this.props.chatItems && this.props.chatItems.length > 0) {
      returnData = (
        <MessageTable
          classes={this.props.classes}
          Messages={this.props.chatItems}
          key="lstallmsg"
          {...this.props}
        />
      );
    }

    //console.log('returnData');
    //console.log(returnData);

    return (
      <div className="message-inner" id="message_inner">
        {this.props.isSelectedClientFetching || this.props.isChatFetching ? (
          <Loader width="40" height="40" />
        ) : returnData && returnData.length === 0 ? (
          <div className="text-center">No Messages yet!</div>
        ) : (
          returnData
        )}
        <Element name="messages_bottom" className="messages_bottom"></Element>
        <form onSubmit={this.addMessage}>
          <div className="chat-notes-btnbox">
            <div className="chat-input-message">
              <div>
                <input
                  type="text"
                  ref={(ref) => (this.messageInputRef = ref)}
                  placeholder="Enter a message"
                  className={"input-with-border input-replay-box"}
                  value={this.state.messageValue}
                  onChange={this.handleChange}
                />
              </div>
              <div className={"notes-submit"}>
                <Button
                  variant="contained"
                  type="submit"
                  className={
                    (this.props &&
                    this.props.classes &&
                    this.props.classes.typographybuttonchat
                      ? this.props.classes.typographybuttonchat
                      : "") + " theme-btn"
                  }
                >
                  <span className="material-icons">arrow_forward_ios</span>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    audience: state.session.audience,
    //selectedClient: state.entities.clients[state.clients.selectedClient],
    selectedClient:
      state.chat && state.chat.selectedClient
        ? state.chat.selectedClient
        : null,
    feedItems: state.entities.feedItems,
    isSelectedClientFetching:
      state.chat && state.chat.isFetchingClient
        ? state.chat.isFetchingClient
        : false,
    isChatFetching:
      state.chat && state.chat.isFetching ? state.chat.isFetching : false,
    chatItems:
      state.chat && state.chat.isChatWithLNO
        ? (state.chat && state.chat.adChats) || []
        : (state.chat && state.chat.chats) || [],
    isChatWithLNO:
      state.chat && state.chat.isChatWithLNO ? state.chat.isChatWithLNO : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  feedActions: bindActionCreators(feedActions, dispatch),
  chatActions: bindActionCreators(chatActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessages);

import { Typography } from "@material-ui/core";
import React, { useImperativeHandle, useState } from "react";
import { forwardRef } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as clientActions from "actions/clients";
import { bindActionCreators } from "redux";
import { Loader } from "react-bootstrap-typeahead";

const ConnectToClient = (props) => {
  const { classes } = props;

  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const onClickSubmit = () => {
    if (email && email !== null && email !== undefined) {
      setError(null);
      props.clientActions.connectClient({ Email: email });
    } else {
      setError("Please enter a valid email");
    }
  };

  useEffect(() => {
    if (
      props.clients.error &&
      props.clients.error !== null &&
      props.clients.error !== undefined
    ) {
      setError(props.clients.error);
    } else {
      setError(null);
    }

    if (
      props.clients.connectClient &&
      props.clients.connectClient !== undefined &&
      props.clients.connectClient === true
    ) {
      setEmail(null);
      setError(null);
      setSuccess("Client Successfully Connected!");
      props.clientActions.fetchClients();
    }
  }, [props.clients]);

  return (
    <div>
      <Modal
        show={props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="connect-client-list"
        onHide={() => {
          setSuccess(null);
          props.closeModal();
        }}
      >
        <Typography
          className={(classes && classes.modalpopupshowmap) || ""}
          component={"span"}
          variant="body2"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Typography
                className={(classes && classes.typographytitle) || ""}
                variant="h2"
              >
                Connect To Client
              </Typography>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="popup-connect-client">
              {error ? <p className="error">{error}</p> : ""}
              {success ? <p className="success">{success}</p> : ""}
              <span className="connect-client-input">
                <input
                  className="input-with-border"
                  type="email"
                  autoComplete="off"
                  placeholder="Email"
                  name="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer className="text-right  mg-tp-15">
            <Button
              variant="contained"
              onClick={onClickSubmit}
              disabled={props.clients.isConnectingClient}
              className={
                ((classes && classes.typographybutton) || "") + " theme-btn"
              }
            >
              {props.clients.isConnectingClient ? <Loader /> : "Connect"}
            </Button>
          </Modal.Footer>
        </Typography>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  clientActions: bindActionCreators(clientActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectToClient);

import { CALL_API/* , Schemas */ } from '../store/api';
//import Cookies from 'universal-cookie';

export const MANAGE_FETCH_REQUEST = 'MANAGE_FETCH_REQUEST';
export const MANAGE_FETCH_SUCCESS = 'MANAGE_FETCH_SUCCESS';
export const MANAGE_FETCH_FAILURE = 'MANAGE_FETCH_FAILURE';

export const MANAGE_LOOKUP_REQUEST = 'MANAGE_LOOKUP_REQUEST';
export const MANAGE_LOOKUP_SUCCESS = 'MANAGE_LOOKUP_SUCCESS';
export const MANAGE_LOOKUP_FAILURE = 'MANAGE_LOOKUP_FAILURE';

export const MANAGE_ADD_REQUEST = 'MANAGE_ADD_REQUEST';
export const MANAGE_ADD_SUCCESS = 'MANAGE_ADD_SUCCESS';
export const MANAGE_ADD_FAILURE = 'MANAGE_ADD_FAILURE';

export const MANAGE_UPDATE_REQUEST = 'MANAGE_UPDATE_REQUEST';
export const MANAGE_UPDATE_SUCCESS = 'MANAGE_UPDATE_SUCCESS';
export const MANAGE_UPDATE_FAILURE = 'MANAGE_UPDATE_FAILURE';

export const MANAGE_DELETE_REQUEST = 'MANAGE_DELETE_REQUEST';
export const MANAGE_DELETE_SUCCESS = 'MANAGE_DELETE_SUCCESS';
export const MANAGE_DELETE_FAILURE = 'MANAGE_DELETE_FAILURE';

export const MANAGE_PP_REQUEST = 'MANAGE_PP_REQUEST';
export const MANAGE_PP_SUCCESS = 'MANAGE_PP_SUCCESS';
export const MANAGE_PP_FAILURE = 'MANAGE_PP_FAILURE';

export const FETCH_AD_REQUEST = 'FETCH_AD_REQUEST';
export const FETCH_AD_SUCCESS = 'FETCH_AD_SUCCESS';
export const FETCH_AD_FAILURE = 'FETCH_AD_FAILURE';

export const EVENT_REQUEST = 'EVENT_REQUEST';
export const EVENT_SUCCESS = 'EVENT_SUCCESS';
export const EVENT_FAILURE = 'EVENT_FAILURE';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';

export const MANAGE_FETCH_CBLG_REQUEST = 'MANAGE_FETCH_CBLG_REQUEST';
export const MANAGE_FETCH_CBLG_SUCCESS = 'MANAGE_FETCH_CBLG_SUCCESS';
export const MANAGE_FETCH_CBLG_FAILURE = 'MANAGE_FETCH_CBLG_FAILURE';

export const MANAGE_ADD_CBLG_REQUEST = 'MANAGE_ADD_CBLG_REQUEST';
export const MANAGE_ADD_CBLG_SUCCESS = 'MANAGE_ADD_CBLG_SUCCESS';
export const MANAGE_ADD_CBLG_FAILURE = 'MANAGE_ADD_CBLG_FAILURE';

export const MANAGE_UPDATE_CBLG_REQUEST = 'MANAGE_UPDATE_CBLG_REQUEST';
export const MANAGE_UPDATE_CBLG_SUCCESS = 'MANAGE_UPDATE_CBLG_SUCCESS';
export const MANAGE_UPDATE_CBLG_FAILURE = 'MANAGE_UPDATE_CBLG_FAILURE';

export const MANAGE_DELETE_CBLG_REQUEST = 'MANAGE_DELETE_CBLG_REQUEST';
export const MANAGE_DELETE_CBLG_SUCCESS = 'MANAGE_DELETE_CBLG_SUCCESS';
export const MANAGE_DELETE_CBLG_FAILURE = 'MANAGE_DELETE_CBLG_FAILURE';

export const fetchFeaturedProperties = (body) => (dispatch, getState) => {
    var endpoint = "listings/featuredproperty/list";
    var key = "featuredproperties";

    return dispatch({
        [CALL_API]: {
            types: [MANAGE_FETCH_REQUEST, MANAGE_FETCH_SUCCESS, MANAGE_FETCH_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });

    /* return dispatch({
        type: MANAGE_FETCH_SUCCESS,
        key: key
    }); */
};

export const addFeaturedProperty = (body) => (dispatch, getState) => {
    var endpoint = "listings/featuredproperty/add";
    var key = "featuredproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_ADD_REQUEST, MANAGE_ADD_SUCCESS, MANAGE_ADD_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const updateFeaturedProperty = (body) => (dispatch, getState) => {
    var endpoint = "listings/featuredproperty/update";
    var key = "featuredproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_UPDATE_REQUEST, MANAGE_UPDATE_SUCCESS, MANAGE_UPDATE_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const deleteFeaturedProperty = (id) => (dispatch, getState) => {
    var endpoint = "listings/featuredproperty/delete/" + id;
    var key = "featuredproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_DELETE_REQUEST, MANAGE_DELETE_SUCCESS, MANAGE_DELETE_FAILURE],
            method: 'DELETE',
            endpoint: endpoint,
        },
        key
    });
};

export const lookupFeaturedProperty = (mlsNumber) => (dispatch, getState) => {
    var endpoint = "listings/featuredlisting/detail/" + mlsNumber;
    var key = "featuredproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_LOOKUP_REQUEST, MANAGE_LOOKUP_SUCCESS, MANAGE_LOOKUP_FAILURE],
            method: 'GET',
            endpoint: endpoint,
        },
        key
    });
};

export const fetchFeaturedOpenHouses = (body) => (dispatch, getState) => {
    var endpoint = "listings/featuredopenhouse/list";
    var key = "featuredopenhouse";

    return dispatch({
        [CALL_API]: {
            types: [MANAGE_FETCH_REQUEST, MANAGE_FETCH_SUCCESS, MANAGE_FETCH_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const addFeaturedOpenHouse = (body) => (dispatch, getState) => {
    var endpoint = "listings/featuredopenhouse/add";
    var key = "featuredopenhouse";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_ADD_REQUEST, MANAGE_ADD_SUCCESS, MANAGE_ADD_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const updateFeaturedOpenHouse = (body) => (dispatch, getState) => {
    var endpoint = "listings/featuredopenhouse/update";
    var key = "featuredopenhouse";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_UPDATE_REQUEST, MANAGE_UPDATE_SUCCESS, MANAGE_UPDATE_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const deleteFeaturedOpenHouse = (id) => (dispatch, getState) => {
    var endpoint = "listings/featuredopenhouse/delete/" + id;
    var key = "featuredopenhouse";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_DELETE_REQUEST, MANAGE_DELETE_SUCCESS, MANAGE_DELETE_FAILURE],
            method: 'DELETE',
            endpoint: endpoint,
        },
        key
    });
};

export const lookupFeaturedOpenHouse = (mlsNumber) => (dispatch, getState) => {
    var endpoint = "listings/featuredlisting/detail/" + mlsNumber;
    var key = "featuredopenhouse";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_LOOKUP_REQUEST, MANAGE_LOOKUP_SUCCESS, MANAGE_LOOKUP_FAILURE],
            method: 'GET',
            endpoint: endpoint,
        },
        key
    });
};

export const fetchFeaturedHotProperties = (body) => (dispatch, getState) => {
    var endpoint = "listings/hotproperty/list";
    var key = "featuredhotproperties";

    return dispatch({
        [CALL_API]: {
            types: [MANAGE_FETCH_REQUEST, MANAGE_FETCH_SUCCESS, MANAGE_FETCH_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const addFeaturedHotProperty = (body) => (dispatch, getState) => {
    var endpoint = "listings/hotproperty/add";
    var key = "featuredhotproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_ADD_REQUEST, MANAGE_ADD_SUCCESS, MANAGE_ADD_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const updateFeaturedHotProperty = (body) => (dispatch, getState) => {
    var endpoint = "listings/hotproperty/update";
    var key = "featuredhotproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_UPDATE_REQUEST, MANAGE_UPDATE_SUCCESS, MANAGE_UPDATE_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const deleteFeaturedHotProperty = (id) => (dispatch, getState) => {
    var endpoint = "listings/hotproperty/delete/" + id;
    var key = "featuredhotproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_DELETE_REQUEST, MANAGE_DELETE_SUCCESS, MANAGE_DELETE_FAILURE],
            method: 'DELETE',
            endpoint: endpoint,
        },
        key
    });
};

export const lookupFeaturedHotProperty = (mlsNumber) => (dispatch, getState) => {
    var endpoint = "listings/featuredlisting/detail/" + mlsNumber;
    var key = "featuredhotproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_LOOKUP_REQUEST, MANAGE_LOOKUP_SUCCESS, MANAGE_LOOKUP_FAILURE],
            method: 'GET',
            endpoint: endpoint,
        },
        key
    });
};

export const fetchFeaturedBFYProperties = (body) => (dispatch, getState) => {
    var endpoint = "listings/featuredbuild/list";
    var key = "featuredbfyproperties";

    return dispatch({
        [CALL_API]: {
            types: [MANAGE_FETCH_REQUEST, MANAGE_FETCH_SUCCESS, MANAGE_FETCH_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const addFeaturedBFYProperty = (body) => (dispatch, getState) => {
    var endpoint = "listings/featuredbuild/add";
    var key = "featuredbfyproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_ADD_REQUEST, MANAGE_ADD_SUCCESS, MANAGE_ADD_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const updateFeaturedBFYProperty = (body) => (dispatch, getState) => {
    var endpoint = "listings/featuredbuild/update";
    var key = "featuredbfyproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_UPDATE_REQUEST, MANAGE_UPDATE_SUCCESS, MANAGE_UPDATE_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const deleteFeaturedBFYProperty = (id) => (dispatch, getState) => {
    var endpoint = "listings/featuredbuild/delete/" + id;
    var key = "featuredbfyproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_DELETE_REQUEST, MANAGE_DELETE_SUCCESS, MANAGE_DELETE_FAILURE],
            method: 'DELETE',
            endpoint: endpoint,
        },
        key
    });
};

export const lookupFeaturedBFYProperty = (mlsNumber) => (dispatch, getState) => {
    var endpoint = "listings/featuredbuild/detail/" + mlsNumber;
    var key = "featuredbfyproperties";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_LOOKUP_REQUEST, MANAGE_LOOKUP_SUCCESS, MANAGE_LOOKUP_FAILURE],
            method: 'GET',
            endpoint: endpoint,
        },
        key
    });
};

export const fetchFeaturedAgents = (body) => (dispatch, getState) => {
    var endpoint = "agents/featuredagent/list";
    var key = "featuredagents";

    return dispatch({
        [CALL_API]: {
            types: [MANAGE_FETCH_REQUEST, MANAGE_FETCH_SUCCESS, MANAGE_FETCH_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const addFeaturedAgent = (body) => (dispatch, getState) => {
    var endpoint = "agents/featuredagent/add";
    var key = "featuredagents";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_ADD_REQUEST, MANAGE_ADD_SUCCESS, MANAGE_ADD_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const updateFeaturedAgent = (body) => (dispatch, getState) => {
    var endpoint = "agents/featuredagent/update";
    var key = "featuredagents";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_UPDATE_REQUEST, MANAGE_UPDATE_SUCCESS, MANAGE_UPDATE_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const deleteFeaturedAgent = (id) => (dispatch, getState) => {
    var endpoint = "agents/featuredagent/delete/" + id;
    var key = "featuredagents";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_DELETE_REQUEST, MANAGE_DELETE_SUCCESS, MANAGE_DELETE_FAILURE],
            method: 'DELETE',
            endpoint: endpoint,
        },
        key
    });
};

export const lookupFeaturedAgent = (mlsNumber) => (dispatch, getState) => {
    var endpoint = "listings/featuredbuild/detail/" + mlsNumber;
    var key = "featuredagents";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_LOOKUP_REQUEST, MANAGE_LOOKUP_SUCCESS, MANAGE_LOOKUP_FAILURE],
            method: 'GET',
            endpoint: endpoint,
        },
        key
    });
};

export const setPrintPiece = (body) => (dispatch, getState) => {
    var endpoint = "agents/printpieceurl/set";
    var key = "printpiece";

    return dispatch({
        [CALL_API]: {
            types: [MANAGE_PP_REQUEST, MANAGE_PP_SUCCESS, MANAGE_PP_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const fetchFeaturedAds = (body) => (dispatch, getState) => {
    var endpoint = "ads/list";
    var key = "featuredads";

    return dispatch({
        [CALL_API]: {
            types: [MANAGE_FETCH_REQUEST, MANAGE_FETCH_SUCCESS, MANAGE_FETCH_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const addFeaturedAd = (body) => (dispatch, getState) => {
    var endpoint = "ads/add";
    var key = "featuredads";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_ADD_REQUEST, MANAGE_ADD_SUCCESS, MANAGE_ADD_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const updateFeaturedAd = (body) => (dispatch, getState) => {
    var endpoint = "ads/update";
    var key = "featuredads";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_UPDATE_REQUEST, MANAGE_UPDATE_SUCCESS, MANAGE_UPDATE_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const deleteFeaturedAd = (id) => (dispatch, getState) => {
    var endpoint = "ads/delete/" + id;
    var key = "featuredads";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_DELETE_REQUEST, MANAGE_DELETE_SUCCESS, MANAGE_DELETE_FAILURE],
            method: 'DELETE',
            endpoint: endpoint,
        },
        key
    });
};

/* export const fetchFrontEndAds = (key, adSize, count) => (dispatch, getState) => {
    var endpoint = "ads/get?adsize=" + adSize;
    if(count){
        endpoint = endpoint + "&count=" + count;
    }

    const cookies = new Cookies();
    const authToken = cookies.get('authDetails');
    if(authToken) {    
        var DeviceIDs = window.localStorage.getItem("DeviceIDs");        
        if(DeviceIDs){
            DeviceIDs = JSON.parse(DeviceIDs);
            var dLst = DeviceIDs.filter(d => d.UserId === authToken.Audience.Id);
            if(dLst && dLst.length > 0){
                endpoint = endpoint + "&duid=" + dLst[0].DeviceId;
            }
        }
    }

    return dispatch({
        [CALL_API]: {
            types: [MANAGE_FETCH_REQUEST, MANAGE_FETCH_SUCCESS, MANAGE_FETCH_FAILURE],
            method: 'GET',
            endpoint: endpoint,
        },
        key
    });
}; */

export const fetchFrontEndAds = (key, body) => (dispatch, getState) => {
    var endpoint = "ads/get";
    
    var lbody = body;

    var DeviceUID = window.localStorage.getItem("DeviceUID");
    if(DeviceUID && DeviceUID !== ""){
        lbody.deviceId = DeviceUID;
    }

    return dispatch({
        [CALL_API]: {
            types: [MANAGE_FETCH_REQUEST, MANAGE_FETCH_SUCCESS, MANAGE_FETCH_FAILURE],
            method: 'POST',            
            body: lbody,
            endpoint: endpoint
        },
        key
    });
}

/* export const trackEvent = (key, body) => (dispatch, getState) => {
    
    var endpoint = "ads/addevent";
    
    var lbody = body;
    var DeviceIDs = window.localStorage.getItem("DeviceIDs");
    if(DeviceIDs && lbody && lbody.UserId){
        DeviceIDs = JSON.parse(DeviceIDs);
        var dLst = DeviceIDs.filter(d => d.UserId === lbody.UserId);
        if(dLst && dLst.length > 0){
            lbody.deviceUID = dLst[0].DeviceId;
        }
    }

    return dispatch({
        [CALL_API]: {
            types: [EVENT_REQUEST, EVENT_SUCCESS, EVENT_FAILURE],
            method: 'POST',
            body: lbody,
            endpoint: endpoint,
        },
        key
    });
}; */

export const trackEvent = (key, body) => (dispatch, getState) => {
    
    var endpoint = "ads/addevent";
    
    var lbody = body;

    var DeviceUID = window.localStorage.getItem("DeviceUID");
    if(DeviceUID && DeviceUID !== ""){
        lbody.deviceUID = DeviceUID;
    }

    return dispatch({
        [CALL_API]: {
            types: [EVENT_REQUEST, EVENT_SUCCESS, EVENT_FAILURE],
            method: 'POST',
            body: lbody,
            endpoint: endpoint,
        },
        key
    });
}

export const updateFrontEndAds = (key, listToUpdate) => (dispatch, getState) => {
    return dispatch({
        type: UPDATE_SUCCESS,
        key: key,
        listToUpdate: listToUpdate 
    });
}

export const fetchCustomBlogPosts = (body) => (dispatch, getState) => {
    var endpoint = "blog/manageblogs";
    var key = "customblogposts";

    return dispatch({
        [CALL_API]: {
            types: [MANAGE_FETCH_REQUEST, MANAGE_FETCH_SUCCESS, MANAGE_FETCH_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const addCustomBlogPost = (body) => (dispatch, getState) => {
    var endpoint = "blog/addblog";
    var key = "customblogposts";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_ADD_REQUEST, MANAGE_ADD_SUCCESS, MANAGE_ADD_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const updateCustomBlogPost = (body) => (dispatch, getState) => {
    var endpoint = "blog/editblog";
    var key = "customblogposts";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_UPDATE_REQUEST, MANAGE_UPDATE_SUCCESS, MANAGE_UPDATE_FAILURE],
            method: 'POST',
            body: body,
            endpoint: endpoint,
        },
        key
    });
};

export const deleteCustomBlogPost = (id) => (dispatch, getState) => {
    var endpoint = "blog/" + id;
    var key = "customblogposts";
    
    return dispatch({
        [CALL_API]: {
            types: [MANAGE_DELETE_REQUEST, MANAGE_DELETE_SUCCESS, MANAGE_DELETE_FAILURE],
            method: 'DELETE',
            endpoint: endpoint,
        },
        key
    });
};